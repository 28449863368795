import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { UnitsOption,Unit,  BaseUnits,BaseUnitsOption,} from "../../../app/models/units";
interface Props {
  closeForm: () => void;
}

export default observer(function ItemForm({ closeForm }: Props) {

  const { unitStore } = useStore();
  const {
    getBaseUnitOptions,
    selectedUnitManage,
    createUnit,
    updateUnit
  } = unitStore;
  const [baseUnitOptions, setBaseUnitOptions] = useState<BaseUnitsOption[]>([]);
  const [title, setTitle] = useState<string>("Create Unit");
  const [itemFormValues, setItemForm] = useState<Unit>(
    {
      id: "",
      name: "",
      quantity: 0,
      baseUnitName: "",
      baseUnitId:""
    }
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    quantity: yup.number().required("Required"),
    baseUnitId: yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedUnitManage) {
      setTitle("Update Unit");
      let formData: Unit = {
        id: selectedUnitManage.id,
        name: selectedUnitManage.name,
        quantity: selectedUnitManage.quantity,
        baseUnitName: selectedUnitManage.baseUnitName,
        baseUnitId: selectedUnitManage.baseUnitId
      };
      setItemForm(formData);
    }

    getBaseUnitOptions().then((unitOptions) => {
      let uOptions: BaseUnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: BaseUnitsOption = {
          value: option.value,
          label: option.label,
        };
        uOptions.push(uOption);
      });
      setBaseUnitOptions(uOptions);
    });
  }, [selectedUnitManage, getBaseUnitOptions]);

  function handleFormSubmit(unitFormValues: Unit) {
    let unit: Unit = {
      ...unitFormValues,
    };
    if (!selectedUnitManage) {
        createUnit(unit).then(() => closeForm());
    } else {
        updateUnit(unit).then(() => closeForm());
    }
  }

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={itemFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in unit information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Unit Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Field
                    name="quantity"
                    type="number"
                    label="Base Unit Quantity"
                    size="small"
                    component={FormTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="baseUnitId"
                    label="Base Unit"
                    size="small"
                    options={baseUnitOptions}
                    component={FormSelect}
                  />
                </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
