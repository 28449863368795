import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FormTextField } from "../../../app/common/form/FormTextField";
import MyDateInput from "../../../app/common/form/MyDateInput";
import {
  ComponentsToOrder,
  FranchiseePurchaseOrderRequestFormValues,
} from "../../../app/models/requests/franchiseePurchaseOrderRequest";
import { useStore } from "../../../app/stores/store";

export default observer(function SummaryOfFranchiseeOrder() {
  const { classificationStore, menuStore, franchiseeOrderRequestStore } =
    useStore();
  const { franchiseeOrderQuantity } = menuStore;
  const { loadCartItems, getComponentsToOrder, createFranchiseeOrderRequest } =
    franchiseeOrderRequestStore;
  const [componentsToOrder, setComponentsToOrder] = useState<
    ComponentsToOrder[]
  >([]);
  const [
    franchiseePurchaseOrderRequestFormValues,
    setFranchiseePurchaseOrderRequestFormValues,
  ] = useState<FranchiseePurchaseOrderRequestFormValues>({
    deliveryDate: null,
    orders: [],
    comment: "",
  });

  useEffect(() => {
    let orderQuantity = new Map<string, number>();
    franchiseeOrderQuantity.forEach((fQuantity, fOrder) => {
      console.log("quantity: ", fQuantity);
      if (fQuantity > 0) orderQuantity.set(fOrder, fQuantity);
    });

    console.log("orderquantity: ", orderQuantity);

    loadCartItems(orderQuantity).then(() => {
      setComponentsToOrder(getComponentsToOrder());
    });
  }, [loadCartItems, getComponentsToOrder]);

  const names = componentsToOrder.map((component) => {
    const name = component.component.split(" : ")[0];
    return name;
  });

  const amounts = componentsToOrder.map((component) => {
    const amount = component.component.split(" : ")[1];
    return amount;
  });

  function handleFormSubmit(
    franchiseePurchaseOrderRequestFormValues: FranchiseePurchaseOrderRequestFormValues
  ) {
    let franchiseePurchaseOrderRequestForm: FranchiseePurchaseOrderRequestFormValues =
      {
        ...franchiseePurchaseOrderRequestFormValues,
      };

    franchiseePurchaseOrderRequestForm.orders = componentsToOrder;
    console.log("order: ", franchiseePurchaseOrderRequestForm);
    createFranchiseeOrderRequest(franchiseePurchaseOrderRequestForm).then(
      () => {}
    );
  }

  return (
    <>
      <Formik
        initialValues={franchiseePurchaseOrderRequestFormValues}
        enableReinitialize
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form noValidate autoComplete="off">
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Box
                  style={{
                    height: "50vh",
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                  sx={{ backgroundColor: "#EFEFEF", padding: 5 }}
                >
                  {names.map((component, index) => (
                    <Grid
                      container
                      sx={{
                        marginTop: 0.5,
                        marginBottom: 0.5,
                      }}
                    >
                      <Grid key={index} item xs={12}>
                        <Stack direction="row">
                          <Typography sx={{ padding: 1 }} variant="h6">
                            {component}
                          </Typography>
                          <Box
                            sx={{
                              flexGrow: 1,
                              display: { xs: "none", md: "flex" },
                            }}
                          />
                          <Typography sx={{ padding: 1 }} variant="h6">
                            {amounts[index]}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Stack spacing={1}>
                  <Field
                    name="comment"
                    label="Comments"
                    size="small"
                    multiline
                    rows={5}
                    component={FormTextField}
                  />
                  <>
                    <MyDateInput
                      placeholderText="Delivery Date"
                      name="deliveryDate"
                      dateFormat="MMMM d, yyyy"
                    />
                  </>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 1,
                }}
              >
                <Stack direction="row">
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={ !isValid}
                    disableElevation
                    sx={{
                      marginRight: 3,
                      backgroundColor: "orange",
                      "&:hover": {
                        backgroundColor: "orange",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "orange",
                        borderColor: "none",
                      },
                    }}
                  >
                    Check Out
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
