import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import {
  ProcessRequestFormValues,
  ProcessToRequest,
} from "../../../app/models/requests/processRequest";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { ItemToTransfer } from "../../../app/models/requests/transferRequest";
import { history } from "../../..";

interface Props {
  closeForm: () => void;
}

export default observer(function ProcessRequestForm({ closeForm }: Props) {
  const { inventoryStore, processRequestStore, userStore } =
    useStore();
  const {
    selectedRecipe,
    loadProcessRequestsToConfirm,
    setProcessForm,
    loadingInitial,
    setLoadingInitial
  } = processRequestStore;
  const { user } = userStore;

  const [processRequestFormValues, setProcessRequestFormValues] =
    useState<ProcessRequestFormValues>({
      processingDepartment: user?.department || 0
    });

  useEffect(() => {
    setLoadingInitial(false);
  }, [setLoadingInitial]);

  function handleFormSubmit(processRequestFormValues: ProcessRequestFormValues) {
    let processRequestForm: ProcessRequestFormValues = {
      ...processRequestFormValues,
    };
    loadProcessRequestsToConfirm(
      selectedRecipe!.id,
      processRequestForm!.processingDepartment
    ).then(() => {
      setProcessForm(processRequestForm);
    });
  }

  if (loadingInitial)
    return <LoadingComponent content="Loading Process Request..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={processRequestFormValues}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in process information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="processingDepartment"
                      label="Processing Department"
                      size="small"
                      options={departmentOptions}
                      component={FormSelect}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Request Process
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
