import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useStore } from "../../../app/stores/store";
import {
  DiscrepancyReportItems,
  DiscrepancyReportFilter,
  DiscrepancyReportExcel,
  AddDiscrepancyReport,
} from "../../../app/models/Inventories/inventory";
import { useHistory } from "react-router-dom";
import moment from "moment";
export default observer(function DiscrepancyReport() {
  const history = useHistory();

  const { endingInventoryStore, discrepancyReportStore } = useStore();
  const { getSelectedItem } = endingInventoryStore;
  const {
    discrepancyReportItemRegistry,
    loadDiscrepancyReportItems,
    getDiscrepancyReportItems,
    generateExcelReport,
    getDiscrepancyReportFilter,
    getHasSetFilterDiscrep,
    setHasSetFilterDiscrep,
    getInitialIdDiscrep,
    getInitialDateDiscrep,
    createDiscrepancyReport,
  } = discrepancyReportStore;
  const [hasReport, setHasReport] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [rows, setRows] = useState<Array<DiscrepancyReportItems>>([]);
  const [hasRendered, sethasRendered] = useState<boolean>(false);
  const [initDate, setInitDate] = useState<string>();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
      cellClassName: "super-app-theme--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "itemName",
      headerName: "Item Name",
      width: 200,
      cellClassName: "super-app-theme--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unitOfMeasure",
      headerName: "Unit Of Measurement",
      width: 200,
      cellClassName: "super-app-theme--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "expectedEndingQuantity",
      headerName: "Expected Ending Qty",
      width: 150,
      cellClassName: "super-app-theme--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "endingQuantity",
      headerName: "Ending Qty",
      width: 150,
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center",
      valueSetter: setEndingQuantity,
    },
    {
      field: "reason",
      headerName: "Reason for quantity change",
      width: 330,
      type: "string",
      editable: true,
      headerAlign: "center",
      align: "center",
      valueSetter: setReason,
    },
  ];
  const DataGridTheme = createTheme({
    components: {
      //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&.Mui-selected": {
              backgroundColor: "rgba(211, 47, 47, 0.3)",
              "&:hover": {
                backgroundColor: "rgba(211, 47, 47, 0.3)",
              },
            },
          },
        },
      },
    },
  });

  function setEndingQuantity(params: GridValueSetterParams) {
    const endQtyItem = rows.find((row) => row.id === params.row.id);
    if (endQtyItem != null) {
      endQtyItem.endingQuantity = params.value;
    }
    const [endingQuantity] = params.value!.toString().split(" ");
    return { ...params.row, endingQuantity };
  }

  function setReason(params: GridValueSetterParams) {
    const reasonItem = rows.find((row) => row.id === params.row.id);
    if (reasonItem != null) {
      reasonItem.reason = params.value;
    }
    const reason = params.value!.toString();
    return { ...params.row, reason };
  }

  useEffect(() => {
    if (getInitialDateDiscrep() != "" && getInitialDateDiscrep() != null) {
      setInitDate(moment(getInitialDateDiscrep()).format("dddd MMMM DD, YYYY"));
    } else {
      setInitDate(moment().format("dddd MMMM DD, YYYY"));
    }
    if (getHasSetFilterDiscrep()) {
      setHasSetFilterDiscrep(false);
    }
    if (getSelectedItem()?.length != 0) {
      getSelectedItem()?.forEach((element) => {
        var el = element;
        setRows((rows) => [...rows, el]);
      });
    } else {
      if (getDiscrepancyReportItems().length != 0) {
        setRows(getDiscrepancyReportItems());
        sethasRendered(false);
      }
    }
  }, [discrepancyReportItemRegistry.size]);

  const handleReport = () => {
    setHasReport(true);
  };

  const handleCloseReport = () => {
    setHasReport(false);
  };

  const handleSubmitted = () => {
    setHasReport(false);
    setHasSubmitted(true);
    let disDate = "";
    let disId = "";
    if (getInitialDateDiscrep() != "" && getInitialDateDiscrep() != null) {
      disDate = moment(getInitialDateDiscrep()).format("YYYY-MM-DD HH:mm:ss");
      disId = getInitialIdDiscrep();
    } else {
      disDate = moment().format("YYYY-MM-DD HH:mm:ss");
      disId = "";
    }
    const disDateConverted = new Date(disDate);
    let addDiscrepancyReport: AddDiscrepancyReport = {
      discrepancyReportId: disId,
      discrepancyReportDate: disDateConverted,
      departmentName: "",
      discrepancyReportItems: rows,
    };
    createDiscrepancyReport(addDiscrepancyReport);
  };

  const handleGenerateReport = () => {
    let discrepancyReportExcel: DiscrepancyReportExcel = {
      DiscrepancyReportItems: rows,
    };
    console.log(discrepancyReportExcel)
    generateExcelReport(discrepancyReportExcel);
  };

  const handleCloseSubmitted = () => {
    setHasSubmitted(false);
    history.push("/discrepancyreportlist");
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Discrepancy Report
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          {initDate}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <ThemeProvider theme={DataGridTheme}>
          <Box
            sx={{
              height: 840,
              width: "100%",
              "& .super-app-theme--cell": {
                backgroundColor: "rgba(211, 47, 47, 0.3)",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  pageSize: 5,
                },
              }}
              disableSelectionOnClick
              sx={{
                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                  {
                    "&:before": {
                      content: '"(*Required)"',
                      color: "#871C1C",
                      marginTop: "25px",
                      fontWeight: "bold",
                    },
                  },
              }}
            />
          </Box>
        </ThemeProvider>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={() => handleGenerateReport()}
              sx={{
                marginRight: 0,
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Export Excel
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleReport()}
              disableElevation
              sx={{
                backgroundColor: "error",
                marginRight: 0,
                "&:hover": {
                  backgroundColor: "error",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "error",
                  borderColor: "none",
                },
              }}
            >
              Submit Discrepancy Report
            </Button>
          </Box>
        </Stack>
        <Dialog open={hasReport}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit discrepancy report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseReport}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmitted()}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={hasSubmitted} onClose={handleCloseSubmitted}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                "& .css-i4bv87-MuiSvgIcon-root": {
                  "margin-bottom": "-5px",
                  "margin-left": "10px",
                  color: "#45D015",
                },
              }}
            >
              Report Submitted
              <CheckCircleIcon />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
});
