import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import SelectItemsToTransfer from "./SelectItemsToTransfer";
import TransferRequestDetail from "./TransferRequestDetail";
import TransferRequestsList from "./TransferRequestsList";

export default observer(function ViewTransferRequest() {
  const { transferRequestStore } = useStore();
  const { selectedTransferRequest, setSearchText, loadTransferRequests } =
    transferRequestStore;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    loadTransferRequests();
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Transfer Request
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Box sx={{ width: "100%", marginBottom: "20px" }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100% ",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Item Name..."
                  inputProps={{ "aria-label": "Search Item Name" }}
                  onChange={handleSearch}
                />
              </Paper>
            </Box>
            {selectedTransferRequest ? (
              <TransferRequestDetail />
            ) : (
              <TransferRequestsList />
            )}
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
