import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    Link,
    TextField,
  } from "@mui/material";
  import {
    ErrorMessage,
    Field,
    Form,
    Formik,
    FormikHelpers,
    FormikProps,
    useFormik,
  } from "formik";
  import { observer } from "mobx-react-lite";
  import React from "react";
  import { useStore } from "../../app/stores/store";
  import { FormTextField } from "../../app/common/form/FormTextField";
  import * as yup from "yup";

  interface Props{
    loginUser: () => void;
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
    password: yup.string().required("Required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup.string().required("required").oneOf([yup.ref("password"), null], "Passwords must match"),
    phoneNumber: yup.string().required("Required").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid'),
  });

  export default observer(function Register({loginUser}: Props) {
    const { userStore } = useStore();
  
    var isLoading = false;
  
    return (
      <>
        <Card sx={{ paddingBottom: 2, width: 375, minWidth: 345 }}>
          <CardMedia
            component="img"
            height="140"
            image="https://file.rendit.io/n/dpUDfwax2auOgIQJAKvX.png"
            alt="banhmi kitchen"
          />
          <CardContent>
            <Typography variant="h5" component="div">
              Create an account
            </Typography>
            <Typography
              sx={{ paddingBottom: 2 }}
              variant="body2"
              color="text.secondary"
            >
              Please fill up registration form
            </Typography>
  
            <Formik
              initialValues={{ email: "", password: "", phoneNumber: "", error: null }}
              validationSchema={validationSchema}
              onSubmit={(values, { setErrors }) =>
                userStore
                  .register(values)
                  .catch((error) =>
                    setErrors({ error: error })
                  )
              }
            >
              {({ handleSubmit, isSubmitting, errors }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        label="Email"
                        size="small"
                        component={FormTextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        label="Password"
                        type="password"
                        size="small"
                        component={FormTextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        size="small"
                        component={FormTextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="phoneNumber"
                        label="Phone Number"
                        size="small"
                        component={FormTextField}
                      />
                      <ErrorMessage
                      name="error"
                      render={() => (
                        <Typography sx={{ color: "red" }}>
                          {errors.error}
                        </Typography>
                      )}
                    />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disableElevation
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        Create an account
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
          <Link
            variant="body2"
            color="text.primary"
            component="button"
            onClick={() => loginUser()}
            sx={{ paddingLeft: 1, marginLeft: 1}}
          >
            Already have an account? Login here.
          </Link>
        </Card>
      </>
    );
  });
  