import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import {
  RepackRequestFormValues,
  RepackToRequest,
} from "../../../app/models/requests/repackRequest";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { ItemToTransfer } from "../../../app/models/requests/transferRequest";
import { history } from "../../..";

interface Props {
  closeForm: () => void;
}

export default observer(function RepackRequestForm({ closeForm }: Props) {
  const { inventoryStore, repackRequestStore, userStore } =
    useStore();
  const {
    selectedRepack,
    loadRepackRequestsToConfirm,
    setRepackForm,
    loadingInitial
  } = repackRequestStore;
  const {
    findInventory,
    loadInventory,
    loadingInitial: loadingInventory,
  } = inventoryStore;
  const { user } = userStore;
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [repackRequestFormValues, setComponentForm] =
    useState<RepackRequestFormValues>({
      quantityToRepack: 0,
      unitIdToRepack: "",
      repackingDepartment: user?.department || 0,
    });

  const validationSchema = yup.object().shape({
    quantityToRepack: yup.number().moreThan(0).required("Required"),
    unitIdToRepack: yup.string().required("Required"),
  });

  useEffect(() => {
    loadInventory().then(() => {
      let itemInventory = findInventory(selectedRepack!.itemId);
      let uOptions = new Map<string, UnitsOption>();
      itemInventory.forEach((inventory) => {
        let uOption: UnitsOption = {
          value: inventory.unitOfMeasureId,
          label: inventory.unitOfMeasure,
        };
        uOptions.set(uOption.value, uOption);
      });
      setUnitOptions(Array.from(uOptions.values()));
    });
  }, [findInventory, selectedRepack, loadInventory]);

  function handleFormSubmit(repackRequestFormValues: RepackRequestFormValues) {
    let repackRequestForm: RepackRequestFormValues = {
      ...repackRequestFormValues,
    };
    loadRepackRequestsToConfirm(
      selectedRepack!.id,
      repackRequestForm!.quantityToRepack,
      repackRequestForm!.unitIdToRepack,
      repackRequestForm!.repackingDepartment
    ).then(() => {
      console.log(loadingInitial);
      setRepackForm(repackRequestForm);
    });
  }

  if (loadingInventory && loadingInitial)
    return <LoadingComponent content="Loading Repack Request..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={repackRequestFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in repack information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="quantityToRepack"
                      label="Quantity to Repack"
                      size="small"
                      type="number"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="unitIdToRepack"
                      label="Unit to Repack"
                      size="small"
                      options={unitOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="repackingDepartment"
                      label="Repacking Department"
                      size="small"
                      options={departmentOptions}
                      component={FormSelect}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Request Repack
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
