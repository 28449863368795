import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface Props {
  content?: string;
}

export default function LoadingComponent({content = 'Loading...'}: Props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex',
      flexDirection: 'column' }}
      open={true}
    >
        <CircularProgress color="inherit" />
        <Typography>{content}</Typography>
    </Backdrop>
  );
}
