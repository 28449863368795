import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { ComponentFormValues } from "../../../app/models/admin/component";
import { string } from "yup/lib/locale";
import { ItemFormValues, ItemsOption } from "../../../app/models/admin/items";
import {
  BaseUnits,
  BaseUnitsOption,
  UnitsOption,
} from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { CategoryOption } from "../../../app/models/admin/category";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { LocationsOption } from "../../../app/models/admin/location";
import { IncomingStockFormValues } from "../../../app/models/Inventories/inventory";
import { SupplierOption } from "../../../app/models/admin/suppliers";
import { history } from "../../..";
import MyDateInput from "../../../app/common/form/MyDateInput";

export default observer(function IncomingStockForm() {
  const {
    incomingStockStore,
    itemStore,
    supplierStore,
    unitStore,
    locationStore,
  } = useStore();
  const { createIncomingStock, loading } = incomingStockStore;
  const { getSupplierOptions, loadingInitial: supplierloading } = supplierStore;
  const { getNonePaginatedItemOptions, loadingInitial: itemsloading } = itemStore;
  const { getUnitOptions, getBaseUnitOptions, loadingInitial: unitsloading } = unitStore;
  const { getLocationOptions, loadingInitial: locationloading } = locationStore;
  const [title, setTitle] = useState<string>("Add Incoming Stock");
  const [supplierOptions, setSupplierOptions] = useState<SupplierOption[]>([]);
  const [itemOptions, setItemOptions] = useState<ItemsOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<LocationsOption[]>([]);
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [baseUnitOptions, setBaseUnitOptions] = useState<BaseUnitsOption[]>([]);
  const [incomingStockFormValues, setIncomingStockForm] =
    useState<IncomingStockFormValues>({
      id: "",
      itemId: "",
      supplierId: "",
      brand: "",
      quantity: 0,
      unitOfMeasureId: "",
      isCustomUnit: false,
      customUnitName: "", // custom unit name
      baseUnitQuantity: 0,
      baseUnitId: "",
      locationId: "",
      dateReceived: null,
      expiration: null,
    });

  const validationSchema = yup.object().shape({
    itemId: yup.string().required("Required"),
    supplierId: yup.string().required("Required"),
    brand: yup.string().required("Required"),
    locationId: yup.string().required("Required"),
    quantity: yup.number().moreThan(0).required("Required"),
    unitOfMeasureId: yup.string().when("isCustomUnit", {
      is: false,
      then: yup.string().required("Required"),
    }),
    baseUnitQuantity: yup.number().when("isCustomUnit", {
      is: true,
      then: yup.number().moreThan(0).required("Required"),
    }),
    customUnitName: yup.string().when("isCustomUnit", {
      is: true,
      then: yup.string().required("Required"),
    }),
    baseUnitId: yup.string().when("isCustomUnit", {
      is: true,
      then: yup.string().required("Required"),
    }),
    dateReceived: yup.date().required("Required"),
  });

  useEffect(() => {
    getNonePaginatedItemOptions().then((itemOptions) => {
      let iOptions: ItemsOption[] = [];
      itemOptions!.forEach((option) => {
        let iOption: ItemsOption = { value: option.value, label: option.label };
        iOptions.push(iOption);
      });
      setItemOptions(iOptions);
    });

    getSupplierOptions().then((supplierOptions) => {
      let sOptions: SupplierOption[] = [];
      supplierOptions!.forEach((option) => {
        let sOption: SupplierOption = {
          value: option.value,
          label: option.label,
        };
        sOptions.push(sOption);
      });
      setSupplierOptions(sOptions);
    });

    getUnitOptions().then((unitOptions) => {
      let uOptions: UnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: UnitsOption = { value: option.value, label: option.label };
        uOptions.push(uOption);
      });
      setUnitOptions(uOptions);
    });

    getBaseUnitOptions().then((unitOptions) => {
      let uOptions: BaseUnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: BaseUnitsOption = {
          value: option.value,
          label: option.label,
        };
        uOptions.push(uOption);
      });
      setBaseUnitOptions(uOptions);
    });

    getLocationOptions().then((locationOptions) => {
      let lOptions: LocationsOption[] = [];
      locationOptions!.forEach((option) => {
        let lOption: LocationsOption = {
          value: option.value,
          label: option.label,
        };
        lOptions.push(lOption);
      });
      setLocationOptions(lOptions);
    });
  }, [getNonePaginatedItemOptions, getUnitOptions, getLocationOptions, getSupplierOptions, getBaseUnitOptions]);

  function handleFormSubmit(incomingStockFormValues: IncomingStockFormValues) {
    console.log(incomingStockFormValues);
    let incomingStock: IncomingStockFormValues = {
      ...incomingStockFormValues,
    };
    incomingStock.isCustomUnit ? incomingStock.unitOfMeasureId = "00000000-0000-0000-0000-000000000000" : incomingStock.baseUnitId = "00000000-0000-0000-0000-000000000000"
    createIncomingStock(incomingStock).then(() => history.push(`/inventory`));
  }

  if (supplierloading && itemsloading && supplierloading && unitsloading && locationloading)
    return <LoadingComponent content="Loading IncomingStock..." />;

  if (loading)
    return <LoadingComponent content="Submitting IncomingStock..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={incomingStockFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ paddingTop: 0, paddingLeft: 2, paddingBottom: 2 }}>
                <Typography variant="h3" color="text.secondary">
                  Incoming Stock
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Please fill in incoming stock information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              {/* input fields start */}
              <Grid item container spacing={2} xs={12} md={12}>
                <Grid item xs={12}>
                  <Field
                    name="itemId"
                    label="Item"
                    size="small"
                    options={itemOptions}
                    component={FormSelect}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="supplierId"
                    label="Supplier"
                    size="small"
                    options={supplierOptions}
                    component={FormSelect}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="brand"
                    label="Brand"
                    size="small"
                    component={FormTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="quantity"
                    type="number"
                    label="Quantity"
                    size="small"
                    component={FormTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="unitOfMeasureId"
                    label="Unit"
                    size="small"
                    disabled={values.isCustomUnit}
                    options={unitOptions}
                    component={FormSelect}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component="legend">
                    create custom unit of measure?
                  </FormLabel>
                  <Switch
                    name="isCustomUnit"
                    checked={values.isCustomUnit}
                    onChange={(event, checked) => {
                      setFieldValue("isCustomUnit", checked ? true : false);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="customUnitName"
                    label="Custom Unit"
                    size="small"
                    disabled={!values.isCustomUnit}
                    component={FormTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="baseUnitQuantity"
                    type="number"
                    label="Base Unit Quantity"
                    size="small"
                    disabled={!values.isCustomUnit}
                    component={FormTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="baseUnitId"
                    label="Base Unit"
                    size="small"
                    disabled={!values.isCustomUnit}
                    options={baseUnitOptions}
                    component={FormSelect}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="locationId"
                    label="Location"
                    size="small"
                    options={locationOptions}
                    component={FormSelect}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component="legend">Date Received</FormLabel>
                  <MyDateInput
                    placeholderText="Date Received"
                    name="dateReceived"
                    dateFormat="MMMM d, yyyy"
                  />
                </Grid>

                <Grid item xs={12}>
                <FormLabel component="legend">Expiration Date</FormLabel>
                  <MyDateInput
                    placeholderText="Expiration Date"
                    name="expiration"
                    dateFormat="MMMM d, yyyy"
                  />
                </Grid>
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item>
                <Stack direction="row">
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                  />
                  
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "orange",
                      "&:hover": {
                        backgroundColor: "orange",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
