import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { CategoryFormValues } from "../../../app/models/admin/category";

interface Props {
  closeForm: () => void;
}

export default observer(function CategoryForm({ closeForm }: Props) {
  const { categoryStore } = useStore();
  const {
    selectedCategory,
    createCategory,
    updateCategory,
    loadingInitial,
  } = categoryStore;
  const [title, setTitle] = useState<string>("Create Category");
  const [categoryFormValues, setCategoryForm] = useState<CategoryFormValues>({
    id: "",
    name: ""
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedCategory) {
      setTitle("Update Category");
      let formData: CategoryFormValues = {
        id: selectedCategory.id,
        name: selectedCategory.name
      };
      setCategoryForm(formData);
    }
  }, [selectedCategory]);

  function handleFormSubmit(categoryFormValues: CategoryFormValues) {
    let category: CategoryFormValues = {
      ...categoryFormValues,
    };
    if (!selectedCategory) {
        createCategory(category).then(() => closeForm());
    } else {
        updateCategory(category).then(() => closeForm());
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading Category..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={categoryFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in category information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Category Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
