import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Item } from "../../models/admin/items";
import { Location, LocationFormValues, LocationList, LocationsOption,FilterDepartment } from "../../models/admin/location";
import { Pagination, PagingParams } from "../../models/pagination";

export default class LocationStore {
    locationRegistry = new Map<string, LocationList>();
    locationOptions = new Map<string, LocationsOption>();
    itemCount: number = 0;
    selectedLocation: Location | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getLocations = () => {
        return Array.from(this.locationRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getLocationOptions = async () => {
        this.loadingInitial = true;
        let locationOptions = this.locationOptions;
        try {
            const user = await agent.Account.current();
            let filterDepartment: FilterDepartment = {
                department: user.department
            };
            const result = await agent.Locations.options(filterDepartment);
            result.forEach(location => {
                let locationOption: LocationsOption = {
                    value: location.id,
                    label: location.name
                }
                this.locationOptions.set(location.id, locationOption);
            });
            this.setLoadingInitial(false);
            return locationOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadLocation = async (id: string) => {
        try {
            var location = await agent.Locations.detail(id);
            this.setLocation(location);
            location.lastUpdated = new Date(location.lastUpdated!);
            runInAction(() => {
                this.selectedLocation = location;
            })
            this.setLoadingInitial(false);
            return location;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadLocations = async () => {
        this.loadingInitial = true;
        this.locationRegistry.clear();
        try {
            const result = await agent.Locations.list(this.axiosParams,"");
            result.data.forEach(location => {
                this.setLocation(location);
                let locationOption: LocationsOption = {
                    value: location.id,
                    label: location.name
                }
                this.setLocationOption(locationOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchLocations = async (searchText: string) => {
        this.locationRegistry.clear();
        try {
            const result = await agent.Locations.list(this.axiosParams,searchText);
            result.data.forEach(location => {
                this.setLocation(location);
                let locationOption: LocationsOption = {
                    value: location.id,
                    label: location.name
                }
                this.setLocationOption(locationOption);
            })
            this.setPagination(result.pagination);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    findLocationByDepartment = (department: number) => {
        var locationList = new Map<string, LocationList>();
        this.getLocations().forEach((value) => {
             if(value.department === department){
                locationList.set(value.id, value);
             }
        });
        return Array.from(locationList.values());
    }

    unSelectLocation = () => {
        this.selectedLocation = undefined;
    }

    private setLocation = (location: LocationList) => {
        this.locationRegistry.set(location.id, location);
    }

    private setLocationOption = (locationOption: LocationsOption) => {
        this.locationOptions.set(locationOption.value, locationOption);
    }

    private getSelectedLocation = () => {
        return this.selectedLocation;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createLocation = async (location: LocationFormValues) => {
        this.loading = true;
        try {
            var locationId = (await agent.Locations.create(location)).data;
            runInAction(async() => {
                this.loadLocation(locationId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateLocation = async (location: LocationFormValues) => {
        this.loading = true;
        try {
            var locationId = (await agent.Locations.update(location)).data;
            runInAction(async() => {
                this.loadLocation(locationId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveLocation= async (classificationId: string) => {
        this.loading = true;
        try {
            await agent.Locations.delete(classificationId)
            runInAction(async() => {
                this.loadLocations().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}