import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useStore } from '../../../app/stores/store';

export default observer(function PasswordResetRequestList() {
    const { userStore } = useStore();
    const { passwordResetRequests, loadPasswordResetRequests, approvePasswordResetRequest } = userStore;

    useEffect(() => {
        loadPasswordResetRequests();
    }, [loadPasswordResetRequests]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Request Date</TableCell>
                        <TableCell>Approved</TableCell>
                        <TableCell>Approver</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {passwordResetRequests.map(request => (
                        <TableRow key={request.id}>
                            <TableCell>{request.email}</TableCell>
                            <TableCell>{request.requestDate.toString()}</TableCell>
                            <TableCell>{request.isApproved ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{request.approver?.email}</TableCell>
                            <TableCell>
                                {!request.isApproved && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => approvePasswordResetRequest(request.id)}
                                    >
                                        Approve
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});
