import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import zIndex from "@mui/material/styles/zIndex";
import { useStore } from "../stores/store";
import { ProfileNav } from "../models/profile";
import { history } from "../..";
import { NavLink } from "react-router-dom";

interface Props {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

const settings = ["Profile", "Logout"];

export default function NavBar({ drawerWidth, handleDrawerToggle }: Props) {
  const {
    userStore: { user, checkIfProfileCreated, logout },
    profileStore: { getEmployeeProfile, getFranchiseeProfile },
  } = useStore();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileMenu = () => {
    handleCloseUserMenu();
    history.push("/manage-profile");
  };

  const [profile, setProfile] = useState<ProfileNav>({
    name: "",
    profilePhoto: "",
  });

  useEffect(() => {
    if (checkIfProfileCreated() && user!.userProfile == 1) {
      getEmployeeProfile().then((employeeProfile) => {
        let profile: ProfileNav = {
          name: employeeProfile!.firstName + " " + employeeProfile!.lastName,
          profilePhoto: employeeProfile!.profilePhoto,
        };
        setProfile(profile);
      });
    } else if (checkIfProfileCreated() && user!.userProfile == 2) {
      getFranchiseeProfile().then((franchiseeProfile) => {
        let profile: ProfileNav = {
          name: franchiseeProfile!.companyName,
          profilePhoto: franchiseeProfile!.profilePhoto,
        };
        setProfile(profile);
      });
    }
  }, [checkIfProfileCreated]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100%)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "black",
        zIndex: 1400,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          noWrap
          component={NavLink}
          to="/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "flex" },
            flexGrow: 1,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Banh Mi Kitchen
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title={profile.name}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={profile.name} src={profile.profilePhoto} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px", zIndex: 1401 }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="Profile" onClick={handleProfileMenu}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem key="Logout" onClick={logout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
