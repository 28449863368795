import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { ItemsOption } from "../../../app/models/admin/items";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { sizeOptions } from "../../../app/common/options/sizeOptions";
import { FormMultiSelect } from "../../../app/common/form/FormMultiSelect";
import { IngredientOption } from "../../../app/models/admin/ingredient";
import { ComponentsOption } from "../../../app/models/admin/component";
import {
  RecipeFormValues,
  RecipeIngredientsFormValues,
} from "../../../app/models/admin/recipe";
import LoadingComponent from "../../../app/layout/LoadingComponent";

interface Props {
  closeForm: () => void;
}

export default observer(function RecipeForm({ closeForm }: Props) {
  const { recipeStore, ingredientStore, componentStore, itemStore, unitStore } =
    useStore();
  const { selectedRecipe, createRecipe, updateRecipe, loadingInitial } =
    recipeStore;
  const { getNonePaginatedIngredientOptions, loadingInitial: ingredientsLoading } =
    ingredientStore;
  const { getNonePaginatedComponentOptions, loadingInitial: componentsLoading } =
    componentStore;
  const { getNonePaginatedItemOptions, loadingInitial: loadItemOptions } = itemStore;
  const { getUnitOptions, loadingInitial: loadUnitOptions } = unitStore;
  const [title, setTitle] = useState<string>("Create Recipe");
  const [ingredientOptions, setingredientOptions] = useState<
    IngredientOption[]
  >([]);
  const [ingredientsToDelOptions, setIngredientsToDelOptions] = useState<
    IngredientOption[]
  >([]);
  const [componentOptions, setComponentOptions] = useState<ComponentsOption[]>(
    []
  );
  const [itemOptions, setItemOptions] = useState<ItemsOption[]>([]);
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [RecipeFormValues, setRecipeForm] = useState<RecipeFormValues>({
    id: "",
    name: "",
    serving: 0,
    componentId: "",
    ingredientsToDelete: [],
    ingredients: [],
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    componentId: yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedRecipe) {
      setTitle("Update Recipe");
      let formData: RecipeFormValues = {
        id: selectedRecipe.id,
        name: selectedRecipe.name,
        serving: selectedRecipe.serving,
        componentId: selectedRecipe.componentId,
        ingredientsToDelete: [],
        ingredients: [],
      };
      setRecipeForm(formData);
    }

    getNonePaginatedComponentOptions().then((componentOptions) => {
      let cOptions: ComponentsOption[] = [];
      componentOptions!.forEach((option) => {
        let cOption: ComponentsOption = {
          value: option.value,
          label: option.label,
        };
        cOptions.push(cOption);
      });
      cOptions.sort((a, b) => a.label.localeCompare(b.label));
      setComponentOptions(cOptions);
    });

    getNonePaginatedItemOptions().then((itemOptions) => {
      let iOptions: ItemsOption[] = [];
      itemOptions!.forEach((option) => {
        let iOption: ItemsOption = { value: option.value, label: option.label };
        iOptions.push(iOption);
      });
      iOptions.sort((a, b) => a.label.localeCompare(b.label));
      setItemOptions(iOptions);
    });

    getUnitOptions().then((unitOptions) => {
      let uOptions: UnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: UnitsOption = { value: option.value, label: option.label };
        uOptions.push(uOption);
      });
      uOptions.sort((a, b) => a.label.localeCompare(b.label));
      setUnitOptions(uOptions);
    });

    getNonePaginatedIngredientOptions().then((ingredientOptions) => {
      let iOptions: IngredientOption[] = [];
      ingredientOptions!.forEach((option) => {
        let iOption: IngredientOption = {
          value: option.value,
          label: option.label,
        };
        iOptions.push(iOption);
      });
      iOptions.sort((a, b) => a.label.localeCompare(b.label));
      setingredientOptions(iOptions);

      let defaultIngredientOptions: IngredientOption[] = [];
      selectedRecipe!.ingredients!.forEach((ingredient) => {
        var ingredientOption = iOptions.find((ingOption) => {
          return ingOption.value === ingredient.id;
        });

        if (ingredientOption) defaultIngredientOptions.push(ingredientOption);
      });
      setIngredientsToDelOptions(defaultIngredientOptions);
    });
  }, [selectedRecipe, getNonePaginatedComponentOptions, getNonePaginatedIngredientOptions]);

  function handleFormSubmit(recipeFormValues: RecipeFormValues) {
    let recipe: RecipeFormValues = {
      ...recipeFormValues,
    };

    if (!selectedRecipe) {
      createRecipe(recipe).then(() => closeForm());
    } else {
      updateRecipe(recipe).then(() => closeForm());
    }
  }

  if (loadingInitial && ingredientsLoading && componentsLoading)
    return <LoadingComponent content="Loading ingredient..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={RecipeFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in Recipe information
                </Typography>
              </Grid>
              {/* header end */}
              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Recipe Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ marginBottom: 1 }}>
                    <Field
                      name="serving"
                      label="Serving"
                      size="small"
                      type="number"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="componentId"
                      label="Component"
                      size="small"
                      options={componentOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  {selectedRecipe ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        limitTags={5}
                        id="tags-outlined"
                        options={ingredientsToDelOptions}
                        getOptionLabel={(option: ComponentsOption) =>
                          option.label
                        }
                        filterSelectedOptions
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(e, value) => {
                          value.forEach((ingredientOptionToDelete) => {
                            values.ingredientsToDelete!.push(
                              ingredientOptionToDelete.value
                            );
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Ingredients To Delete"
                            sx={{
                              backgroundColor: "white",
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "gray",
                              }, //styles the label
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": { borderColor: "gray" },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* ingredient Form Start */}
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                      <FormLabel component="legend">Ingredients</FormLabel>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper style={{ backgroundColor: "#EFEFEF" }}>
                      <Box
                        style={{
                          height: "35vh",
                          maxHeight: "35vh",
                          overflow: "auto",
                        }}
                        sx={{ padding: 1 }}
                      >
                        {/* list Ingredients here */}
                        <FieldArray name="ingredients">
                          {({ insert, remove, push }) => (
                            <Grid item xs={12}>
                              <Stack spacing={1} sx={{ marginBottom: 1 }}>
                                {values.ingredients!.map(
                                  (ingredient, index) => (
                                    <Card key={index} sx={{ padding: 1 }}>
                                      {ingredient.isNew == true ? (
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`ingredients.${index}.itemId`}
                                              label="Item"
                                              size="small"
                                              options={itemOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`ingredients.${index}.Quantity`}
                                              label="Quantity"
                                              size="small"
                                              type="number"
                                              component={FormTextField}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`ingredients.${index}.unitId`}
                                              label="Unit"
                                              size="small"
                                              options={unitOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Stack spacing={1}>
                                            <Button
                                              variant="outlined"
                                              onClick={() => remove(index)}
                                              size="small"
                                              color="error"
                                              disabled={isSubmitting}
                                              disableElevation
                                            >
                                              Remove Ingredient
                                            </Button>
                                          </Stack>
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}

                                      {ingredient.isNew == false ? (
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`ingredients.${index}.ingredientId`}
                                              label="Ingredient"
                                              size="small"
                                              options={ingredientOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Stack spacing={1}>
                                            <Button
                                              variant="outlined"
                                              onClick={() => remove(index)}
                                              size="small"
                                              color="error"
                                              disabled={isSubmitting}
                                              disableElevation
                                            >
                                              Remove Ingredient
                                            </Button>
                                          </Stack>
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                    </Card>
                                  )
                                )}
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    push({
                                      isNew: true,
                                      ingredientId: null,
                                      itemId: "",
                                      quantity: 0,
                                      unitId: "",
                                    } as RecipeIngredientsFormValues);
                                  }}
                                  size="small"
                                  color="primary"
                                  disabled={isSubmitting}
                                  disableElevation
                                  sx={{
                                    backgroundColor: "orange",
                                    "&:hover": {
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                      boxShadow: "none",
                                    },
                                    "&:active": {
                                      boxShadow: "none",
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                    },
                                  }}
                                >
                                  Create Ingredient
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    push({
                                      isNew: false,
                                      ingredientId: "",
                                      itemId: null,
                                      quantity: null,
                                      unitId: null,
                                    } as RecipeIngredientsFormValues);
                                  }}
                                  size="small"
                                  color="primary"
                                  disabled={isSubmitting}
                                  disableElevation
                                  sx={{
                                    backgroundColor: "orange",
                                    "&:hover": {
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                      boxShadow: "none",
                                    },
                                    "&:active": {
                                      boxShadow: "none",
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                    },
                                  }}
                                >
                                  Select Existing Ingredient
                                </Button>
                              </Stack>
                            </Grid>
                          )}
                        </FieldArray>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
