import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { TabPanel } from "../../../app/common/panel/TabPanel";
import { useStore } from "../../../app/stores/store";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { add } from "date-fns";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import SummaryOfFranchiseeOrder from "./SummaryOfFranchiseeOrder";

export default observer(function PurchaseOrderRequest() {
  const { classificationStore, menuStore, franchiseeOrderRequestStore } =
    useStore();
  const { loadNonePaginatedClassifications, getNonePaginatedClassifications } = classificationStore;
  const { loadNonePaginatedMenus, getNonPaginatedMenus, franchiseeOrderQuantity, loadingInitial } =
    menuStore;
  const { createFranchiseeOrderRequest } = franchiseeOrderRequestStore;
  const [value, setValue] = React.useState(0);
  const [viewCart, setViewCart] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    loadNonePaginatedClassifications().then(() => {
      loadNonePaginatedMenus().then(() => {
        getNonePaginatedClassifications().forEach((classification) => {
          getNonPaginatedMenus(classification.name).forEach((menu) => {
            franchiseeOrderQuantity.set(menu.id, 0);
          });
        });
      });
    });
  }, [loadNonePaginatedClassifications, loadNonePaginatedMenus, getNonePaginatedClassifications, getNonPaginatedMenus, franchiseeOrderQuantity]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleAdd = (menuId: string) => {
    let count = franchiseeOrderQuantity.get(menuId);
    count!++;
    franchiseeOrderQuantity.set(menuId, count!);
  };

  const handleViewCart = () => {
    setViewCart(true);
  };

  const handleCloseCart = () => {
    setViewCart(false);
  };

  const handleMinus = (menuId: string) => {
    let count = franchiseeOrderQuantity.get(menuId);
    if (count != 0) {
      count!--;
      franchiseeOrderQuantity.set(menuId, count!);
    }
  };

  if (loadingInitial) return <LoadingComponent content="Loading Menu..." />;

  return (
    <>
      {/* <Typography sx={{ padding: 1 }} variant="h4">
        Order Request
      </Typography> */}
      <Stack
        direction="row"
        sx={{
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="h6"
          color="text.secondary"
        >
          Click the add or minus buttons to add or remove an order
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        <Button
          startIcon={<ShoppingCartIcon />}
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          onClick={handleViewCart}
          sx={{
            marginRight: 3,
            backgroundColor: "orange",
            "&:hover": {
              backgroundColor: "orange",
              borderColor: "none",
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: "orange",
              borderColor: "none",
            },
          }}
        >
          view cart
        </Button>
        <Dialog
          fullScreen={fullScreen}
          maxWidth="lg"
          fullWidth
          open={viewCart}
          onClose={handleCloseCart}
          aria-labelledby="responsive-dialog-title"
          sx={{
            zIndex: 1500,
            overflow: "auto",
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            Summary Of Orders
          </DialogTitle>
          <DialogContent style={{ maxHeight: "70vh" }}>
            <SummaryOfFranchiseeOrder />
          </DialogContent>
        </Dialog>
      </Stack>

      <Box sx={{ bgcolor: "#EFEFEF" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {getNonePaginatedClassifications().map((classification) => (
            <Tab label={classification.name} />
          ))}
        </Tabs>

        <Divider light />

        {getNonePaginatedClassifications().map((classification, index) => (
          <TabPanel value={value} index={index}>
            <Box
              style={{
                height: "67vh",
                maxHeight: "67vh",
                overflow: "auto",
              }}
              sx={{ backgroundColor: "#EFEFEF", padding: 5 }}
            >
              {getNonPaginatedMenus(classification.name).map((menu) => (
                <Grid
                  container
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      {menu.name}
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} md={4}>
                    <Stack direction="row">
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        onClick={() => handleMinus(menu.id)}
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                          display: { xs: "flex" },
                        }}
                      >
                        <RemoveIcon />
                      </Button>

                      <TextField
                        label="Quantity"
                        size="medium"
                        sx={{
                          backgroundColor: "white",
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                        value={franchiseeOrderQuantity.get(menu.id)}
                      />

                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        onClick={() => handleAdd(menu.id)}
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={4}></Grid>
                </Grid>
              ))}
            </Box>
          </TabPanel>
        ))}
      </Box>
    </>
  );
});
