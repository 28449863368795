import { Card, CardActionArea, CardHeader, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function LocationList() {
  const { locationStore } = useStore();
  const { getLocations, loadLocation } = locationStore;

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getLocations().map((location) => (
          <Card key={location.id} onClick={() => loadLocation(location.id)}>
            <CardActionArea>
              <CardHeader
                title={location.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
});
