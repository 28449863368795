import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Item, ItemFormValues, ItemList, ItemsOption } from "../../models/admin/items";
import { Pagination, PagingParams } from "../../models/pagination";

export default class ItemStore {
    itemRegistry = new Map<string, ItemList>();
    itemOptions = new Map<string, ItemsOption>();
    nonePaginatedItemOptions = new Map<string, ItemsOption>();
    itemCount: number = 0;
    selectedItem: Item | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getItems = () => {
        return Array.from(this.itemRegistry.values()).sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.name.localeCompare(b.name));
    }

    getNonePaginatedItemOptions = async () => {
        this.loadingInitial = true;
        let nonePaginatedItemOptions = this.nonePaginatedItemOptions;
        try {
            const items = await agent.Items.options();
            items.forEach(item => {
                let itemOption: ItemsOption = {
                    value: item.id,
                    label: item.name
                }
                this.nonePaginatedItemOptions.set(item.id, itemOption);
            });
            this.setLoadingInitial(false);
            return nonePaginatedItemOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadItem = async (id: string) => {
            try {
                var item = await agent.Items.detail(id);
                this.setItem(item);
                runInAction(() => {
                    this.selectedItem = item;
                })
                return item;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadItems = async () => {
        this.loadingInitial = true;
        this.itemRegistry.clear();
        try {
            const result = await agent.Items.list(this.axiosParams,"");
            result.data.forEach(item => {
                this.setItem(item);
                let itemOption: ItemsOption = {
                    value: item.id,
                    label: item.name
                }
                this.setItemOption(itemOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchItems= async (searchText: string) => {
        this.itemRegistry.clear();
        try {
            const result = await agent.Items.list(this.axiosParams,searchText);
            result.data.forEach(item => {
                this.setItem(item);
                let itemOption: ItemsOption = {
                    value: item.id,
                    label: item.name
                }
                this.setItemOption(itemOption);
            })
            this.setPagination(result.pagination);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }


    unSelectItem = () => {
        this.selectedItem = undefined;
    }

    private setItem = (item: ItemList) => {
        this.itemRegistry.set(item.id, item);
    }

    private setItemOption = (itemOption: ItemsOption) => {
        this.itemOptions.set(itemOption.value, itemOption);
    }

    private getSelectedItem = () => {
        return this.selectedItem;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createItem = async (item: ItemFormValues) => {
        this.loading = true;
        try {
            var itemtId = (await agent.Items.create(item)).data;
            runInAction(async() => {
                this.loadItem(itemtId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateItem = async (item: ItemFormValues) => {
        this.loading = true;
        try {
            var itemtId = (await agent.Items.update(item)).data;
            runInAction(async() => {
                this.loadItem(itemtId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    
    archiveItem = async (itemtId: string) => {
        this.loading = true;
        try {
            await agent.Items.delete(itemtId);
            runInAction(async() => {
                this.loadItems().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}