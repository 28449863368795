import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function ComponentDetail() {
  const { componentStore } = useStore();
  const { selectedComponent } = componentStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedComponent
          ? selectedComponent.name
          : "Select a component from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedComponent
                ? "Item Name: " + selectedComponent.itemName
                : "Item Name: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedComponent
                ? "Quantity: " + selectedComponent.quantity
                : "Quantity: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedComponent
                ? "Unit: " + selectedComponent.unit
                : "Unit: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">
              {selectedComponent
                ? "Price: " + selectedComponent.price
                : "Price: "}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
