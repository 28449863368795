import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { ItemsOption } from "../../../app/models/admin/items";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { FormMultiSelect } from "../../../app/common/form/FormMultiSelect";
import { IngredientFormValues } from "../../../app/models/admin/ingredient";

interface Props {
  closeForm: () => void;
}

export default observer(function IngredientForm({ closeForm }: Props) {
  const { ingredientStore,  itemStore, unitStore } = useStore();
  const {
    selectedIngredient,
    createIngredient,
    updateIngredient,
    loadingInitial,
  } = ingredientStore;
  const { getNonePaginatedItemOptions, loadingInitial: itemsloading } = itemStore;
  const { getUnitOptions, loadingInitial: unitsloading} = unitStore;
  const [title, setTitle] = useState<string>("Create Ingredient");
  const [itemOptions, setItemOptions] = useState<ItemsOption[]>([]);
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [ingredientFormValues, setIngredientForm] = useState<IngredientFormValues>(
    {
      id: "",
      itemId: "",
      quantity: 0,
      unitId: "",
    }
  );

  const validationSchema = yup.object().shape({
    itemId: yup.string().required("Required"),
    quantity: yup.number().required("Required"),
    unitId: yup.string().required("Required")
  });

  useEffect(() => {
    if (selectedIngredient) {
      setTitle("Update Ingredient");
      let formData: IngredientFormValues = {
        id: selectedIngredient.id,
        itemId: selectedIngredient.itemId,
        quantity: selectedIngredient.quantity,
        unitId: selectedIngredient.unitId,
      };
      setIngredientForm(formData);
    }

    getNonePaginatedItemOptions().then((itemOptions) => {
      let iOptions: ItemsOption[] = [];
      itemOptions!.forEach((option) => {
        let iOption: ItemsOption = { value: option.value, label: option.label };
        iOptions.push(iOption);
      });
      iOptions.sort((a, b) => a.label.localeCompare(b.label));
      setItemOptions(iOptions);
    });

    getUnitOptions().then((unitOptions) => {
        let uOptions: UnitsOption[] = [];
        unitOptions!.forEach((option) => {
          let uOption: UnitsOption = { value: option.value, label: option.label };
          uOptions.push(uOption);
        });
        uOptions.sort((a, b) => a.label.localeCompare(b.label));
        setUnitOptions(uOptions);
      });

  }, [selectedIngredient, getNonePaginatedItemOptions, getUnitOptions]);

  function handleFormSubmit(ingredientFormValues: IngredientFormValues) {
    let ingredient: IngredientFormValues = {
      ...ingredientFormValues,
    };
    if (!selectedIngredient) {
      createIngredient(ingredient).then(() => closeForm());
    } else {
      updateIngredient(ingredient).then(() => closeForm());
    }
  }

  if (loadingInitial && itemsloading && unitsloading)
    return <LoadingComponent content="Loading Ingredients..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={ingredientFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, values }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in Ingredient information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="itemId"
                      label="Item"
                      size="small"
                      options={itemOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="quantity"
                      label="Quantity"
                      size="small"
                      type="number"
                      component={FormTextField}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Field
                      name="unitId"
                      label="Unit"
                      size="small"
                      options={unitOptions}
                      component={FormSelect}
                    />
                  </Grid>

                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
