import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { useStore } from "../../../app/stores/store";
import ProcessRequestDetail from "./ProcessRequestDetail";
import ProcessRequestList from "./ProcessRequestList";
  
  export default observer(function ViewProcessRequest() {
    const { processRequestStore } = useStore();
    const { selectedProcessRequest } = processRequestStore;
  
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ padding: 1 }} variant="h3">
            Process Request
          </Typography>
  
          <Divider sx={{ marginBottom: 2 }} />
  
          <Grid container direction="row" spacing={0}>
            <Grid item xs={0} md={0} lg={2}></Grid>
  
            <Grid item xs={12} md={12} lg={8}>
              {selectedProcessRequest ? (
                <ProcessRequestDetail />
              ) : (
                <ProcessRequestList />
              )}
            </Grid>
  
            <Grid item xs={0} md={0} lg={2}></Grid>
          </Grid>
        </Box>
      </>
    );
  });
  