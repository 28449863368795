import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import ConfirmRepackRequest from "./ConfirmRepackRequest";
import SelectItemsToRepack from "./SelectItemsToRepack";

export default observer(function CreateRepackRequest() {
  const { repackRequestStore } = useStore();
  const { filledRepackForm } = repackRequestStore;
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Create Repack Request
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          This is where we can create a repack request
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            { filledRepackForm ? (
                <ConfirmRepackRequest />
              ) : (
                <SelectItemsToRepack />
              )}
            
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
