import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { MenuFormValues } from "../../../app/models/admin/menus";
import { ClassificationOption } from "../../../app/models/admin/classification";
import { FormSelect } from "../../../app/common/form/FormSelect";

interface Props {
  closeForm: () => void;
}

export default observer(function MenuForm({ closeForm }: Props) {
  const { menuStore, classificationStore } = useStore();
  const {
    selectedMenu,
    createMenu,
    updateMenu,
    loadingInitial,
  } = menuStore;
  const { getNonePaginatedClassificationOptions } = classificationStore;
  const [title, setTitle] = useState<string>("Create Menu");
  const [classificationOptions, setClassificationOptions] = useState<ClassificationOption[]>([]);
  const [menuFormValues, setMenuForm] = useState<MenuFormValues>({
    id: "",
    name: "",
    classificationId: ""
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    classificationId: yup.string().required("Required")
  });

  useEffect(() => {
    if (selectedMenu) {
      setTitle("Update Menu");
      let formData: MenuFormValues = {
        id: selectedMenu.id,
        name: selectedMenu.name,
        classificationId: selectedMenu.classificationId
      };
      setMenuForm(formData);
    }

    getNonePaginatedClassificationOptions().then((classificationOptions) => {
        let cOptions: ClassificationOption[] = [];
        classificationOptions!.forEach((option) => {
          let cOption: ClassificationOption = { value: option.value, label: option.label };
          cOptions.push(cOption);
        });
        cOptions.sort((a, b) => a.label.localeCompare(b.label));
        setClassificationOptions(cOptions);
    });
  }, [selectedMenu]);

  function handleFormSubmit(menuFormValues: MenuFormValues) {
    let menu: MenuFormValues = {
      ...menuFormValues,
    };
    if (!selectedMenu) {
        createMenu(menu).then(() => closeForm());
    } else {
        updateMenu(menu).then(() => closeForm());
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading menu..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={menuFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in category information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>

                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Menu Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="classificationId"
                      label="Classification"
                      size="small"
                      options={classificationOptions}
                      component={FormSelect}
                    />
                  </Grid>

                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
