import { Card, CardActionArea, CardHeader, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function ComponentList() {
  const { componentStore } = useStore();
  const { getComponents, loadComponent } = componentStore;

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getComponents().map((component) => (
          <Card key={component.id} onClick={() => loadComponent(component.id)}>
            <CardActionArea>
              <CardHeader
                title={component.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
});
