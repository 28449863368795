import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FinalPortion, FinalPortionFormValues, FinalPortionList, FinalPortionsOption } from "../../models/admin/finalPortion";
import { Pagination, PagingParams } from "../../models/pagination";

export default class FinalPortionStore {
    finalPortionRegistry = new Map<string, FinalPortionList>();
    finalPortionOptions = new Map<string, FinalPortionsOption>();
    itemCount: number = 0;
    selectedFinalPortion: FinalPortion | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getFinalPortions = () => {
        return Array.from(this.finalPortionRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    loadFinalPortion = async (id: string) => {
        this.loadingInitial = true;
            try {
                var finalPortion = await agent.FinalPortions.detail(id);
                this.setFinalPortion(finalPortion);
                runInAction(() => {
                    this.selectedFinalPortion = finalPortion;
                })
                this.setLoadingInitial(false);
                return finalPortion;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadFinalPortions = async () => {
        this.loadingInitial = true;
        this.finalPortionRegistry.clear();
        try {
            const result = await agent.FinalPortions.list(this.axiosParams, "");
            result.data.forEach(finalPortion => {
                this.setFinalPortion(finalPortion);
                let finalPortionOption: FinalPortionsOption = {
                    value: finalPortion.id,
                    label: finalPortion.name
                }
                this.setFinalPortionOption(finalPortionOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchFinalPortions = async (searchText: string) => {
        this.finalPortionRegistry.clear();
        try {
            const result = await agent.FinalPortions.list(this.axiosParams,searchText);
            result.data.forEach(finalPortion => {
                this.setFinalPortion(finalPortion);
                let finalPortionOption: FinalPortionsOption = {
                    value: finalPortion.id,
                    label: finalPortion.name
                }
                this.setFinalPortionOption(finalPortionOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectFinalPortion = () => {
        this.selectedFinalPortion = undefined;
    }

    private setFinalPortion = (finalPortion: FinalPortionList) => {
        this.finalPortionRegistry.set(finalPortion.id, finalPortion);
    }

    private setFinalPortionOption = (finalPortionOption: FinalPortionsOption) => {
        this.finalPortionOptions.set(finalPortionOption.value, finalPortionOption);
    }

    private getSelectedFinalPortion = () => {
        return this.selectedFinalPortion;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createFinalPortion = async (finalPortion: FinalPortionFormValues) => {
        this.loading = true;
        try {
            var finalPotionId = (await agent.FinalPortions.create(finalPortion)).data;
            runInAction(async() => {
                this.loadFinalPortion(finalPotionId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateFinalPortion = async (finalPortion: FinalPortionFormValues) => {
        this.loading = true;
        try {
            var finalPotionId = (await agent.FinalPortions.update(finalPortion)).data;
            runInAction(async() => {
                this.loadFinalPortion(finalPotionId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveFinalPortion = async (finalPotionId: string) => {
        this.loading = true;
        try {
            await agent.FinalPortions.delete(finalPotionId);
            runInAction(async() => {
                this.loadFinalPortions().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}