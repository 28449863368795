import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Repack, RepackFormValues, RepackList, RepackOption } from "../../models/admin/repack";
import { Pagination, PagingParams } from "../../models/pagination";

export default class RepackStore {
    repackRegistry = new Map<string, RepackList>();
    repackOptions = new Map<string, RepackOption>();
    itemCount: number = 0;
    selectedRepack: Repack | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getRepacks = () => {
        return Array.from(this.repackRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    loadRepack = async (id: string) => {
        this.loadingInitial = true;
            try {
                var repack = await agent.Repacks.detail(id);
                this.setRepack(repack);
                runInAction(() => {
                    this.selectedRepack = repack;
                })
                this.setLoadingInitial(false);
                return repack;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadRepacks = async () => {
        this.loadingInitial = true;
        this.repackRegistry.clear();
        try {
            const result = await agent.Repacks.list(this.axiosParams,"");
            result.data.forEach(repack => {
                this.setRepack(repack);
                let repackOption: RepackOption = {
                    value: repack.id,
                    label: repack.name
                }
                this.setRepackOption(repackOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchRepacks = async (searchText: string) => {
        this.repackRegistry.clear();
        try {
            const result = await agent.Repacks.list(this.axiosParams,searchText);
            result.data.forEach(repack => {
                this.setRepack(repack);
                let repackOption: RepackOption = {
                    value: repack.id,
                    label: repack.name
                }
                this.setRepackOption(repackOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectRepack = () => {
        this.selectedRepack = undefined;
    }

    private setRepack = (repack: RepackList) => {
        this.repackRegistry.set(repack.id, repack);
    }

    private setRepackOption = (RepackOption: RepackOption) => {
        this.repackOptions.set(RepackOption.value, RepackOption);
    }

    private getSelectedRepack = () => {
        return this.selectedRepack;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRepack = async (repack: RepackFormValues) => {
        this.loading = true;
        try {
            var repackId = (await agent.Repacks.create(repack)).data;
            runInAction(async() => {
                this.loadRepack(repackId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateRepack = async (repack: RepackFormValues) => {
        this.loading = true;
        try {
            var repackId = (await agent.Repacks.update(repack)).data;
            runInAction(async() => {
                this.loadRepack(repackId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveRepack = async (repackId: string) => {
        this.loading = true;
        try {
            await agent.Repacks.delete(repackId);
            runInAction(async() => {
                this.loadRepacks().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}