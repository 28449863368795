import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useParams } from "react-router";
import { history } from "../..";
import LoadingComponent from "../../app/layout/LoadingComponent";
import modalStore from "../../app/stores/modalStore";
import { useStore } from "../../app/stores/store";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  EndingInventory,
  EndingInventoryFilter,
  Inventory,
} from "../../app/models/Inventories/inventory";
import ConvertInventoryForm from "./ConvertInventoryForm";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
export default observer(function Inventory() {
  const history = useHistory();
  const { inventoryStore, endingInventoryStore } = useStore();
  const {
    loadInventory,
    loadingInitial,
    getInventory,
    searchInventory,
    inventoryRegistry,
  } = inventoryStore;
  const {
    endingInventoryItemsRegistry,
    setEndingInventoryItemsFiter,
    setHasSetFilter,
    setInitialDate,
    setIsView,
    loadEndingInventoryItems,
  } = endingInventoryStore;

  const [add, setAdd] = React.useState(false);
  const [searchName, setSearchName] = React.useState<string>();
  const [selectedItem, setSelectedItem] = React.useState<Inventory | null>(
    null
  );

  const handleAdd = () => {
    setAdd(true);
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setSelectedItem(null);
  };

  const handleRowButtonClick = (item: Inventory) => {
    setSelectedItem(item);
    handleAdd();
  };

  const handleSearch = () => {
    if (searchName != null) {
      searchInventory(searchName);
    } else {
      loadInventory();
    }
  };

  const ActionButtonCellRenderer = (params: GridRenderCellParams) => {
    const item = params.row as Inventory;

    return (
      <>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          sx={{
            marginRight: 0,
            backgroundColor: "orange",
            "&:hover": {
              backgroundColor: "orange",
              borderColor: "none",
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: "orange",
              borderColor: "none",
            },
          }}
          onClick={() => handleRowButtonClick(item)}
        >
          Convert
        </Button>
        <Dialog open={add}>
          <DialogTitle>Add Menu</DialogTitle>
          <DialogContent>
            {/* input fields */}
            {selectedItem && (
              <ConvertInventoryForm
                closeForm={handleCloseAdd}
                inventory={selectedItem}
              />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const columns: GridColDef[] = [
    { field: "itemName", headerName: "Item", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    { field: "department", headerName: "Department", width: 150 },
    { field: "supplierName", headerName: "Supplier", width: 175 },
    { field: "brand", headerName: "Brand", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 75 },
    { field: "unitOfMeasure", headerName: "Unit Of Measure", width: 150 },
    { field: "locationName", headerName: "Location", width: 200 },
    { field: "dateReceived", headerName: "Date Received", width: 150 },
    { field: "expiration", headerName: "Expiration Date", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ActionButtonCellRenderer,
    },
  ];

  useEffect(() => {
    inventoryRegistry.clear();
    loadInventory();
  }, [loadInventory, inventoryRegistry]);

  const handleCreateEnding = () => {
    let endInvFilter: EndingInventoryFilter = {
      id: null,
      departmentId: null,
    };
    setEndingInventoryItemsFiter(endInvFilter).then(() => {
      loadEndingInventoryItems(endInvFilter).then(() => {
        setHasSetFilter(true);
        setInitialDate(moment().format("YYYY-MM-DD"));
        setIsView(false);
        history.push("/endinginventory");
      });
    });
  };

  if (loadingInitial)
    return <LoadingComponent content="Loading Inventory..." />;

  return (
    <>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Item Name..."
            inputProps={{ "aria-label": "Search Item Name" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchName(event.target.value);
            }}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box sx={{ height: 840, width: "100%" }}>
        <DataGrid rows={getInventory()} columns={columns} />
      </Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        <Box sx={{ flexGrow: 0, padding: 1 }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            onClick={() => handleCreateEnding()}
            sx={{
              marginRight: 0,
              backgroundColor: "orange",
              "&:hover": {
                backgroundColor: "orange",
                borderColor: "none",
                boxShadow: "none",
              },
              "&:active": {
                boxShadow: "none",
                backgroundColor: "orange",
                borderColor: "none",
              },
            }}
          >
            Create Ending Inventory
          </Button>
        </Box>
      </Stack>
    </>
  );
});
