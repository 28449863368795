import React, { useState } from "react";
import axios from "axios";
import { Button, ButtonGroup, Container, Typography } from "@mui/material";
import { toast } from "react-toastify";
import ValidationErrors from "./ValidationError";

export default function TestErrors() {
  const baseUrl = "https://localhost:7018/api/";
  const [errors, setErrors] = useState(null);

  function handleNotFound() {
    axios
      .get(baseUrl + "buggy/not-found")
      .catch((err) => console.log(err.response));
  }

  function handleBadRequest() {
    axios
      .get(baseUrl + "buggy/bad-request")
      .catch((err) => console.log(err.response));
  }

  function handleServerError() {
    axios
      .get(baseUrl + "buggy/server-error")
      .catch((err) => console.log(err.response));
  }

  function handleUnauthorised() {
    axios
      .get(baseUrl + "buggy/unauthorised")
      .catch((err) => console.log(err.response));
  }

  function handleBadGuid() {
    axios.get(baseUrl + "activities/notaguid").catch((err) => console.log(err));
  }

  function handleValidationError() {
    axios.post(baseUrl + "categories", {}).catch((err) => setErrors(err));
  }

  return (
    <>
      <Typography variant="h5" component="div">
        Test Error component
      </Typography>
      <Container>
        <ButtonGroup variant="contained">
          <Button onClick={handleNotFound}>Not Found</Button>
          <Button onClick={handleBadRequest}>Bad Request</Button>
          <Button onClick={handleValidationError}>Validation Error</Button>
          <Button onClick={handleServerError}>Server Error</Button>
          <Button onClick={handleUnauthorised}>Unauthorised</Button>
          <Button onClick={handleBadGuid}>Bad Guid</Button>
        </ButtonGroup>
        {errors && <ValidationErrors errors={errors} />}
      </Container>
    </>
  );
}
