import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function UnitDetail() {
  const { unitStore } = useStore();
  const {
    selectedUnitManage
  } = unitStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedUnitManage
          ? selectedUnitManage.name
          : "Select an unit from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
      <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Typography variant="subtitle1">
              {selectedUnitManage
                ? "Base Unit Quantity: " + selectedUnitManage.quantity
                : "Base Unit Quantity: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedUnitManage
                ? "Base Unit: " + selectedUnitManage.baseUnitName
                : "Base Unit: "}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
