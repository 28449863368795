import { Button, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

interface Props{
  searchList: (searchFilter: string) => void;
}

export default observer(function Search({searchList}: Props) {
  const [search, setSearch] = React.useState("");
  const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ marginBottom: 1}}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchFieldChange}
          sx={{
            backgroundColor: "white",
            "& .MuiInputLabel-root.Mui-focused": { color: "gray" }, //styles the label
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": { borderColor: "gray" },
            },
          }}
        />
        <Button
          size="small"
          onClick={() => {searchList(search)}}
          variant="contained"
          color="primary"
          disableElevation
          sx={{
            backgroundColor: "#EFEFEF",
            "&:hover": {
              backgroundColor: "#EFEFEF",
              borderColor: "none",
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: "orange",
              borderColor: "none",
            }
          }}
        >
          <SearchIcon color="action" />
        </Button>
      </Stack>
    </>
  );
});
