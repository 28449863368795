import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { ItemList } from "../../../app/models/admin/items";
import TransferRequestForm from "./TransferRequestForm";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  multipleMode: boolean;
}

export default observer(function SelectItemsToRepack({ multipleMode }: Props) {
  const { itemStore, transferRequestStore } = useStore();
  const {
    loadingInitial,
    loadItems,
    getItems,
    loadItem,
    pagination,
    pagingParams,
    itemRegistry,
    searchItems,
  } = itemStore;
  const { selectItem } = transferRequestStore;
  const [add, setAdd] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [searchName, setSearchName] = React.useState<string>();

  const handleAdd = (selectedItem: ItemList) => {
    loadItem(selectedItem.id).then((item) => {
      selectItem(item!);
      setAdd(true);
    });
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };

  useEffect(() => {
    handlePaginationInit();
    if (itemRegistry.size <= 0) loadItems();
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems, itemRegistry]);

  const handleSearch = () => {
    if (searchName != null) {
      searchItems(searchName);
    } else {
      loadItems();
    }
  };

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    if (searchName != null) {
      searchItems(searchName);
    } else {
      loadItems();
    }
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    if (searchName != null) {
      searchItems(searchName);
    } else {
      loadItems();
    }
  };

  if (loadingInitial) return <LoadingComponent content="Loading Items..." />;

  return (
    <>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100% ",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Item Name..."
            inputProps={{ "aria-label": "Search Item Name" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchName(event.target.value);
            }}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ padding: 1 }} variant="h5">
            Select item to transfer
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
        </Stack>

        <Divider sx={{ marginBottom: 1 }} />
        <Box
          style={{
            height: "73vh",
            maxHeight: "73vh",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {getItems().map((item) => (
              <Card key={item.id} onClick={() => handleAdd(item)}>
                <CardActionArea>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Typography variant="h5">Transfer {item.name}</Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
          <Dialog open={add}>
            <DialogTitle>Fill in Amount to Transfer</DialogTitle>
            <DialogContent>
              <TransferRequestForm
                closeForm={handleCloseAdd}
                multipleMode={multipleMode}
              />
            </DialogContent>
          </Dialog>
        </Box>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handlePrevClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display: currentPage == 1 ? "none" : "block",
              }}
            >
              Previous
            </Button>
          </Box>
          <Typography sx={{ padding: 1 }} variant="h6" color="text.secondary">
            Page {currentPage} of{" "}
            {totalItems != 0 ? Math.ceil(totalItems / pageSize) : 1}
          </Typography>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handleNextClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display:
                  currentPage == Math.ceil(totalItems / pageSize)
                    ? "none"
                    : totalItems != 0
                    ? "block"
                    : "none",
              }}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Paper>
    </>
  );
});
