import { Grid, Paper } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  errors: string[] | null;
}

export default function ValidationErrors({ errors }: Props) {
  return (
    <>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="left"
          justifyContent="center"
        >
          {/* {errors!.map((err: any, i) => (
            <Grid item key={i}>{err}</Grid>
          ))} */}
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              color: "red"
            }}
            sx={{paddingLeft: 2}}
          >
            Validation Errors
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              color: "red"
            }}
          >
            <ErrorIcon sx={{paddingLeft: 3}} />
            error 1
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              color: "red"
            }}
          >
            <ErrorIcon sx={{paddingLeft: 3}} />
            error 2
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
