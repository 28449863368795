import {
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { useEffect, useState } from "react";
import IncomingStock from "./IncomingStock";
  
  export default observer(function AddIncomingStock() {
    return (
      <>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Card style={{ backgroundColor: "#EFEFEF", height: "100%" }}>
              <CardContent>
                <IncomingStock />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      </>
    );
  });
  