import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import {
  ComponentFormValues,
  ComponentsOption,
} from "../../../app/models/admin/component";
import { string } from "yup/lib/locale";
import { ItemsOption } from "../../../app/models/admin/items";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { MenusOption } from "../../../app/models/admin/menus";
import {
  FinalPortionFormValues,
  PortionedComponentFormValues,
} from "../../../app/models/admin/finalPortion";
import { sizeOptions } from "../../../app/common/options/sizeOptions";
import { FormMultiSelect } from "../../../app/common/form/FormMultiSelect";

interface Props {
  closeForm: () => void;
}

export default observer(function FinalPortionForm({ closeForm }: Props) {
  const { finalPortionStore, componentStore, itemStore, unitStore, menuStore } =
    useStore();
  const {
    selectedFinalPortion,
    createFinalPortion,
    updateFinalPortion,
    loadingInitial,
  } = finalPortionStore;
  const { getNonePaginatedComponentOptions, loadingInitial: loadComponentOptions } =
    componentStore;
  const { getNonePaginatedItemOptions, loadingInitial: loadItemOptions } = itemStore;
  const { getUnitOptions, loadingInitial: loadUnitOptions } = unitStore;
  const { getNonPaginatedMenuOptions, loadingInitial: loadMenuOptions } = menuStore;
  const [title, setTitle] = useState<string>("Create Final Portion");
  const [componentOptions, setComponentOptions] = useState<ComponentsOption[]>(
    []
  );
  const [componentToDelOptions, setComponentToDelOptions] = useState<ComponentsOption[]>(
    []
  );
  const [itemOptions, setItemOptions] = useState<ItemsOption[]>([]);
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [menuOptions, setMenuOptions] = useState<MenusOption[]>([]);
  const [finalPortionFormValues, setFinalPortionForm] =
    useState<FinalPortionFormValues>({
      id: "",
      name: "",
      menuId: "",
      size: 0,
      portionedComponentsToDelete: [],
      portionedComponents: [],
    });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    menuId: yup.string().required("Required"),
    size: yup.number().required("Required"),
  });

  useEffect(() => {
    if (selectedFinalPortion) {
      setTitle("Update Final Portion");
      let formData: FinalPortionFormValues = {
        id: selectedFinalPortion.id,
        name: selectedFinalPortion.name,
        menuId: selectedFinalPortion.menuId,
        size: selectedFinalPortion.size,
        portionedComponentsToDelete: [],
        portionedComponents: [],
      };
      setFinalPortionForm(formData);
    }

    getNonPaginatedMenuOptions().then((menuOptions) => {
      let mOptions: MenusOption[] = [];
      menuOptions!.forEach((option) => {
        let mOption: MenusOption = { value: option.value, label: option.label };
        mOptions.push(mOption);
      });
      mOptions.sort((a, b) => a.label.localeCompare(b.label));
      setMenuOptions(mOptions);
    });

    getNonePaginatedItemOptions().then((itemOptions) => {
      let iOptions: ItemsOption[] = [];
      itemOptions!.forEach((option) => {
        let iOption: ItemsOption = { value: option.value, label: option.label };
        iOptions.push(iOption);
      });
      iOptions.sort((a, b) => a.label.localeCompare(b.label));
      setItemOptions(iOptions);
    });

    getUnitOptions().then((unitOptions) => {
      let uOptions: UnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: UnitsOption = { value: option.value, label: option.label };
        uOptions.push(uOption);
      });
      uOptions.sort((a, b) => a.label.localeCompare(b.label));
      setUnitOptions(uOptions);
    });

    getNonePaginatedComponentOptions().then((componentOptions) => {
      let cOptions: ComponentsOption[] = [];
      componentOptions!.forEach((option) => {
        let cOption: ComponentsOption = {
          value: option.value,
          label: option.label,
        };
        cOptions.push(cOption);
      });
      cOptions.sort((a, b) => a.label.localeCompare(b.label));
      setComponentOptions(cOptions);

      let defaultCompOptions: ComponentsOption[] = [];
      selectedFinalPortion!.components!.forEach((component) => {
        var componentOption = cOptions.find((compOption) => {
          return compOption.value === component.componentId;
        });
        componentOption!.value = component.id;
        if (componentOption) defaultCompOptions.push(componentOption);
      });
      setComponentToDelOptions(defaultCompOptions);
    });
  }, [selectedFinalPortion, getNonPaginatedMenuOptions, getNonePaginatedComponentOptions, getNonePaginatedItemOptions, getUnitOptions]);

  function handleFormSubmit(finalPortionFormValues: FinalPortionFormValues) {
    let finalPortion: FinalPortionFormValues = {
      ...finalPortionFormValues,
    };
    if (!selectedFinalPortion) {
      createFinalPortion(finalPortion).then(() => closeForm());
    } else {
      updateFinalPortion(finalPortion).then(() => closeForm());
    }
  }

  if (
    loadingInitial &&
    loadComponentOptions &&
    loadItemOptions &&
    loadUnitOptions &&
    loadMenuOptions
  )
    return <LoadingComponent content="Loading Final Portion..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={finalPortionFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in final portion information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Final Portion Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="menuId"
                      label="Menu"
                      size="small"
                      options={menuOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="size"
                      label="Size"
                      size="small"
                      options={sizeOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  {selectedFinalPortion ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        limitTags={5}
                        id="tags-outlined"
                        options={componentToDelOptions}
                        getOptionLabel={(option: ComponentsOption) =>
                          option.label
                        }
                        defaultValue={componentToDelOptions!}
                        filterSelectedOptions
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(e, value) => {
                          value.forEach((componentOptionToDelete) => {
                            values.portionedComponentsToDelete!.push(componentOptionToDelete.value)
                          })
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Components To Remove"
                            sx={{
                              backgroundColor: "white",
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "gray",
                              }, //styles the label
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": { borderColor: "gray" },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Component Form Start */}
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                      <FormLabel component="legend">Component</FormLabel>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper style={{ backgroundColor: "#EFEFEF" }}>
                      <Box
                        style={{
                          height: "35vh",
                          maxHeight: "35vh",
                          overflow: "auto",
                        }}
                        sx={{ padding: 1 }}
                      >
                        {/* list component here */}
                        <FieldArray name="portionedComponents">
                          {({ insert, remove, push }) => (
                            <Grid item xs={12}>
                              <Stack spacing={1} sx={{ marginBottom: 1 }}>
                                {values.portionedComponents!.map(
                                  (portionedComponent, index) => (
                                    <Card key={index} sx={{ padding: 1 }}>
                                      {portionedComponent.isNew == true ? (
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.portionQuantity`}
                                              label="Quantity"
                                              size="small"
                                              type="number"
                                              component={FormTextField}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.itemId`}
                                              label="Item"
                                              size="small"
                                              options={itemOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.componentQuantity`}
                                              label="Component Quantity"
                                              size="small"
                                              type="number"
                                              component={FormTextField}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.unitId`}
                                              label="Unit"
                                              size="small"
                                              options={unitOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Stack spacing={1}>
                                            <Button
                                              variant="outlined"
                                              onClick={() => remove(index)}
                                              size="small"
                                              color="error"
                                              disabled={isSubmitting}
                                              disableElevation
                                            >
                                              remove Component
                                            </Button>
                                          </Stack>
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}

                                      {portionedComponent.isNew == false ? (
                                        <Grid item xs={12}>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.portionQuantity`}
                                              label="Quantity"
                                              size="small"
                                              type="number"
                                              component={FormTextField}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ marginBottom: 1 }}
                                          >
                                            <Field
                                              name={`portionedComponents.${index}.componentId`}
                                              label="Component"
                                              size="small"
                                              options={componentOptions}
                                              component={FormSelect}
                                            />
                                          </Grid>

                                          <Stack spacing={1}>
                                            <Button
                                              variant="outlined"
                                              onClick={() => remove(index)}
                                              size="small"
                                              color="error"
                                              disabled={isSubmitting}
                                              disableElevation
                                            >
                                              remove Component
                                            </Button>
                                          </Stack>
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                    </Card>
                                  )
                                )}
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    push({
                                      isNew: true,
                                      portionQuantity: 1,
                                      componentId: null,
                                      itemId: "",
                                      componentQuantity: 0,
                                      unitId: "",
                                    } as PortionedComponentFormValues);
                                  }}
                                  size="small"
                                  color="primary"
                                  disabled={isSubmitting}
                                  disableElevation
                                  sx={{
                                    backgroundColor: "orange",
                                    "&:hover": {
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                      boxShadow: "none",
                                    },
                                    "&:active": {
                                      boxShadow: "none",
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                    },
                                  }}
                                >
                                  Create Component
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    push({
                                      isNew: false,
                                      portionQuantity: 1,
                                      componentId: "",
                                      itemId: null,
                                      componentQuantity: null,
                                      unitId: null,
                                    } as PortionedComponentFormValues);
                                  }}
                                  size="small"
                                  color="primary"
                                  disabled={isSubmitting}
                                  disableElevation
                                  sx={{
                                    backgroundColor: "orange",
                                    "&:hover": {
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                      boxShadow: "none",
                                    },
                                    "&:active": {
                                      boxShadow: "none",
                                      backgroundColor: "orange",
                                      borderColor: "none",
                                    },
                                  }}
                                >
                                  Select Existing Component
                                </Button>
                              </Stack>
                            </Grid>
                          )}
                        </FieldArray>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
