import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CompleteTurnOverRequestFormValues, TurnOverRequest, TurnOverRequestList } from "../../models/requests/turnOverRequest";

export default class TurnOverRequestStore {
    turnOverRequestRegistry = new Map<string, TurnOverRequestList>();
    itemCount: number = 0;
    selectedTurnOverRequest: TurnOverRequest | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    getTurnOverRequests = () => {
        return Array.from(this.turnOverRequestRegistry.values());
    }

    loadTurnOverRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var turnOverRequest = await agent.TurnOverRequests.detail(id);
                runInAction(() => {
                    this.selectedTurnOverRequest = turnOverRequest;
                })
                this.setLoadingInitial(false);
                return turnOverRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadOrderRequests = async () => {
        this.loadingInitial = true;
        try {
            const turnOverRequests = await agent.TurnOverRequests.list();
            turnOverRequests.forEach(turnOverRequests => {
                this.setTurnOverRequest(turnOverRequests);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setTurnOverRequest = (turnOverRequest: TurnOverRequestList) => {
        this.turnOverRequestRegistry.set(turnOverRequest.id, turnOverRequest);
    }

    private getSelectedTurnOverRequest = () => {
        return this.selectedTurnOverRequest;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    completeTurnOverRequest = async (completeTurnOverRequest: CompleteTurnOverRequestFormValues) => {
        this.loading = true;
        try {
            await agent.TurnOverRequests.complete(completeTurnOverRequest);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}