import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Recipe } from "../../models/admin/recipe";
import { CompleteProcessRequestFormValues, ConfirmProcess, ProcessRequest, ProcessRequestList, ProcessToRequest, IngredientsToConfirm, RestockProcessRequestFormValues, TransfersToProcess, TurnOverProcessRequestFormValues, ProcessRequestFormValues } from "../../models/requests/processRequest";
import { Units } from "../../models/units";
import { Pagination, PagingParams } from "../../models/pagination";

export default class ProcessRequestStore {
    processRequestRegistry = new Map<string, ProcessRequestList>();
    selectedProcessRequest: ProcessRequest | undefined = undefined;
    selectedRecipe: Recipe | undefined = undefined;
    confirmProcess: ConfirmProcess | undefined = undefined;
    filledProcessForm: ProcessRequestFormValues | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getProcessRequests = () => {
        return Array.from(this.processRequestRegistry.values());
    }

    loadProcessRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var processRequest = await agent.ProcessRequests.detail(id);
                runInAction(() => {
                    if(!processRequest.isTurnOver) processRequest.completionDate = null;
                    this.selectedProcessRequest = processRequest;
                    this.loadingInitial = false;
                })
                return processRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadProcessRequests = async () => {
        this.loadingInitial = true;
        this.processRequestRegistry.clear();
        try {
            const result = await agent.ProcessRequests.list(this.axiosParams);
            result.data.forEach(processRequest => {
                this.setProcessRequest(processRequest);
            })
            this.loadingInitial = false;
            this.setPagination(result.pagination);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    isProcessReady = () => {
        var selectedRequestNumberString = this.selectedProcessRequest!.requestNumber.substring(10, 16);
        var selectedRequestNumber: number = +selectedRequestNumberString;

        var requestsInProgress = this.getProcessRequests().filter((x) => x.inProgress === true);

        var priorityProcessRequest = requestsInProgress.find((request) => {
            var requestNumberString = request.requestNumber.substring(10, 16);
            var requestNumber: number = +requestNumberString;

            if(request.id === this.selectedProcessRequest!.id) return false;
            
            if(requestNumber > selectedRequestNumber) return false;
            
            if(request.recipeId != this.selectedProcessRequest!.recipeId) return false;

            if(request.processingDepartment != this.selectedProcessRequest!.processingDepartment) return false;

            return true;
        });

        if(priorityProcessRequest){
            return true;
        }else{
            return false;
        }
    }

    loadProcessRequestsToConfirm = async (recipeId: string, department: number) => {
        this.loadingInitial = true;
        try {
            const processRequestsToConfirm = await agent.ProcessRequests.confirm(recipeId, department);
            runInAction(() => {
                this.confirmProcess = processRequestsToConfirm;
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    removeToProcess = (toRemove: IngredientsToConfirm) => {
        const indexOfIngredientsToConfirm = this.confirmProcess!.ingredientsToConfirm.findIndex((ingredient) => {
            return ingredient.id === toRemove.id;
        });
          
        if (indexOfIngredientsToConfirm !== -1) {
            this.confirmProcess!.ingredientsToConfirm.splice(indexOfIngredientsToConfirm, 1);
        }
    }

    removeToTransfer = (toRemove: TransfersToProcess) => {
        const indexOfTransfersToConfirm = this.confirmProcess!.transfersToProcess.findIndex((toTransfer) => {
            return toTransfer.id === toRemove.id;
        });
          
        if (indexOfTransfersToConfirm !== -1) {
            this.confirmProcess!.transfersToProcess.splice(indexOfTransfersToConfirm, 1);
        }
    }

    private setProcessRequest = (processRequest: ProcessRequestList) => {
        this.processRequestRegistry.set(processRequest.id, processRequest);
    }

    private getSelectedProcessRequest = () => {
        return this.selectedProcessRequest;
    }

    selectRecipe = (recipe: Recipe) => {
        this.selectedRecipe = recipe;
    }

    unSetProcessForm = () => {
        this.filledProcessForm = undefined;
    }

    unSelectProcessRequest = () => {
        this.selectedProcessRequest = undefined;
    }

    setProcessForm = (processForm: ProcessRequestFormValues) => {
        this.filledProcessForm = processForm;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createProcessRequest = async (confirmedProcesses: ProcessToRequest) => {
        this.loading = true;
        try {
            await agent.ProcessRequests.create(confirmedProcesses);
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    restockProcessRequest = async (restockRecipe: RestockProcessRequestFormValues) => {
        this.loading = true;
        try {
            await agent.ProcessRequests.restock(restockRecipe.ingredientToProcessId, restockRecipe);
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    completeProcessRequest = async (completeProcess: CompleteProcessRequestFormValues) => {
        this.loading = true;
        try {
            var processRequestId = (await agent.ProcessRequests.complete(completeProcess.id, completeProcess));
            runInAction(async() => {
                this.loadProcessRequest(processRequestId);
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    turnOverProcessRequest = async (turnOverProcess: TurnOverProcessRequestFormValues) => {
        this.loading = true;
        try {
            await agent.ProcessRequests.turnover(turnOverProcess.id, turnOverProcess);
            runInAction(async() => {
                this.loadProcessRequest(this.selectedProcessRequest!.id);
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}