import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import {
  ApproveProfileFormValues,
  EmployeeProfile,
  EmployeeProfileFormValues,
  EmployeeProfiles,
  FranchiseeProfile,
  FranchiseeProfileFormValues,
  FranchiseeProfiles,
} from "../models/profile";

export default class ProfileStore {
  employeeNames: Record<string, string> = {};
  employeeProfile: EmployeeProfile | null = null;
  employeeRegistry = new Map<string, EmployeeProfiles>();
  franchiseeProfile: FranchiseeProfile | null = null;
  franchiseeRegistry = new Map<string, FranchiseeProfiles>();
  editMode = false;
  loading = false;
  loadingInitial = false;
  employees: EmployeeProfiles[] = [];
  franchisees: FranchiseeProfiles[] = [];
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();

  constructor() {
    makeAutoObservable(this);
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    return params;
  }

  get isInDepartment() {
    return (department: number) => {
      if (this.employeeProfile && this.employeeProfile.roles) {
        return this.employeeProfile.roles.some(
          (role) => role.department === department
        );
      }
      return false;
    };
  }

  checkIfEmployeeRegistered = () => {
    let employeeProfile = this.employeeProfile;
    if (employeeProfile) return true;
    return false;
  };

  searchEmployees = async (searchFilter: string) => {
    this.loadingInitial = true;
    this.employeeRegistry.clear();
    try {
      const result = await agent.Profile.getEmployees(
        this.axiosParams,
        searchFilter
      );
      result.data.forEach((employee) => {
        this.setEmployee(employee);
      });
      this.setPagination(result.pagination);
      this.populateEmployees();
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  populateEmployees = () => {
    this.employees = Array.from(this.employeeRegistry.values());
  };

  loadEmployees = async () => {
    this.loadingInitial = true;
    this.employeeRegistry.clear();
    try {
      const result = await agent.Profile.getEmployees(this.axiosParams, "");
      result.data.forEach((employee) => {
        this.setEmployee(employee);
      });
      this.setPagination(result.pagination);
      this.populateEmployees();
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  private setEmployee = (employee: EmployeeProfiles) => {
    this.employeeRegistry.set(employee.id, employee);
  };

  loadEmployeeProfile = async () => {
    let employeeProfile = this.employeeProfile;
    if (employeeProfile) {
      return employeeProfile;
    } else {
      this.loadingInitial = true;
      try {
        employeeProfile = await agent.Profile.getEmployee();
        runInAction(() => {
          this.employeeProfile = employeeProfile;
        });
        this.setLoadingInitial(false);
        return employeeProfile;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadEmployeeNames = async () => {
    try {
      this.employeeNames = await agent.Account.getEmployeeNames();
    } catch (error) {
      console.log(error);
    }
  };

  getEmployeeNameByAppUserId = (appUserId: string) => {
    return this.employeeNames[appUserId];
  };

  getEmployeeProfile = async () => {
    try {
      const profile = await agent.Profile.getEmployee();
      runInAction(() => (this.employeeProfile = profile));
      return profile;
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createEmployeeProfile = async (formValues: EmployeeProfileFormValues) => {
    this.loading = true;
    try {
      await agent.Profile.createEmployee(formValues);
      runInAction(() => {
        this.getEmployeeProfile();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateEmployeeProfile = async (formValues: EmployeeProfileFormValues) => {
    this.loading = true;
    try {
      console.log(this.employeeProfile!.id);
      await agent.Profile.updateEmployee(formValues, this.employeeProfile!.id);
      runInAction(() => {
        this.getEmployeeProfile();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  approveEmployeeProfile = async (formValues: ApproveProfileFormValues) => {
    this.loading = true;
    try {
      await agent.Profile.approveEmployee(formValues, formValues.id);
      runInAction(() => {
        this.loadEmployees();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  checkIfFranchiseeRegistered = () => {
    let franchiseeProfile = this.franchiseeProfile;
    if (franchiseeProfile) return true;
    return false;
  };

  initialLoadFranchiseeProfile = async () => {
    let franchiseeProfile = this.franchiseeProfile;
    if (franchiseeProfile) {
      return franchiseeProfile;
    } else {
      this.loadingInitial = true;
      franchiseeProfile = await agent.Profile.getFranchisee();

      if (franchiseeProfile) {
        runInAction(() => {
          this.franchiseeProfile = franchiseeProfile;
        });
        this.setLoadingInitial(false);
        return franchiseeProfile;
      } else {
        return null;
      }
    }
  };

  searchFranchisees = (searchFilter: string) => {
    this.franchisees = Array.from(this.franchiseeRegistry.values()).filter(
      (x) => x.companyName.toLowerCase().includes(searchFilter.toLowerCase())
    );
  };

  populateFranchisees = () => {
    this.franchisees = Array.from(this.franchiseeRegistry.values());
  };

  loadFranchisees = async () => {
    this.loadingInitial = true;
    this.franchiseeRegistry.clear();
    try {
      const franchisees = await agent.Profile.getFranchisees();
      runInAction(() => {
        franchisees.forEach((franchisee) => {
          this.setFranchisee(franchisee);
        });
        this.populateFranchisees();
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  private setFranchisee = (franchisee: FranchiseeProfiles) => {
    this.franchiseeRegistry.set(franchisee.id, franchisee);
  };

  loadFranchiseeProfile = async () => {
    let franchiseeProfile = this.franchiseeProfile;
    if (franchiseeProfile) {
      return franchiseeProfile;
    } else {
      this.loadingInitial = true;
      try {
        franchiseeProfile = await agent.Profile.getFranchisee();
        runInAction(() => {
          this.franchiseeProfile = franchiseeProfile;
        });
        this.setLoadingInitial(false);
        return franchiseeProfile;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };

  getFranchiseeProfile = async () => {
    try {
      const profile = await agent.Profile.getFranchisee();
      runInAction(() => (this.franchiseeProfile = profile));
      return profile;
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  createFranchiseeProfile = async (formValues: FranchiseeProfileFormValues) => {
    try {
      await agent.Profile.createFranchisee(formValues);
      runInAction(() => {
        this.getFranchiseeProfile();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateFranchiseeProfile = async (formValues: FranchiseeProfileFormValues) => {
    this.loading = true;
    try {
      console.log(this.franchiseeProfile!.id);
      await agent.Profile.updateFranchisee(
        formValues,
        this.franchiseeProfile!.id
      );
      runInAction(() => {
        this.getFranchiseeProfile();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  approveFranchiseeProfile = async (formValues: ApproveProfileFormValues) => {
    this.loading = true;
    try {
      await agent.Profile.approveFranchisee(formValues, formValues.id);
      runInAction(() => {
        this.loadFranchisees();
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
