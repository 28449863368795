import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { PasswordResetRequest, PasswordResetRequestFormValues, User, UserFormValues } from "../models/user";
import { store } from "./store";

export default class UserStore {
    user: User | null = null;
    usersRegistry = new Map<string, string>();
    passwordResetRequests: PasswordResetRequest[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user;
    }

    checkIfProfileCreated = () => {
        if(this.user!.userProfile == 0){
            return false;
        } else {
            return true;
        }
    }

    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            history.push('/dashboard');
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.user = null;
        history.push('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user);
        } catch (error) {
            console.log(error);
        }
    }

    // loadUsers = async () => {
    //     try{
    //         const userlist = await agent.Account.users();
    //         userlist.forEach(user => {
    //             this.setUser(user);
    //         })
    //     }catch (error) {
    //         console.log(error);
    //     }
    // }

    get getUsers() {
        return Array.from(this.usersRegistry);
    }

    // private setUser = (user: UserList) => {
    //     this.usersRegistry.set(user.id, user.displayName);
    // }

    register = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            history.push('/dashboard');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    resetPassword = async (request: PasswordResetRequestFormValues) => {
        try {
            await agent.Account.resetPassword(request);
            history.push('/');
            // Show success message
        } catch (error) {
            throw error;
        }
    };

    loadPasswordResetRequests = async () => {
        try {
            const requests = await agent.Account.getPasswordResetRequests();
            runInAction(() => {
                this.passwordResetRequests = requests;
            });
        } catch (error) {
            console.log(error);
        }
    };

    approvePasswordResetRequest = async (id: string) => {
        try {
            await agent.Account.approvePasswordResetRequest(id);
            runInAction(() => {
                this.passwordResetRequests = this.passwordResetRequests.map(request =>
                    request.id === id ? { ...request, isApproved: true } : request
                );
            });
        } catch (error) {
            console.log(error);
        }
    };
}