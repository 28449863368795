import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { observer } from "mobx-react-lite";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { useStore } from "../../../app/stores/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  ConfirmRepack,
  RepacksToRequest,
  RepackToConfirm,
  TransfersToRepack,
} from "../../../app/models/requests/repackRequest";
import { history } from "../../..";
import { TransferRequestFormValues } from "../../../app/models/requests/transferRequest";

export default observer(function ConfirmRepackRequest() {
  const { repackRequestStore, transferRequestStore } = useStore();
  const {
    unSelectRepackAndEmptyToRepackList,
    confirmRepack,
    loading,
    removeToRepack,
    removeToTransfer,
    createRepackRequest,
  } = repackRequestStore;
  const { createMultipleTransferRequest, loading: loadingTransfer } =
    transferRequestStore;
  const [repackToConfirm, setRepackToConfirm] = useState<ConfirmRepack>({
    itemToRepack: "",
    itemToRepackId: "",
    quantityToRepack: 0,
    unitToRepack: "",
    repackingDepartment: 1,
    repacksToConfirm: [],
    transfersToRepack: [],
  });

  const handleReturnToList = () => {
    unSelectRepackAndEmptyToRepackList();
  };

  const GridHead = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#505050",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#ffffff",
  }));

  const GridBody = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#e5e5e5",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleArchive = () => {};

  const handleConfirmAll = () => {
    let repacksToRequest: RepacksToRequest = {
      repackRequestsToAdd: repackToConfirm.repacksToConfirm,
    };

    if (repackToConfirm.transfersToRepack.length > 0) {
      let transferRequests: TransferRequestFormValues[] = [];

      repackToConfirm.transfersToRepack.forEach((toTransfer) => {
        let transferRequestForm: TransferRequestFormValues = {
          itemId: toTransfer.itemId,
          inventoryToTransferQuantity: toTransfer.quantity,
          unitId: toTransfer.unitId,
          remarks: "Transfer request for repack completion",
          providingDepartment: toTransfer.providingDepartment,
          receivingDepartment: toTransfer.receivingDepartment,
        };

        transferRequests.push(transferRequestForm);
      });

      createRepackRequest(repacksToRequest).then(() => {
        createMultipleTransferRequest(transferRequests).then(() => {
          unSelectRepackAndEmptyToRepackList();
          history.push(`/history/repack`);
        });
      });
    } else {
      createRepackRequest(repacksToRequest).then(() => {
        unSelectRepackAndEmptyToRepackList();
        history.push(`/history/repack`);
      });
    }
  };

  const handleRemoveRepack = (repackToRemove: RepackToConfirm) => {
    removeToRepack(repackToRemove);
  };

  const handleRemoveTransfer = (transferToRemove: TransfersToRepack) => {
    removeToTransfer(transferToRemove);
  };

  useEffect(() => {
    setRepackToConfirm(confirmRepack!);
  }, [setRepackToConfirm, confirmRepack]);

  if (loading || loadingTransfer)
    return <LoadingComponent content="Loading Repack Request..." />;

  return (
    <>
      <Stack spacing={2}>
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Confirm inventories for repack
            </Typography>
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box sx={{ paddingBottom: 2 }}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {repackToConfirm
                      ? "Item to Repack: " + repackToConfirm!.itemToRepack
                      : "Item to Repack: "}
                  </Typography>
                  <Typography variant="h6">
                    {repackToConfirm
                      ? "Quantity: " +
                        repackToConfirm!.quantityToRepack +
                        " " +
                        repackToConfirm!.unitToRepack
                      : "Quantity: "}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {repackToConfirm
                      ? "Repacking Department: " +
                        departmentOptions[repackToConfirm!.repackingDepartment]
                          .label
                      : "Repacking Department: "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleReturnToList}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Return to list
            </Button>

            <Button
              startIcon={<SendIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              disabled={repackToConfirm!.repacksToConfirm.length == 0}
              onClick={handleConfirmAll}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Confirm All
            </Button>
          </Stack>
        </Paper>

        {/* inventories to Repack */}
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Inventories to Repack
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box
            style={{
              height: "52vh",
              maxHeight: "52vh",
              overflow: "auto",
            }}
          >
            <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
              {repackToConfirm!.repacksToConfirm.map((toRepack) => (
                <Card key={toRepack.id}>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Stack spacing={5} direction="row">
                      <Typography variant="h6">
                        Item: {toRepack.item}
                      </Typography>
                      <Typography variant="h6">
                        Storage: {toRepack.location}
                      </Typography>
                      {toRepack.expirationDate ? (
                        <Typography variant="h6">
                          Expiration Date:{" "}
                          {format(
                            new Date(toRepack.expirationDate),
                            "dd MMM yyyy"
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="h6">Expiration Date:</Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 3 }}
                    >
                      {/* inventory to repack */}
                      <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10}>
                          <GridHead>To Repack</GridHead>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Quantity</Typography>
                              <Typography>
                                {toRepack.quantityToRepack}{" "}
                                {toRepack.unitToRepack}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Alt Quantity</Typography>
                              <Typography>
                                {toRepack.altQuantity > 1000
                                  ? toRepack.altQuantity / 1000
                                  : toRepack.altQuantity}{" "}
                                {toRepack.altUnit}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>

                      {/* expected output */}
                      <Grid container spacing={1}>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={8}>
                          <GridHead>Expected Output</GridHead>
                        </Grid>

                        <Grid item xs={2}></Grid>

                        <Grid item xs={2}></Grid>

                        <Grid item xs={8}>
                          <GridBody>
                            <Stack>
                              <Typography>Quantity</Typography>
                              <Typography>
                                {Math.round(
                                  (toRepack.expectedQuantity + Number.EPSILON) *
                                    100
                                ) / 100}{" "}
                                {toRepack.expectedUnit}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>

                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Divider />
                  <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                    />
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      onClick={() => handleRemoveRepack(toRepack)}
                      sx={{
                        backgroundColor: "red",
                        "&:hover": {
                          backgroundColor: "red",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "red",
                          borderColor: "none",
                        },
                      }}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Box>
        </Paper>

        {/* transfer to repack */}
        {confirmRepack!.transfersToRepack.length > 0 ? (
          <Paper style={{ backgroundColor: "#EFEFEF" }}>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ padding: 1 }} variant="h5">
                Inventories to Transfer for Repack
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            </Stack>

            <Divider sx={{ marginBottom: 1 }} />

            <Box
              style={{
                height: "25vh",
                maxHeight: "25vh",
                overflow: "auto",
              }}
            >
              <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                {repackToConfirm!.transfersToRepack.map((toTransfer) => (
                  <Card key={toTransfer.id}>
                    <Box sx={{ flexGrow: 0, padding: 2 }}>
                      <Typography variant="h6">
                        {toTransfer.quantity} {toTransfer.unit} of{" "}
                        {toTransfer.inventoryItem} from:{" "}
                        {
                          departmentOptions[toTransfer.providingDepartment]
                            .label
                        }{" "}
                        Department
                      </Typography>
                    </Box>
                    <Divider />
                    <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: { xs: "flex", md: "flex" },
                        }}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        onClick={() => handleRemoveTransfer(toTransfer)}
                        sx={{
                          backgroundColor: "red",
                          "&:hover": {
                            backgroundColor: "red",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "red",
                            borderColor: "none",
                          },
                        }}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Paper>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
});
