import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import BranchStore from "./admin/branchStore";
import CategoryStore from "./admin/categoryStore";
import ClassificationStore from "./admin/classificationStore";
import ComponentStore from "./admin/componentStore";
import FinalPortionStore from "./admin/finalPortionStore";
import ItemStore from "./admin/itemStore";
import locationStore from "./admin/locationStore";
import MenuStore from "./admin/menuStore";
import SupplierStore from "./admin/supplierStore";
import FranchiseeOrderRequestStore from "./requests/franchiseeOrderRequestStore";
import OrderRequestStore from "./requests/orderRequestStore";
import RequestReleaseStore from "./requests/requestReleaseStore";
import TurnOverRequestStore from "./requests/turnOverRequestStore";
import LocationStore from "./admin/locationStore";
import UnitStore from "./unitStore";
import InventoryStore from "./inventories/inventoryStore";
import EndingInventoryStore from "./inventories/endingInventoryStore";
import DiscrepancyReportStore from "./inventories/discrepancyReportStore";
import IncomingStockStore from "./inventories/incomingStockStore";
import RepackStore from "./admin/repackStore";
import IngredientStore from "./admin/ingredientStore";
import RecipeStore from "./admin/recipeStore";
import RepackRequestStore from "./requests/repackRequestStore";
import TransferRequestStore from "./requests/transferRequestStore";
import RoleStore from "./admin/roleStore";
import PermissionStore from "./admin/permissionStore";
import ProcessRequestStore from "./requests/processRequestStore";
import AdminOrderRequestStore from "./requests/adminOrderRequestStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    profileStore: ProfileStore;
    unitStore: UnitStore;
    inventoryStore: InventoryStore;
    endingInventoryStore: EndingInventoryStore;
    discrepancyReportStore: DiscrepancyReportStore;
    incomingStockStore: IncomingStockStore;
    branchStore: BranchStore;
    categoryStore: CategoryStore;
    classificationStore: ClassificationStore;
    componentStore: ComponentStore;
    finalPortionStore: FinalPortionStore;
    ingredientStore: IngredientStore;
    itemStore: ItemStore;
    locationStore: locationStore;
    menuStore: MenuStore;
    permissionStore: PermissionStore;
    recipeStore: RecipeStore;
    repackStore: RepackStore;
    roleStore: RoleStore;
    supplierStore: SupplierStore;
    franchiseeOrderRequestStore: FranchiseeOrderRequestStore;
    orderRequestStore: OrderRequestStore;
    requestReleasestore: RequestReleaseStore;
    turnOverRequestStore: TurnOverRequestStore;
    repackRequestStore: RepackRequestStore;
    processRequestStore: ProcessRequestStore
    transferRequestStore: TransferRequestStore;
    adminOrderRequestStore: AdminOrderRequestStore;
}

export const store: Store = {
    
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    userStore: new UserStore(),
    profileStore: new ProfileStore(),
    unitStore: new UnitStore(),
    inventoryStore: new InventoryStore(),
    endingInventoryStore: new EndingInventoryStore(),
    discrepancyReportStore: new DiscrepancyReportStore(),
    incomingStockStore: new IncomingStockStore(),
    branchStore: new BranchStore(),
    categoryStore: new CategoryStore(),
    classificationStore: new ClassificationStore(),
    componentStore: new ComponentStore(),
    finalPortionStore: new FinalPortionStore(),
    ingredientStore: new IngredientStore(),
    itemStore: new ItemStore(),
    locationStore: new LocationStore(),
    menuStore: new MenuStore(),
    permissionStore: new PermissionStore(),
    recipeStore: new RecipeStore(),
    repackStore: new RepackStore(),
    roleStore: new RoleStore(),
    supplierStore: new SupplierStore(),
    franchiseeOrderRequestStore: new FranchiseeOrderRequestStore(),
    orderRequestStore: new OrderRequestStore(),
    requestReleasestore: new RequestReleaseStore(),
    turnOverRequestStore: new TurnOverRequestStore(),
    repackRequestStore: new RepackRequestStore(),
    processRequestStore: new ProcessRequestStore(),
    transferRequestStore: new TransferRequestStore(),
    adminOrderRequestStore: new AdminOrderRequestStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}