import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { useStore } from "../../../app/stores/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArchiveIcon from "@mui/icons-material/Archive";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Inventory } from "../../../app/models/Inventories/inventory";
import RadarIcon from "@mui/icons-material/Radar";
import {
  RepackRequest,
  RepackRequestList,
  RestockRepackRequestFormValues,
} from "../../../app/models/requests/repackRequest";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CheckIcon from "@mui/icons-material/Check";
import CompleteRepackForm from "./CompleteRepackForm";
import TurnOverRepackForm from "./TurnOverRepackForm";
import { history } from "../../..";
export default observer(function RepackRequestDetail() {
  const { repackRequestStore, inventoryStore } = useStore();
  const {
    loadInventory,
    findInventoryForRequest,
    loadingInitial,
    setLoadingInitial,
  } = inventoryStore;
  const {
    selectedRepackRequest,
    unSelectRepackRequest,
    restockRepackRequest,
    loading,
    isRepackReady,
    archiveBranch
  } = repackRequestStore;
  const [complete, setComplete] = useState(false);
  const [turnOver, setTurnOver] = useState(false);
  const [isRepackRequestReady, setIsReady] = useState(false);
  const [repackRequest, setRepackRequest] = useState<RepackRequest>({
    id: "",
    requestNumber: "",
    inProgress: true,
    isComplete: false,
    completionDate: null,
    createdDate: null,
    requestorName: "",
    repackingDepartment: 1,
    itemId: "",
    inventoryId: "",
    inventory: "",
    inventoryAmount: 0,
    inventoryToRepackQuantity: 0,
    inventoryToRepackUnitId: "",
    inventoryToRepackUnit: "",
    repackId: "",
    repack: "",
    componentId: "",
    component: "",
    expectedQuantity: 0,
    expectedUnitId: "",
    expectedUnit: "",
    altQuantity: 0,
    altUnitId: "",
    altUnit: "",
    discrepancyQuantity: 0,
    reason: "",
    actualQuantity: 0,
    locationId: "",
    location: "",
    isTurnOver: false,
    turnOverPersonnelId: "",
    turnOverPersonnel: "",
    turnOverDate: null,
  });

  const handleReturnToList = () => {
    unSelectRepackRequest();
  };

  const handleArchive = () => {
    if(selectedRepackRequest!=null){
      archiveBranch(selectedRepackRequest?.id);
      unSelectRepackRequest();
      history.push(`/history/repack`);
    }
  };

  const handleRepack = () => {
    setComplete(true);
  };

  const handleTurnOver = () => {
    setTurnOver(true);
  };

  const handleRestockCheck = () => {
    loadInventory().then(() => {
      let inventory = findInventoryForRequest(
        selectedRepackRequest!.itemId,
        selectedRepackRequest!.inventoryToRepackQuantity,
        selectedRepackRequest!.inventoryToRepackUnitId,
        selectedRepackRequest!.repackingDepartment
      );

      if (inventory.length > 0) {
        let restockedRequest: RepackRequest = {
          ...selectedRepackRequest!,
        };
        restockedRequest.inventoryId = inventory[0].id;
        setRepackRequest(restockedRequest);
        let restockRepack: RestockRepackRequestFormValues = {
          inventoryId: restockedRequest.inventoryId,
          repackRequestId: restockedRequest.id,
        };
        console.log(restockRepack);
        restockRepackRequest(restockRepack);
      }
    });
  };

  const handleCloseComplete = () => {
    setComplete(false);
  };

  const handleCloseTurnOver = () => {
    setTurnOver(false);
  };

  const GridHead = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#505050",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#ffffff",
  }));

  const GridBody = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#e5e5e5",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    setIsReady(isRepackReady());
    setRepackRequest(selectedRepackRequest!);
    setLoadingInitial(false);
  }, [selectedRepackRequest, setLoadingInitial]);

  if (loadingInitial || loading)
    return <LoadingComponent content="Loading Repack Requests..." />;

  return (
    <>
      <Stack spacing={2}>
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ padding: 1 }} variant="h5">
              {repackRequest
                ? repackRequest.requestNumber
                : "No Repack Request Selected"}
            </Typography>
            <Stack
              sx={{ paddingRight: 2, paddingTop: 1.5 }}
              direction="row"
              spacing={2}
            >
              {repackRequest! ? (
                repackRequest.isTurnOver ? (
                  <Chip
                    size="small"
                    icon={<CheckCircleIcon />}
                    color="success"
                    label="Repacked"
                  />
                ) : (
                  <Chip
                    size="small"
                    icon={<PendingActionsIcon />}
                    color="warning"
                    label="In Progress"
                  />
                )
              ) : (
                <Chip
                  size="small"
                  icon={<PendingActionsIcon />}
                  color="default"
                  label="In Progress"
                />
              )}
              {repackRequest! ? (
                repackRequest.inventoryId ? (
                  <></>
                ) : (
                  <Chip
                    size="small"
                    icon={<PendingActionsIcon />}
                    color="warning"
                    label="Awaiting Restock"
                  />
                )
              ) : (
                <Chip
                  size="small"
                  icon={<PendingActionsIcon />}
                  color="default"
                  label="In Progress"
                />
              )}
            </Stack>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />
          <Box sx={{ paddingBottom: 2 }}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {repackRequest
                      ? "Item to Repack: " + repackRequest.inventory
                      : "Item to Repack: "}
                  </Typography>

                  <Typography variant="h6">
                    {repackRequest
                      ? "Quantity: " +
                        repackRequest.inventoryToRepackQuantity +
                        " " +
                        repackRequest.inventoryToRepackUnit
                      : "Quantity: "}
                  </Typography>

                  <Typography variant="h6">
                    {repackRequest
                      ? "Request Date: " +
                        format(
                          new Date(repackRequest.createdDate!),
                          "dd MMM yyyy"
                        )
                      : "Request Date: "}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {repackRequest
                      ? "Repacking Department: " +
                        departmentOptions[repackRequest.repackingDepartment]
                          .label
                      : "Repacking Department: "}
                  </Typography>

                  <Typography variant="h6">
                    {repackRequest
                      ? repackRequest.location
                        ? "Receiving Storage: " + repackRequest.location
                        : "Receiving Storage: Not yet specified"
                      : "Receiving Storage: "}
                  </Typography>

                  <Typography variant="h6">
                    {repackRequest!.completionDate
                      ? "Repack Date: " +
                        format(
                          new Date(repackRequest!.completionDate),
                          "dd MMM yyyy"
                        )
                      : "Repack Date: Still in progress"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleReturnToList}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Return to list
            </Button>

            {repackRequest.inventoryId ? (
              <></>
            ) : (
              <Button
                startIcon={<RadarIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled={isRepackRequestReady}
                onClick={handleRestockCheck}
                sx={{
                  backgroundColor: "orange",
                  "&:hover": {
                    backgroundColor: "orange",
                    borderColor: "none",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "orange",
                    borderColor: "none",
                  },
                }}
              >
                Check restock
              </Button>
            )}

            <Button
              startIcon={<ArchiveIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleArchive}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Archive
            </Button>

            {repackRequest ? (
              repackRequest.isTurnOver ? (
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled
                >
                  Repack
                </Button>
              ) : repackRequest.isComplete ? (
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={handleTurnOver}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Turn Over
                </Button>
              ) : (
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled={isRepackRequestReady}
                  onClick={handleRepack}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Repack
                </Button>
              )
            ) : (
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled
              >
                Repack
              </Button>
            )}
          </Stack>
          <Dialog open={complete}>
            <DialogTitle>Confirm Repack of Inventory</DialogTitle>
            <DialogContent>
              {/* input fields */}
              <CompleteRepackForm closeForm={handleCloseComplete} />
            </DialogContent>
          </Dialog>
          <Dialog open={turnOver}>
            <DialogTitle>Select Repack Storage</DialogTitle>
            <DialogContent>
              {/* input fields */}
              <TurnOverRepackForm closeForm={handleCloseTurnOver} />
            </DialogContent>
          </Dialog>
        </Paper>

        {/* repack details */}
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Inventory to Repack
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box sx={{ flexGrow: 0, padding: 2 }}>
            <Stack direction="row" sx={{ paddingLeft: 1, paddingRight: 1 }}>
              {/* inventory to repack */}
              <Grid container spacing={1}>
                <Grid item xs={1}></Grid>

                <Grid item xs={10}>
                  <GridHead>To Repack</GridHead>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={5}>
                  <GridBody>
                    <Stack>
                      <Typography>Quantity</Typography>
                      <Typography>
                        {repackRequest!.inventoryToRepackQuantity}{" "}
                        {repackRequest!.inventoryToRepackUnit}
                      </Typography>
                    </Stack>
                  </GridBody>
                </Grid>
                <Grid item xs={5}>
                  <GridBody>
                    <Stack>
                      <Typography>Alt Quantity</Typography>
                      <Typography>
                        {repackRequest!.altQuantity > 1000
                          ? repackRequest!.altQuantity / 1000
                          : repackRequest!.altQuantity}{" "}
                        {repackRequest!.altUnit}
                      </Typography>
                    </Stack>
                  </GridBody>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>

              {/* expected output */}
              <Grid container spacing={1}>
                <Grid item xs={2}></Grid>

                <Grid item xs={8}>
                  <GridHead>Expected Output</GridHead>
                </Grid>

                <Grid item xs={2}></Grid>

                <Grid item xs={2}></Grid>

                <Grid item xs={8}>
                  <GridBody>
                    <Stack>
                      <Typography>Quantity</Typography>
                      <Typography>
                        {Math.round(
                          (repackRequest!.expectedQuantity + Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        {repackRequest!.expectedUnit}
                      </Typography>
                    </Stack>
                  </GridBody>
                </Grid>

                <Grid item xs={2}></Grid>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Stack>
    </>
  );
});
