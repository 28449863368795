import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { sizeOptions } from "../../../app/common/options/sizeOptions";
import { useStore } from "../../../app/stores/store";

export default observer(function IngredientDetail() {
  const { ingredientStore } = useStore();
  const { selectedIngredient } = ingredientStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedIngredient
          ? selectedIngredient.name
          : "Select a Ingredient from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Typography variant="subtitle1">
              {selectedIngredient
                ? "Item: " + selectedIngredient.item
                : "Item: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedIngredient
                ? "Quantity: " + selectedIngredient.quantity
                : "Quantity: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedIngredient
                ? "Unit: " + selectedIngredient.unit
                : "Unit: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedIngredient
                ? "Yield: " + selectedIngredient.yield
                : "Yield: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
