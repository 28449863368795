import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { DiscrepancyReport, DiscrepancyReportDto,DiscrepancyReportExcel, DiscrepancyReportListFilter,DiscrepancyReportItemsDto, DiscrepancyReportItems, DiscrepancyReportFilter , AddDiscrepancyReport} from "../../models/Inventories/inventory";

export default class DiscrepancyReportStore {
    discrepancyReportRegistry = new Map<string, DiscrepancyReport>();
    discrepancyReportFilter: DiscrepancyReportFilter | undefined = undefined;
    discrepancyReportItemRegistry = new Map<string, DiscrepancyReportItems>();
    hasSetFilter = false;
    loadingInitial = true;
    loading = false;
    InitialDate="";
    InitialId="";

    constructor() {
        makeAutoObservable(this);
    }

    setInitialDateDiscrep = (initDate: string) => {
        this.InitialDate = initDate;
    }

    getInitialDateDiscrep = () => {
        return this.InitialDate;
    }

    
    setInitialIdDiscrep = (initId: string) => {
        this.InitialId = initId;
    }

    getInitialIdDiscrep = () => {
        return this.InitialId;
    }

    setDiscrepancyReportFilter = (filter: DiscrepancyReportFilter) => {
        this.discrepancyReportFilter = filter;
    }

    getDiscrepancyReportFilter = () => {
        return this.discrepancyReportFilter;
    }

    setHasSetFilterDiscrep = (filter: boolean) => {
        this.hasSetFilter = filter;
    }

    getHasSetFilterDiscrep = () => {
        return this.hasSetFilter;
    }

    getDiscrepancyReport = () => {
        return Array.from(this.discrepancyReportRegistry.values());
    }

    loadDiscrepancyReport= async () => {
        this.discrepancyReportRegistry.clear();
        this.loadingInitial = true;
        const user = await agent.Account.current();
        let disInvFilter: DiscrepancyReportListFilter = {
            departmentId:user.department
          };
        try {
            const discrepancy = await agent.DiscrepancyReports.list(disInvFilter);
            discrepancy.forEach(item => {
                this.setDiscrepancyReport(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getDiscrepancyReportItems = () => {
        return Array.from(this.discrepancyReportItemRegistry.values());
    }

    loadDiscrepancyReportItems = async (discrepancyReportItemFilter: DiscrepancyReportFilter) => {
        this.discrepancyReportItemRegistry.clear();
        this.loadingInitial = true;
        try {
            const discrepancy = await agent.DiscrepancyReports.listitems(discrepancyReportItemFilter);
            discrepancy.forEach(item => {
                this.setDiscrepancyReportItem(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    generateExcelReport = async (discrepancyReportExcel: DiscrepancyReportExcel) => {
        try {
            const inventory = await agent.DiscrepancyReports.generateExcelReport(discrepancyReportExcel);
            const outputFilename = `DiscrepancyReport-${Date.now()}.xlsx`;
            const url = URL.createObjectURL(new Blob([inventory]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    private setDiscrepancyReport = (discrepancyReportDto: DiscrepancyReportDto) => {
        let discrepancy: DiscrepancyReport = {
            id:discrepancyReportDto.id,
            name: discrepancyReportDto.name,
            discrepancyReportDate: discrepancyReportDto.discrepancyReportDate,
            createdBy: discrepancyReportDto.createdBy,
        };
        this.discrepancyReportRegistry.set(discrepancy.id, discrepancy);
    }

    private setDiscrepancyReportItem = (discrepancyReportItemDto: DiscrepancyReportItemsDto) => {
        let discrepancyItem: DiscrepancyReportItems = {
            id:discrepancyReportItemDto.id,
            inventoryItemId:discrepancyReportItemDto.inventoryItemId!=null ? discrepancyReportItemDto?.inventoryItemId: "",
            itemName: discrepancyReportItemDto.itemName,
            unitOfMeasure: discrepancyReportItemDto.unitOfMeasure,
            expectedEndingQuantity: discrepancyReportItemDto.expectedEndingQuantity,
            endingQuantity: discrepancyReportItemDto.endingQuantity,
            reason: discrepancyReportItemDto.reason,
        };
        this.discrepancyReportItemRegistry.set(discrepancyItem.id, discrepancyItem);
    }

    createDiscrepancyReport = async (addDiscrepancyReport: AddDiscrepancyReport) => {
        const user = await agent.Account.current();
        addDiscrepancyReport.departmentName=user.departmentName;
        addDiscrepancyReport.departmentId=user.department;
        this.loading = true;
        try {
            await agent.DiscrepancyReports.create(addDiscrepancyReport);
            runInAction(() => {
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }
}