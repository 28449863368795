import {
    Avatar,
    Badge,
    Button,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import * as yup from "yup";
  import { Field, Form, Formik } from "formik";
  import { FormTextField } from "../../app/common/form/FormTextField";
  import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
  import { useStore } from "../../app/stores/store";
  import { useEffect, useState } from "react";
  import { FranchiseeProfileFormValues } from "../../app/models/profile";
  import { history } from "../..";
  import LoadingComponent from "../../app/layout/LoadingComponent";
import { FormSelect } from "../../app/common/form/FormSelect";
import { BranchOption } from "../../app/models/admin/branch";
  
  export default observer(function FranchiseeProfile() {
    const { profileStore, commonStore, branchStore } = useStore();
    const {
      checkIfFranchiseeRegistered,
      loadFranchiseeProfile,
      initialLoadFranchiseeProfile,
      createFranchiseeProfile,
      updateFranchiseeProfile,
      loading,
      loadingInitial,
    } = profileStore;
    const { getBranchOptions } = branchStore;
    const { uploadPhoto } = commonStore;
    const [title, setTitle] = useState<string>("Create Franchisee Profile");
    const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
    const [baseImage, setBaseImage] = useState("");
    const [options, setBranchOptions] = useState<BranchOption[]>([]);
    const [franchiseeProfileFormValues, setFranchiseeForm] =
      useState<FranchiseeProfileFormValues>({
        id: "",
        companyName: "",
        locationId: "",
        address: "",
        profilePhoto: "",
      });
  
    const validationSchema = yup.object().shape({
      companyName: yup.string().required("Required"),
      locationId: yup.string().required("Required"),
      address: yup.string().required("Required")
    });
  
    useEffect(() => {
      //improvement to do: check userProfile value to see if user has a profile already
      loadFranchiseeProfile().then((values) => {
        if (checkIfFranchiseeRegistered()) {
          setTitle("Update Franchisee Profile");
          setBaseImage(values!.profilePhoto);
          let formData: FranchiseeProfileFormValues = {
            id: values!.id,
            companyName: values!.companyName,
            locationId: values!.branchId,
            address: values!.address,
            profilePhoto: values!.profilePhoto,
          };
          setFranchiseeForm(formData);
        }
      });
      getBranchOptions().then((branchOptions) => {
        let bOptions: BranchOption[] = [];
        branchOptions!.forEach((option) => {
          let bOption: BranchOption = {value: option.value, label: option.label}
          bOptions.push(bOption);
        })
        setBranchOptions(bOptions);
      });
    }, [checkIfFranchiseeRegistered, loadFranchiseeProfile, getBranchOptions]);
  
    const handleUploadPhoto = async (e: any) => {
      setBaseImage(await uploadPhoto(e));
      setIsPhotoUploaded(true);
    }
  
    function handleFormSubmit(
      franchiseeProfileFormValues: FranchiseeProfileFormValues
    ) {
      let profile: FranchiseeProfileFormValues = {
        ...franchiseeProfileFormValues,
        profilePhoto: baseImage
      };
      if (!checkIfFranchiseeRegistered()) {
        console.log(profile);
        createFranchiseeProfile(profile).then(() =>
          history.push(`/dashboard`)
        );
      } else {
        updateFranchiseeProfile(profile).then(() => {
          history.push(`/dashboard`);
        });
      }
    }
  
    if (loadingInitial) return <LoadingComponent content="Loading Profile..." />;
  
    return (
      <>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={2}></Grid>
          <Grid item xs>
            <Paper style={{ backgroundColor: "#EFEFEF", height: "100%" }}>
              <Grid container spacing={0}>
                <Formik
                  initialValues={franchiseeProfileFormValues}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={(values) => handleFormSubmit(values)}
                >
                  {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form noValidate autoComplete="off">
                      {/* header start */}
                      <Grid item sx={{ padding: 2 }}>
                        <Typography variant="h5" component="div">
                          {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Please fill in additional information to complete your
                          profile
                        </Typography>
                      </Grid>
                      {/* header end */}
  
                      {/* Form */}
                      <Grid
                        item
                        container
                        direction="row"
                        spacing={2}
                        sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
                      >
                        {/* input fields start */}
                        <Grid item container spacing={2} xs={12} md={8}>
                          <Grid item xs={12}>
                            <Field
                              name="companyName"
                              label="Company Name"
                              size="small"
                              component={FormTextField}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="locationId"
                              label="Location"
                              size="small"
                              options={options}
                              component={FormSelect}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="address"
                              label="Address"
                              size="small"
                              multiline
                              rows={3}
                              component={FormTextField}
                            />
                          </Grid>
                        </Grid>
                        {/* input fields end */}
  
                        {/* profile pic start */}
                        <Grid item xs={12} md={4}>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Stack spacing={2}>
                              <Avatar
                                src={baseImage}
                                sx={{ width: 150, height: 150 }}
                              />
                              <Button
                                component="label"
                                startIcon={<AddAPhotoIcon />}
                                variant="contained"
                                size="small"
                                color="primary"
                                disableElevation
                                sx={{
                                  backgroundColor: "black",
                                  "&:hover": {
                                    backgroundColor: "black",
                                    borderColor: "none",
                                    boxShadow: "none",
                                  },
                                  "&:active": {
                                    boxShadow: "none",
                                    backgroundColor: "black",
                                    borderColor: "none",
                                  },
                                }}
                              >
                                Upload Photo
                                <input
                                  name="profilePhoto"
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  hidden
                                  onChange={(e) => {
                                    handleUploadPhoto(e);
                                  }}
                                />
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                        {/* profile pic end */}
                      </Grid>
                      {/* form end */}
  
                      {/* button start */}
                      <Grid item sx={{ padding: 2 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={isSubmitting || !isValid}
                          disableElevation
                          sx={{
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "black",
                              borderColor: "none",
                            },
                          }}
                        >
                          Submit Profile
                        </Button>
                      </Grid>
                      {/* button end */}
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </>
    );
  });
  