import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Permission, PermissionOption } from "../../models/admin/role";

export default class PermissionStore {
    permissionRegistry = new Map<string, Permission>();
    myPermissionRegistry = new Map<string, Permission>();
    permissionOptions = new Map<string, PermissionOption>();
    itemCount: number = 0;
    selectedPermission: Permission | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    getPermissions = () => {
        return Array.from(this.permissionRegistry.values());
    }

    getPermissionOptions = async () => {
        this.loadingInitial = true;
        let permissionOptions = this.permissionOptions;
        try {
            const permissions = await agent.Permissions.list();
            permissions.forEach(permission => {
                let permissionOption: PermissionOption = {
                    value: permission.id,
                    label: permission.module + " " + permission.operation
                }
                this.permissionOptions.set(permission.id, permissionOption);
            });
            this.setLoadingInitial(false);
            return permissionOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadPermissions = async () => {
        this.loadingInitial = true;
        try {
            const permissions = await agent.Permissions.list();
            permissions.forEach(Permission => {
                this.setPermission(Permission);
                let permissionOption: PermissionOption = {
                    value: Permission.id,
                    label: Permission.module + " " + Permission.operation
                }
                this.setPermissionOption(permissionOption);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadUserPermissions = async () => {
        this.loadingInitial = true;
        try {
            const permissions = await agent.Permissions.myPermissions();
            runInAction(() => {
                permissions.forEach(Permission => {
                    this.setMyPermission(Permission);
                })
                this.setLoadingInitial(false);
            });
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    selectPermission = (id: string) => {
        this.selectedPermission = this.permissionRegistry.get(id);
    }

    unSelectPermission = () => {
        this.selectedPermission = undefined;
    }

    private setPermission = (Permission: Permission) => {
        this.permissionRegistry.set(Permission.id, Permission);
    }

    private setMyPermission = (Permission: Permission) => {
        this.myPermissionRegistry.set(Permission.id, Permission);
    }

    private setPermissionOption = (permissionOption: PermissionOption) => {
        this.permissionOptions.set(permissionOption.value, permissionOption);
    }

    private getPermission = (id: string) => {
        return this.permissionRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    
}