import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useStore } from "../../app/stores/store";
import { UnitsOption } from "../../app/models/units";
import { ConvertInventoryFormValues, Inventory } from "../../app/models/Inventories/inventory";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { FormTextField } from "../../app/common/form/FormTextField";
import { FormSelect } from "../../app/common/form/FormSelect";

interface Props {
  closeForm: () => void;
  inventory: Inventory
}

export default observer(function ConvertInventoryForm({ closeForm, inventory }: Props) {
  const { unitStore, inventoryStore } = useStore();
  const {
    getUnitOptions,
    getBaseUnitOptions,
    loadingInitial: unitsloading,
  } = unitStore;
  const { convertInventory } = inventoryStore
  const [title, setTitle] = useState<string>("Convert Inventory Unit");
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [convertInventoryFormValues, setConvertInventoryForm] =
    useState<ConvertInventoryFormValues>({
      inventoryId: inventory.id,
      quantityToConvert: 0,
      convertToUnitId: "",
    });

  const validationSchema = yup.object().shape({
    quantityToConvert: yup.number().required("Required"),
    convertToUnitId: yup.string().required("Required"),
  });

  useEffect(() => {
    console.log("inventory", inventory)
    getUnitOptions().then((unitOptions) => {
      let uOptions: UnitsOption[] = [];
      unitOptions!.forEach((option) => {
        let uOption: UnitsOption = { value: option.value, label: option.label };
        uOptions.push(uOption);
      });
      setUnitOptions(uOptions);
    });
  }, [getUnitOptions]);

  function handleFormSubmit(
    convertInventoryFormValues: ConvertInventoryFormValues
  ) {
    convertInventory(convertInventoryFormValues).then(() => closeForm());
  }

  if (unitsloading) return <LoadingComponent content="Loading converter..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={convertInventoryFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                Please fill in the quantity of '{inventory.itemName}' and the converstion information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="quantityToConvert"
                      type="number"
                      label="Quantity To Convert"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="convertToUnitId"
                      label="Convert to unit"
                      size="small"
                      options={unitOptions}
                      component={FormSelect}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
