import { Typography } from "@mui/material";
import { ErrorMessage, useField } from "formik";
import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

export default function MyDateInput(props: Partial<ReactDatePickerProps>) {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(value) => helpers.setValue(value)}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage
          name="error"
          render={() => (
            <Typography sx={{ color: "red" }}>{meta.error}</Typography>
          )}
        />
      ) : null}
    </>
  );
}
