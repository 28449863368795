import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default observer(function DispatchList() {
  const { requestReleasestore } = useStore();
  const {
    loadRequestRelease,
    loadRequestReleases,
    getRequestReleases,
    requestReleaseRegistry,
  } = requestReleasestore;

  useEffect(() => {
    requestReleaseRegistry.clear();
    if (requestReleaseRegistry.size <= 0) loadRequestReleases();
  }, [requestReleaseRegistry]);

  const handleSelectPR = (id: string) => {
    loadRequestRelease(id);
  };

  return (
    <>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ padding: 1 }} variant="h5">
            Dispatch Orders
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
        </Stack>
        <Divider sx={{ marginBottom: 1 }} />
        <Box
          style={{
            height: "73vh",
            maxHeight: "73vh",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {getRequestReleases().map((item) => (
              <Card key={item.id} onClick={() => handleSelectPR(item.id)}>
                <CardActionArea>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Typography sx={{ paddingBottom: 1 }} variant="h5">
                      Dispatch Order Number: {item.requestNumber}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {item.isComplete ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Completed"
                        />
                      ) : (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="For Dispatch"
                        />
                      )}
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </Box>
      </Paper>
    </>
  );
});
