import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import React, { useEffect, useState } from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function PurchaseOrderList() {
  const { franchiseeOrderRequestStore } = useStore();
  const {
    loadProcessOrderDetails,
    loadFranchiseeOrderRequests,
    getFranchiseeOrderRequests,
    setPagingParams,
    pagination,
    pagingParams,
  } = franchiseeOrderRequestStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    handlePaginationInit();
    loadFranchiseeOrderRequests();
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems, loadFranchiseeOrderRequests]);

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    loadFranchiseeOrderRequests();
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    loadFranchiseeOrderRequests();
  };

  const handleSelectPR = (id: string) => {
    loadProcessOrderDetails(id);
  };

  return (
    <>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ padding: 1 }} variant="h5">
            Purchase Orders
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
        </Stack>
        <Divider sx={{ marginBottom: 1 }} />
        <Box
          style={{
            height: "73vh",
            maxHeight: "73vh",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {getFranchiseeOrderRequests().map((item) => (
              <Card key={item.id} onClick={() => handleSelectPR(item.id)}>
                <CardActionArea>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Typography sx={{ paddingBottom: 1 }} variant="h5">
                      Purchase Order Number: {item.requestNumber}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {item.isApproved ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Approved"
                        />
                      ) : (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="For Approval"
                        />
                      )}
                      {item.inProgress == true && item.isApproved == true ? (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="In Progress"
                        />
                      ) : (
                        ""
                      )}
                      {item.inProgress == false && item.isComplete == true && item.isApproved == true ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Completed"
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </Box>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handlePrevClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display: currentPage == 1 ? "none" : "block",
              }}
            >
              Previous
            </Button>
          </Box>
          <Typography sx={{ padding: 1 }} variant="h6" color="text.secondary">
            Page {currentPage} of{" "}
            {totalItems != 0 ? Math.ceil(totalItems / pageSize) : 1}
          </Typography>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handleNextClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display:
                  currentPage == Math.ceil(totalItems / pageSize)
                    ? "none"
                    : totalItems != 0
                    ? "block"
                    : "none",
              }}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Paper>
    </>
  );
});
