import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Component, ComponentFormValues, ComponentList, ComponentsOption } from "../../models/admin/component";
import { Pagination, PagingParams } from "../../models/pagination";

export default class ComponentStore {
    componentRegistry = new Map<string, ComponentList>();
    componentOptions = new Map<string, ComponentsOption>();
    nonePaginatedComponentOptions = new Map<string, ComponentsOption>();
    itemCount: number = 0;
    selectedComponent: Component | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getComponents = () => {
        return Array.from(this.componentRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getNonePaginatedComponentOptions = async () => {
        this.loadingInitial = true;
        let nonePaginatedComponentptions = this.nonePaginatedComponentOptions;
        try {
            const comp = await agent.Components.options();
            comp.forEach(component => {
                let componentOption: ComponentsOption = {
                    value: component.id,
                    label: component.name
                }
                this.nonePaginatedComponentOptions.set(component.id, componentOption);
            });
            this.setLoadingInitial(false);
            return nonePaginatedComponentptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadComponent = async (id: string) => {
        this.loadingInitial = true;
        try {
            var component = await agent.Components.detail(id);
            this.setComponent(component);
            runInAction(() => {
                this.selectedComponent = component;
            })
            this.setLoadingInitial(false);
            return component;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadComponents = async () => {
        this.loadingInitial = true;
        this.componentRegistry.clear();
        try {
            const result = await agent.Components.list(this.axiosParams, "");
            result.data.forEach(component => {
                this.setComponent(component);
                let componentOption: ComponentsOption = {
                    value: component.id,
                    label: component.name
                }
                this.setComponentOption(componentOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchComponents = async (searchText: string) => {
        this.componentRegistry.clear();
        try {
            const result = await agent.Components.list(this.axiosParams,searchText);
            result.data.forEach(component => {
                this.setComponent(component);
                let componentOption: ComponentsOption = {
                    value: component.id,
                    label: component.name
                }
                this.setComponentOption(componentOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectComponent = () => {
        this.selectedComponent = undefined;
    }

    private setComponent = (component: ComponentList) => {
        this.componentRegistry.set(component.id, component);
    }

    private setComponentOption = (componentOption: ComponentsOption) => {
        this.componentOptions.set(componentOption.value, componentOption);
    }

    private getSelectedComponent = () => {
        return this.selectedComponent;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createComponent = async (component: ComponentFormValues) => {
        this.loading = true;
        try {
            var componentId = (await agent.Components.create(component)).data;
            runInAction(async() => {
                this.loadComponent(componentId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateComponent = async (component: ComponentFormValues) => {
        this.loading = true;
        try {
            var componentId = (await agent.Components.update(component)).data;
            runInAction(async() => {
                this.loadComponent(componentId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveComponent = async (componentId: string) => {
        this.loading = true;
        try {
            await agent.Components.delete(componentId);
            runInAction(async() => {
                this.loadComponents().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}