import { format } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { departmentOptions } from "../../common/options/departmentOptions";
import { Item, ItemFormValues, ItemList, ItemsOption } from "../../models/admin/items";
import { ConvertInventoryFormValues, Inventory, InventoryDto,SearchItemDto } from "../../models/Inventories/inventory";
import { TransferRequest } from "../../models/requests/transferRequest";

export default class InventoryStore {
    inventoryRegistry = new Map<string, Inventory>();
    itemCount: number = 0;
    selectedItem: Inventory | undefined = undefined;
    loading = false;
    loadingInitial = true;
    editMode = false;
    constructor() {
        makeAutoObservable(this);
    }

    getInventory = () => {
        return Array.from(this.inventoryRegistry.values());
    }

    findInventory = (itemId: string) => {
        var itemList = new Map<string, Inventory>();
        var isExpiring = false;
        this.getInventory().forEach((value) => {
             if(value.expiration) isExpiring = true;
             if(value.itemId === itemId){
                itemList.set(value.id, value);
             }
        });
        if(isExpiring){
            return Array.from(itemList.values()).sort(
                (inventoryA, inventoryB) => new Date(inventoryA.expiration!).getTime() - new Date(inventoryB.expiration!).getTime(),
            );
        } else {
            return Array.from(itemList.values());
        }
    }

    findInventoryForRequest = (itemId: string, quantity: number, unitId: string, department: number) => {
        var itemList = new Map<string, Inventory>();
        var isExpiring = false;
        for(let inventory of this.getInventory()){
            if(inventory.itemId !== itemId) continue;
            if(inventory.quantity <= 0) continue;
            if(inventory.unitOfMeasureId !== unitId) continue;
            if(inventory.expiration) isExpiring = true;
            
            itemList.set(inventory.id, inventory);
        }

        if(isExpiring){
            return Array.from(itemList.values()).sort(
                (inventoryA, inventoryB) => new Date(inventoryA.expiration!).getTime() - new Date(inventoryB.expiration!).getTime(),
            );
        } else {
            return Array.from(itemList.values());
        }
    }

    computedInventoryForTransfer = (selectedTransferRequest: TransferRequest) => {
        var quantityToTransfer = selectedTransferRequest.inventoryToTransferQuantity;
        var checkedInventoryQuantityTotal = 0;
        var availableInventories = this.findInventoryForRequest(
            selectedTransferRequest!.itemId,
            selectedTransferRequest!.inventoryToTransferQuantity,
            selectedTransferRequest!.unitId,
            selectedTransferRequest!.providingDepartment);

        var inventoriesToTransfer: Inventory[] = [];

        for(let inventory of availableInventories){
            //console.log("q:" + inventory.quantity);
            checkedInventoryQuantityTotal = checkedInventoryQuantityTotal + inventory.quantity;
            //console.log(checkedInventoryQuantityTotal);
            if(quantityToTransfer == 0) break;

            if(inventory.quantity >= quantityToTransfer){
                inventory.quantity = quantityToTransfer;
                quantityToTransfer = 0;
                inventoriesToTransfer.push(inventory);
            }else if(inventory.quantity < quantityToTransfer){
                quantityToTransfer = quantityToTransfer - inventory.quantity;
                inventoriesToTransfer.push(inventory);
            }

            if(checkedInventoryQuantityTotal >= quantityToTransfer) break;
        }

        return inventoriesToTransfer;
    }

    loadInventory = async () => {
        this.loadingInitial = true;
        try {
            const user = await agent.Account.current(); 
            let searchItemDto: SearchItemDto = {
                searchText:"",
                department: user.department
            };
            const inventory = await agent.Inventories.list(searchItemDto);
            inventory.forEach(item => {
                this.setItem(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadFullInventory = async () => {
        this.loadingInitial = true;
        try {
            let searchItemDto: SearchItemDto = {
                searchText:"",
                department: null
            };
            const inventory = await agent.Inventories.list(searchItemDto);
            inventory.forEach(item => {
                this.setItem(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchInventory = async (searchText: string) => {
        this.inventoryRegistry.clear();
        try {
            const user = await agent.Account.current(); 
            let searchItemDto: SearchItemDto = {
                searchText:searchText,
                department: user.department
              };
            const inventory = await agent.Inventories.list(searchItemDto);
            inventory.forEach(item => {
                this.setItem(item);
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setItem = (inventoryDto: InventoryDto) => {
        let inventoryItem: Inventory = {
            id: inventoryDto.id,
            itemId: inventoryDto.itemId,
            itemName: inventoryDto.itemName,
            category: inventoryDto.category,
            department: departmentOptions[inventoryDto.department].label,
            supplierName: inventoryDto.supplierName,
            brand: inventoryDto.brand,
            quantity: inventoryDto.quantity,
            unitOfMeasureId: inventoryDto.unitOfMeasureId,
            unitOfMeasure: inventoryDto.unitOfMeasure,
            locationName: inventoryDto.locationName,
            dateReceived: format(new Date(inventoryDto.dateReceived!), 'dd MMM yyyy'),
            expiration: inventoryDto.expiration ? format(new Date(inventoryDto.expiration!), 'dd MMM yyyy') : 'no expiry',
            isExpiring: inventoryDto.isExpiring
        };
        this.inventoryRegistry.set(inventoryItem.id, inventoryItem);
    }

    private getSelectedItem = () => {
        return this.selectedItem;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    convertInventory = async (convertInventoryFormValues: ConvertInventoryFormValues) => {
        this.loading = true;
        try {
            await agent.Inventories.convert(convertInventoryFormValues);
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}