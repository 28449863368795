import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import EditIcon from "@mui/icons-material/Edit";
import { green, red } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import Search from "../../../app/layout/Search";
import {
  ApproveProfileFormValues,
  EmployeeProfiles,
  EmployeeProfilesForm,
} from "../../../app/models/profile";
import { RoleList, RolesOption } from "../../../app/models/admin/role";
import { Form, Formik } from "formik";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { history } from "../../..";

export default observer(function EmployeeList() {
  const { profileStore, roleStore } = useStore();
  const {
    employees: employeeList,
    approveEmployeeProfile,
    loading,
    loadingInitial,
  } = profileStore;
  const { getRoleOptions } = roleStore;
  const [employees, setEmployees] = useState<EmployeeProfilesForm[]>([]);
  const [roleOptions, setRoleOptions] = useState<RolesOption[]>([]);
  let employeeForms: EmployeeProfilesForm[] = [];

  useEffect(() => {
    employeeList.forEach((employee) => {
      let employeeToAdd: EmployeeProfilesForm = {
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        isApproved: employee.isApproved,
        roleIds: [],
      };

      employee.roles!.forEach((role) => {
        let roleToAdd: RolesOption = {
          label: role.name,
          value: role.id,
        };
        employeeToAdd.roleIds!.push(roleToAdd);
      });
      employeeForms.push(employeeToAdd);
    });
    setEmployees(employeeForms);
    setRoleOptions(getRoleOptions());
  }, [employeeList, getRoleOptions]);

  function handleApprove(employeeToApprove: EmployeeProfilesForm) {
    let approveFormValues: ApproveProfileFormValues = {
      id: employeeToApprove.id,
      userProfile: 1,
      isApproved: true,
      roleIds: [],
    };

    employeeToApprove.roleIds!.forEach((role) => {
      approveFormValues.roleIds!.push(role.value);
    });

    approveEmployeeProfile(approveFormValues).then(() => setEmployees(employeeForms));
  }

  function handleReject(employeeToApprove: EmployeeProfilesForm) {
    let approveFormValues: ApproveProfileFormValues = {
      id: employeeToApprove.id,
      userProfile: 1,
      isApproved: false,
      roleIds: [],
    };

    approveEmployeeProfile(approveFormValues).then(() => setEmployees(employeeForms));
  }

  if (loadingInitial)
    return <LoadingComponent content="Updating User Profiles..." />;

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {employees.map((employee) => (
          <Card key={employee.id}>
            <Formik
              initialValues={employee}
              enableReinitialize
              onSubmit={(values) => handleApprove(values)}
            >
              {({ values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ flexGrow: 0, padding: 2 }}>
                      <Typography variant="h5">
                        {employee.firstName + " " + employee.lastName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 2 }}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="success"
                          disableElevation
                          disabled={loading}
                        >
                          {employee.isApproved ? "Update Role" : "Approve"}
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          disableElevation
                          disabled={loading}
                          onClick={() => handleReject(values)}
                        >
                          {employee.isApproved ? "Revoke" : "Reject"}
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                  <Autocomplete
                    sx={{ marginLeft: 2, marginRight: 2 }}
                    multiple
                    id="tags-standard"
                    options={roleOptions}
                    getOptionLabel={(option: RolesOption) => option.label}
                    defaultValue={employee.roleIds!}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, value) => {
                      setFieldValue("roleIds", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Employee Roles"
                        sx={{
                          backgroundColor: "white",
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "gray",
                          }, //styles the label
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": { borderColor: "gray" },
                          },
                        }}
                      />
                    )}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        ))}
      </Stack>
    </>
  );
});
