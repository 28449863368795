import { Chip, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { useStore } from "../../../app/stores/store";

export default observer(function RoleDetail() {
  const { roleStore } = useStore();
  const { selectedRole } = roleStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1 }}>
        {selectedRole
          ? selectedRole.name
          : "Select a role from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Typography variant="subtitle1">
              {selectedRole ? "Role Name: " + selectedRole.name : "Role Name: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRole
                ? selectedRole.department !== null
                  ? "Department: " +
                    departmentOptions[selectedRole.department].label
                  : "Department: No Department"
                : "Department: "}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">
              Permissions
            </Typography>
            {selectedRole 
            ? selectedRole.permissions!.map((permission) => (
                <Chip key={permission.id} color="default"
                label={permission.module + " " + permission.operation}/>
            )) : ""}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
