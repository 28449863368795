import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Item } from "../../models/admin/items";
import { TransfersToRepack } from "../../models/requests/repackRequest";
import { ItemToTransfer, TransferRequest, TransferRequestFormValues, TransferRequestList, TurnOverTransferRequestFormValues } from "../../models/requests/transferRequest";
import { Units } from "../../models/units";
import { Pagination, PagingParams } from "../../models/pagination";

export default class TransferRequestStore {
    transferRequestRegistry = new Map<string, TransferRequestList>();
    selectedTransferRequest: TransferRequest | undefined = undefined;
    selectedItem: Item | undefined = undefined;
    toTransfer: ItemToTransfer[] = [];
    multipleTransferFormValues: TransferRequestFormValues[] = [];
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    searchText: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        params.append('searchText', this.searchText);
        return params;
    }

    getTransferRequests = () => {
        return Array.from(this.transferRequestRegistry.values());
    }

    setSearchText = (searchText: string) => {
        this.searchText = searchText;
    }

    loadTransferRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var transferRequest = await agent.TransferRequests.detail(id);
                runInAction(() => {
                    if(!transferRequest.isTurnOver) transferRequest.completionDate = null;
                    this.selectedTransferRequest = transferRequest;
                })
                this.setLoadingInitial(false);
                return transferRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadTransferRequests = async () => {
        this.loadingInitial = true;
        this.transferRequestRegistry.clear();
        try {
            const result = await agent.TransferRequests.list(this.axiosParams, this.searchText);
            result.data.forEach(TransferRequest => {
                this.setTransferRequest(TransferRequest);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setTransferRequest = (TransferRequest: TransferRequestList) => {
        this.transferRequestRegistry.set(TransferRequest.id, TransferRequest);
    }

    addItemToTransfer = (itemToTransfer: ItemToTransfer) => {
        this.toTransfer.push(itemToTransfer);
    }
    
    addMultipleTransferFormValues = (transferFormValues: TransferRequestFormValues) => {
        this.multipleTransferFormValues.push(transferFormValues);
    };

    private getSelectedTransferRequest = () => {
        return this.selectedTransferRequest;
    }

    selectItem = (item: Item | undefined) => {
        this.selectedItem = item;
    };

    unSelectTransferRequest = () => {
        this.selectedTransferRequest = undefined;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createTransferRequest = async (TransferRequest: TransferRequestFormValues) => {
        this.loading = true;
        try {
            console.log(TransferRequest);
            var TransferRequestId = (await agent.TransferRequests.create(TransferRequest)).data;
            runInAction(async() => {
                this.loadTransferRequest(TransferRequestId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    createMultipleTransferRequest = async (transferRequests: TransferRequestFormValues[]) => {
        this.loading = true;
        try {
            transferRequests.forEach(async (transferRequest) => {
                await agent.TransferRequests.create(transferRequest)
            });
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    turnOverTransferRequest = async (TransferRequest: TurnOverTransferRequestFormValues) => {
        this.loading = true;
        try {
            await agent.TransferRequests.turnover(TransferRequest.id, TransferRequest);
            runInAction(async() => {
                this.selectedTransferRequest = undefined;
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveTransferRequest = async (transferRequestId: string) => {
        this.loading = true;
        try {
            await agent.TransferRequests.delete(transferRequestId);
            runInAction(async() => {
                this.selectedTransferRequest = undefined;
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}