import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import EmployeeList from "./EmployeeList";
import Search from "../../../app/layout/Search";
import FranchiseeList from "./FranchiseeList";

export default observer(function ManageUser() {
  const { profileStore, roleStore } = useStore();
  const {
    loadingInitial,
    employeeRegistry,
    franchiseeRegistry,
    loadEmployees,
    loadFranchisees,
    searchEmployees,
    pagination,
    pagingParams,
  } = profileStore;
  const { loadRoles, loadingInitial: loadingRoles } = roleStore;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);

  useEffect(() => {
    handlePaginationInit();
    if (employeeRegistry.size <= 1 && franchiseeRegistry.size <= 1) {
      loadEmployees();
      loadFranchisees();
      loadRoles(true);
    }
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems, loadEmployees, loadFranchisees]);

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    loadEmployees();
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    loadEmployees();
  };

  if (loadingInitial && loadingRoles)
    return <LoadingComponent content="Loading Users..." />;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Users
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          View all Users, assign and update roles
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={12} md={5.9} lg={5.9} sx={{ margin: 0.5 }}>
            <Search searchList={searchEmployees} />
            <Paper style={{ backgroundColor: "#EFEFEF" }}>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ padding: 1 }} variant="h5">
                  Employees
                </Typography>
                <Box
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                />
              </Stack>

              <Divider sx={{ marginBottom: 1 }} />
              <Box
                style={{
                  height: "73vh",
                  maxHeight: "73vh",
                  overflow: "auto",
                }}
              >
                {/* list component here */}
                <EmployeeList />
              </Box>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                />
                <Box sx={{ flexGrow: 0, padding: 1 }}>
                  <Button
                    onClick={handlePrevClick}
                    variant="contained"
                    size="small"
                    color="primary"
                    disableElevation
                    sx={{
                      backgroundColor: "orange",
                      "&:hover": {
                        backgroundColor: "orange",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "orange",
                        borderColor: "none",
                      },
                      display: currentPage == 1 ? "none" : "block",
                    }}
                  >
                    Previous
                  </Button>
                </Box>
                <Typography
                  sx={{ padding: 1 }}
                  variant="h6"
                  color="text.secondary"
                >
                  Page {currentPage} of{" "}
                  {totalItems != 0 ? Math.ceil(totalItems / pageSize) : 1}
                </Typography>
                <Box sx={{ flexGrow: 0, padding: 1 }}>
                  <Button
                    onClick={handleNextClick}
                    variant="contained"
                    size="small"
                    color="primary"
                    disableElevation
                    sx={{
                      backgroundColor: "orange",
                      "&:hover": {
                        backgroundColor: "orange",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "orange",
                        borderColor: "none",
                      },
                      display:
                        currentPage == Math.ceil(totalItems / pageSize)
                          ? "none"
                          : totalItems != 0
                          ? "block"
                          : "none",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={12} md={5.9} lg={5.9} sx={{ margin: 0.5 }}>
            <Search searchList={searchEmployees} />
            <Paper style={{ backgroundColor: "#EFEFEF" }}>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ padding: 1 }} variant="h5">
                  Franchisees
                </Typography>
                <Box
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                />
              </Stack>

              <Divider sx={{ marginBottom: 1 }} />
              <Box
                style={{
                  height: "73vh",
                  maxHeight: "73vh",
                  overflow: "auto",
                }}
              >
                {/* list component here */}
                <FranchiseeList />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
