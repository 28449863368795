import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Classification, ClassificationFormValues, ClassificationOption } from "../../models/admin/classification";
import { Pagination, PagingParams } from "../../models/pagination";

export default class ClassificationStore {
    classificationRegistry = new Map<string, Classification>();
    nonePaginatedClassificationRegistry = new Map<string, Classification>();
    classificationOptions = new Map<string, ClassificationOption>();
    nonePaginatedClassificationOptions = new Map<string, ClassificationOption>();
    itemCount: number = 0;
    selectedClassification: Classification | undefined = undefined;
    selectedNonePaginatedClassification: Classification | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getClassifications = () => {
        return Array.from(this.classificationRegistry.values()).sort((a: Classification, b: Classification) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    getNonePaginatedClassifications = () => {
        return Array.from(this.nonePaginatedClassificationRegistry.values()).sort((a: Classification, b: Classification) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    getNonePaginatedClassificationOptions = async () => {
        this.loadingInitial = true;
        let classificationOptionsPO = this.nonePaginatedClassificationOptions;
        try {
            const classifications = await agent.Classifications.options();
            classifications.forEach(classification => {
                let classificationOption: ClassificationOption = {
                    value: classification.id,
                    label: classification.name
                }
                this.nonePaginatedClassificationOptions.set(classification.id, classificationOption);
            });
            this.setLoadingInitial(false);
            return classificationOptionsPO;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadClassification = async (id: string) => {
        this.loadingInitial = true;
        try {
            let classification = await agent.Classifications.detail(id);
            this.setClassifcation(classification);
            runInAction(() => {
                this.selectedClassification = classification;
            })
            this.setLoadingInitial(false);
            return classification;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadClassifications = async () => {
        this.loadingInitial = true;
        this.classificationRegistry.clear();
        try {
            const result = await agent.Classifications.list(this.axiosParams, "");
            result.data.forEach(classification => {
                this.setClassifcation(classification);
                let classificationOption: ClassificationOption = {
                    value: classification.id,
                    label: classification.name
                }
                this.setClassificationOption(classificationOption);
            })
            this.setPagination(result.pagination)
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchClassifications = async (searchText: string) => {
        this.classificationRegistry.clear();
        try {
            const result = await agent.Classifications.list(this.axiosParams,searchText);
            result.data.forEach(classification => {
                this.setClassifcation(classification);
                let classificationOption: ClassificationOption = {
                    value: classification.id,
                    label: classification.name
                }
                this.setClassificationOption(classificationOption);
            })
            this.setPagination(result.pagination)
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadNonePaginatedClassifications = async () => {
        this.loadingInitial = true;
        this.nonePaginatedClassificationRegistry.clear();
        try {
            const classifications = await agent.Classifications.options();
            classifications.forEach(classification => {
                this.setNonePaginatedClassifcation(classification);
                let classificationOption: ClassificationOption = {
                    value: classification.id,
                    label: classification.name
                }
                this.setNonePaginatedClassificationOption(classificationOption);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    selectClassification = (id: string) => {
        this.selectedClassification = this.classificationRegistry.get(id);
    }

    selectNonePaginatedClassification = (id: string) => {
        this.selectedNonePaginatedClassification = this.nonePaginatedClassificationRegistry.get(id);
    }

    unSelectClassification = () => {
        this.selectedClassification = undefined;
    }

    private setClassifcation = (classification: Classification) => {
        this.classificationRegistry.set(classification.id, classification);
    }

    private setNonePaginatedClassifcation = (classification: Classification) => {
        this.nonePaginatedClassificationRegistry.set(classification.id, classification);
    }

    private setClassificationOption = (classificationOption: ClassificationOption) => {
        this.classificationOptions.set(classificationOption.value, classificationOption);
    }

    private setNonePaginatedClassificationOption = (classificationOption: ClassificationOption) => {
        this.nonePaginatedClassificationOptions.set(classificationOption.value, classificationOption);
    }

    private getClassification = (id: string) => {
        return this.classificationRegistry.get(id);
    }

    private getNonePaginatedClassification = (id: string) => {
        return this.nonePaginatedClassificationRegistry.get(id);
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createClassification = async (classification: ClassificationFormValues) => {
        this.loading = true;
        try {
            var classificationId = (await agent.Classifications.create(classification)).data;
            runInAction(async() => {
                this.loadClassification(classificationId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateClassification = async (classification: ClassificationFormValues) => {
        this.loading = true;
        try {
            var classificationId = (await agent.Classifications.update(classification)).data;
            runInAction(async() => {
                this.loadClassification(classificationId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveClassification = async (classificationId: string) => {
        this.loading = true;
        try {
            await agent.Classifications.delete(classificationId);
            runInAction(async() => {
                this.loadClassifications().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}