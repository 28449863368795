import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { OrderRequest, OrderRequestFormValues, OrderRequestList } from "../../models/requests/orderRequest";

export default class OrderRequestStore {
    orderRequestRegistry = new Map<string, OrderRequestList>();
    itemCount: number = 0;
    selectedOrderRequest: OrderRequest | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    getOrderRequests = () => {
        return Array.from(this.orderRequestRegistry.values());
    }

    loadOrderRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var orderRequest = await agent.OrderRequests.detail(id);
                runInAction(() => {
                    this.selectedOrderRequest = orderRequest;
                })
                this.setLoadingInitial(false);
                return orderRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadOrderRequests = async () => {
        this.loadingInitial = true;
        try {
            const orderRequests = await agent.OrderRequests.list();
            orderRequests.forEach(orderRequest => {
                this.setOrderRequest(orderRequest);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setOrderRequest = (orderRequest: OrderRequestList) => {
        this.orderRequestRegistry.set(orderRequest.id, orderRequest);
    }

    private getSelectedOrderRequest = () => {
        return this.selectedOrderRequest;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createOrderRequest = async (orderRequest: OrderRequestFormValues) => {
        this.loading = true;
        try {
            await agent.OrderRequests.create(orderRequest);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}