import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Ingredient, IngredientFormValues, IngredientList, IngredientOption } from "../../models/admin/ingredient";
import { Pagination, PagingParams } from "../../models/pagination";

export default class IngredientStore {
    ingredientRegistry = new Map<string, IngredientList>();
    nonePaginatedIngredientRegistry = new Map<string, IngredientList>();
    ingredientOptions = new Map<string, IngredientOption>();
    ingredientNonePaginatedOptions = new Map<string, IngredientOption>();
    itemCount: number = 0;
    selectedIngredient: Ingredient | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getIngredients = () => {
        return Array.from(this.ingredientRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getNonePaginatedIngredients = () => {
        return Array.from(this.nonePaginatedIngredientRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getIngredientOptions = async () => {
        this.loadingInitial = true;
        let ingredientOptions = this.ingredientOptions;
        try {
            const result = await agent.Ingredients.list(this.axiosParams, "");
            result.data.forEach(ingredient => {
                let ingredientOption: IngredientOption = {
                    value: ingredient.id,
                    label: ingredient.name
                }
                this.ingredientOptions.set(ingredient.id, ingredientOption);
            });
            this.setLoadingInitial(false);
            return ingredientOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getNonePaginatedIngredientOptions = async () => {
        this.loadingInitial = true;
        let ingredientOptions = this.ingredientNonePaginatedOptions;
        try {
            const ingredients = await agent.Ingredients.options();
            ingredients.forEach(ingredient => {
                let ingredientOption: IngredientOption = {
                    value: ingredient.id,
                    label: ingredient.name
                }
                this.ingredientNonePaginatedOptions.set(ingredient.id, ingredientOption);
            });
            this.setLoadingInitial(false);
            return ingredientOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadIngredient = async (id: string) => {
        this.loadingInitial = true;
            try {
                var ingredient = await agent.Ingredients.detail(id);
                this.setIngredient(ingredient);
                runInAction(() => {
                    this.selectedIngredient = ingredient;
                })
                this.setLoadingInitial(false);
                return ingredient;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadIngredients = async () => {
        this.loadingInitial = true;
        this.ingredientRegistry.clear();
        try {
            const result = await agent.Ingredients.list(this.axiosParams, "");
            result.data.forEach(ingredient => {
                this.setIngredient(ingredient);
                let ingredientOption: IngredientOption = {
                    value: ingredient.id,
                    label: ingredient.name
                }
                this.setIngredientOption(ingredientOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchIngredients = async (searchText: string) => {
        this.ingredientRegistry.clear();
        try {
            const result = await agent.Ingredients.list(this.axiosParams,searchText);
            result.data.forEach(ingredient => {
                this.setIngredient(ingredient);
                let ingredientOption: IngredientOption = {
                    value: ingredient.id,
                    label: ingredient.name
                }
                this.setIngredientOption(ingredientOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadNonePaginatedIngredients = async () => {
        this.loadingInitial = true;
        this.nonePaginatedIngredientRegistry.clear();
        try {
            const ingredients = await agent.Ingredients.options();
            ingredients.forEach(ingredient => {
                this.setNonePaginatedIngredient(ingredient);
                let ingredientOption: IngredientOption = {
                    value: ingredient.id,
                    label: ingredient.name
                }
                this.setNonePaginatedIngredientOption(ingredientOption);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectIngredient = () => {
        this.selectedIngredient = undefined;
    }

    private setIngredient = (ingredient: IngredientList) => {
        this.ingredientRegistry.set(ingredient.id, ingredient);
    }

    
    private setNonePaginatedIngredient = (ingredient: IngredientList) => {
        this.nonePaginatedIngredientRegistry.set(ingredient.id, ingredient);
    }

    private setIngredientOption = (ingredientOption: IngredientOption) => {
        this.ingredientOptions.set(ingredientOption.value, ingredientOption);
    }

    private setNonePaginatedIngredientOption = (ingredientOption: IngredientOption) => {
        this.ingredientNonePaginatedOptions.set(ingredientOption.value, ingredientOption);
    }

    private getSelectedIngredient = () => {
        return this.selectedIngredient;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createIngredient = async (ingredient: IngredientFormValues) => {
        this.loading = true;
        try {
            var ingredientId = (await agent.Ingredients.create(ingredient)).data;
            runInAction(async() => {
                this.loadIngredient(ingredientId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateIngredient = async (ingredient: IngredientFormValues) => {
        this.loading = true;
        try {
            var ingredientId = (await agent.Ingredients.update(ingredient)).data;
            runInAction(async() => {
                this.loadIngredient(ingredientId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveIngredient = async (ingredientId: string) => {
        this.loading = true;
        try {
            await agent.Ingredients.delete(ingredientId);
            runInAction(async() => {
                this.loadIngredients().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}