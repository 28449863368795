import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useStore } from "../../../app/stores/store";
import {
  EndingInventoryItems,
  EndingInventoryFilter,
  DiscrepancyReportFilter,
  AddEndingInventory,
  DiscrepancyReportItems,
} from "../../../app/models/Inventories/inventory";
import { useHistory } from "react-router-dom";
import moment from "moment";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

export default observer(function EndingInventory() {
  const history = useHistory();
  const { discrepancyReportStore, endingInventoryStore } = useStore();
  const {
    endingInventoryItemsRegistry,
    loadEndingInventoryItems,
    generateExcelReport,
    loadingInitial,
    getInitialDate,
    getInitialId,
    getIsView,
    getEndingInventoryItems,
    getSelectedItem,
    selectItem,
    getEndingInventoryItemsFiter,
    getHasSetFilter,
    setHasSetFilter,
    createEndingInventory,
  } = endingInventoryStore;
  const {
    setDiscrepancyReportFilter,
    setHasSetFilterDiscrep,
    setInitialDateDiscrep,
  } = discrepancyReportStore;
  const [hasRowCheck, sethasRowCheck] = useState<boolean>(true);
  const [hasRendered, sethasRendered] = useState<boolean>(false);
  const [initDate, setInitDate] = useState<string>();
  const [hasConfirm, setHasConfirm] = useState(false);
  const [hasReport, setHasReport] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [rows, setRows] = useState<Array<EndingInventoryItems>>([]);
  const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>();
  const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "itemName", headerName: "Item Name", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    { field: "departmentName", headerName: "Department", width: 200 },
    { field: "supplierName", headerName: "Supplier", width: 200 },
    { field: "brand", headerName: "Brand", width: 200 },
    { field: "unitOfMeasure", headerName: "Unit Of Measurement", width: 200 },
    { field: "locationName", headerName: "Location", width: 200 },
    { field: "startingQuantity", headerName: "Starting Qty", width: 150 },
    { field: "addedQuantity", headerName: "Added Qty", width: 150 },
    { field: "dispatchedQuantity", headerName: "Dispatched Qty", width: 150 },
    { field: "wasteYielding", headerName: "Waste/Yielding", width: 150 },
    { field: "endingQuantity", headerName: "Ending Qty", width: 150 },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerName: "Report Discrepancy",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];
  const DataGridTheme = createTheme({
    components: {
      //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&.Mui-selected": {
              backgroundColor: "rgba(211, 47, 47, 0.3)",
              "&:hover": {
                backgroundColor: "rgba(211, 47, 47, 0.3)",
              },
            },
            "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
              {
                color: "#871C1C",
              },
          },
        },
      },
    },
  });
  useEffect(() => {
    let endInvFilter: EndingInventoryFilter = {
      id: getEndingInventoryItemsFiter()?.id,
      filteredDateStart: getEndingInventoryItemsFiter()?.filteredDateStart,
      filteredDateEnd: getEndingInventoryItemsFiter()?.filteredDateStart,
      departmentId: getEndingInventoryItemsFiter()?.departmentId,
    };
    if (getInitialDate() != "" && getInitialDate() != null) {
      setInitDate(moment(getInitialDate()).format("dddd MMMM DD, YYYY"));
    } else {
      setInitDate(moment().format("dddd MMMM DD, YYYY"));
    }
    if (getHasSetFilter()) {
      setHasSetFilter(false);
      endingInventoryItemsRegistry.clear();
    }

    if (endingInventoryItemsRegistry.size <= 0) {
      sethasRendered(true);
      loadEndingInventoryItems(endInvFilter);
    }

    if (getEndingInventoryItems().length != 0 && hasRendered == true) {
      setRows(getEndingInventoryItems());
      sethasRendered(false);
    }
  }, [selectedRows.length]);

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    setSelectedRows([]);
    const selectedRowsData = ids.map((id) =>
      getEndingInventoryItems().find((row) => row.id === id)
    );
    selectedRowsData.forEach((element) => {
      let disReportitem: DiscrepancyReportItems = {
        id: element?.id != null ? element?.id : "",
        inventoryItemId:
          element?.inventoryItemId != null ? element?.inventoryItemId : "",
        itemName: element?.itemName != null ? element?.itemName : "",
        unitOfMeasure:
          element?.unitOfMeasure != null ? element?.unitOfMeasure : "",
        expectedEndingQuantity:
          element?.endingQuantity != null ? element.endingQuantity : 0,
        endingQuantity: 0,
        reason: "",
      };
      selectedRows.push(disReportitem);
    });
    if (selectedRows.length != 0) {
      selectItem(selectedRows);
      sethasRowCheck(false);
    } else {
      sethasRowCheck(true);
    }
  };

  const handleConfirm = () => {
    setHasConfirm(true);
  };

  const handleCloseConfirm = () => {
    setHasConfirm(false);
  };

  const handleReport = () => {
    setHasReport(true);
  };

  const handleCloseReport = () => {
    setHasReport(false);
  };

  const handleSubmitReport = () => {
    let disReportFilter: DiscrepancyReportFilter = {
      id: null,
    };
    setDiscrepancyReportFilter(disReportFilter);
    setHasSetFilterDiscrep(true);
    setHasReport(false);
    setInitialDateDiscrep("");
    history.push("/discrepancyreport");
  };

  const handleSubmitted = () => {
    setHasConfirm(false);
    setHasSubmitted(true);
    let InvDate = "";
    let InvId = "";
    if (getInitialDate() != "" && getInitialDate() != null) {
      InvDate = moment(getInitialDate()).format("YYYY-MM-DD HH:mm:ss");
      InvId = getInitialId();
    } else {
      InvDate = moment().format("YYYY-MM-DD HH:mm:ss");
      InvId = "";
    }
    const InvDateConverted = new Date(InvDate);
    let addEndingInventory: AddEndingInventory = {
      endingInventoryId: InvId,
      endingInventoryDate: InvDateConverted,
      departmentName: "",
      endingInventoryItems: getEndingInventoryItems(),
    };
    createEndingInventory(addEndingInventory);
  };

  const handleCloseSubmitted = () => {
    setHasSubmitted(false);
    history.push("/endinginventorylist");
  };
  
  const handleExportExcel = () => {
    let endInvFilter: EndingInventoryFilter = {
      id: getEndingInventoryItemsFiter()?.id,
      filteredDateStart:new Date(moment(startDateValue?.format('YYYY-MM-DD')).format("YYYY-MM-DD")) ,
      filteredDateEnd: new Date(moment(endDateValue?.format('YYYY-MM-DD')).format("YYYY-MM-DD")),
      departmentId: getEndingInventoryItemsFiter()?.departmentId,
    };
    generateExcelReport(endInvFilter);
  };

  const handleSearch = () => {
    let endInvFilter: EndingInventoryFilter = {
      id: getEndingInventoryItemsFiter()?.id,
      filteredDateStart:new Date(moment(startDateValue?.format('YYYY-MM-DD')).format("YYYY-MM-DD")) ,
      filteredDateEnd: new Date(moment(endDateValue?.format('YYYY-MM-DD')).format("YYYY-MM-DD")),
      departmentId: getEndingInventoryItemsFiter()?.departmentId,
    };
    setHasSetFilter(false);
    endingInventoryItemsRegistry.clear();
    loadEndingInventoryItems(endInvFilter);
  };

  const handleStartDate= (newValue: Dayjs|null) => {
    setStartDateValue(newValue)
    if(endDateValue){
      setInitDate(newValue?.format("dddd MMMM DD, YYYY") + " - " + endDateValue?.format("dddd MMMM DD, YYYY"));
    }else{
      setInitDate(newValue?.format("dddd MMMM DD, YYYY"));
    }
  };

  const handleEndDate= (newValue: Dayjs|null) => {
    setEndDateValue(newValue)
    if(startDateValue){
      setInitDate(startDateValue?.format("dddd MMMM DD, YYYY") + " - " + newValue?.format("dddd MMMM DD, YYYY"));
    }else{
      setInitDate(newValue?.format("dddd MMMM DD, YYYY"));
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1, display: "inline-block"}} variant="h3">
          Ending Inventory
        </Typography>
        <Stack direction="row" spacing={2} sx={{display: getIsView()==true? "none": "inline-block", float:"right"} }>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Box
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <DatePicker
                 sx={{
                  marginRight: "20px",
                }}
                label="Start Date"
                value={startDateValue}
                onChange={(newValue1) => handleStartDate(newValue1)}
              />
              <DatePicker
                label="End Date"
                value={endDateValue}
                onChange={(newValue2) => handleEndDate(newValue2)}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Box>
        </Stack>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          {initDate}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <ThemeProvider theme={DataGridTheme}>
          <Box sx={{ height: 840, width: "100%" }}>
            <DataGrid
              rows={getEndingInventoryItems()}
              columns={columns}
              initialState={{
                pagination: {
                  pageSize: 100,
                },
              }}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    "&:before": {
                      content: '"Report Discrepancy"',
                    },
                  },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent":
                  {
                    display: "none",
                  },
              }}
            />
          </Box>
        </ThemeProvider>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={() => handleExportExcel()}
              sx={{
                marginRight: 0,
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Export Excel
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={getIsView()}
              disableElevation
              onClick={() => handleConfirm()}
              sx={{
                marginRight: 0,
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Confirm Quantity
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleReport()}
              disabled={hasRowCheck}
              disableElevation
              sx={{
                backgroundColor: "error",
                marginRight: 0,
                "&:hover": {
                  backgroundColor: "error",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "error",
                  borderColor: "none",
                },
              }}
            >
              Report Discrepancy
            </Button>
          </Box>
        </Stack>
        <Dialog open={hasConfirm}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to confirm Ending Inventory?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirm}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmitted()}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={hasReport}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to report the selected discrepancy?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseReport}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmitReport()}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "black",
                  borderColor: "none",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={hasSubmitted} onClose={handleCloseSubmitted}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                "& .css-i4bv87-MuiSvgIcon-root": {
                  "margin-bottom": "-5px",
                  "margin-left": "10px",
                  color: "#45D015",
                },
              }}
            >
              Ending Inventory Submitted
              <CheckCircleIcon />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
});
