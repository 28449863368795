import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { PasswordResetRequest, PasswordResetRequestFormValues, User, UserFormValues } from '../models/user';
import { store } from '../stores/store';
import { history } from '../..';
import { ApproveProfileFormValues, EmployeeProfile, EmployeeProfileFormValues, EmployeeProfiles, FranchiseeProfile, FranchiseeProfileFormValues, FranchiseeProfiles } from '../models/profile';
import { Branch, BranchFormValues } from '../models/admin/branch';
import { Category, CategoryFormValues } from '../models/admin/category';
import { Classification, ClassificationFormValues } from '../models/admin/classification';
import { Component, ComponentFormValues, ComponentList } from '../models/admin/component';
import { FinalPortion, FinalPortionFormValues, FinalPortionList } from '../models/admin/finalPortion';
import { Item, ItemFormValues, ItemList } from '../models/admin/items';
import { Location, LocationFormValues, LocationList,FilterDepartment } from '../models/admin/location';
import { Menu, MenuFormValues } from '../models/admin/menus';
import { Supplier, SupplierFormValues } from '../models/admin/suppliers';
import { ComponentsToOrder, FranchiseePurchaseOrderRequest, FranchiseePurchaseOrderRequestFormValues, FranchiseePurchaseOrderRequestList,ApprovedPOFormValues,CompletePOFormValues,RestockPurchaseRequestFormValues, GetComponentsToOrder, OrderConfirmation } from '../models/requests/franchiseePurchaseOrderRequest';
import { CompleteOrderRequestFormValues, OrderRequest, OrderRequestFormValues, OrderRequestList } from '../models/requests/orderRequest';
import { CompleteTurnOverRequestFormValues, TurnOverRequest, TurnOverRequestList } from '../models/requests/turnOverRequest';
import { CompleteRequestReleaseFormValues, RequestRelease, RequestReleaseList } from '../models/requests/requestRelease';
import { AltUnitForm, BaseUnits, Unit, Units } from '../models/units';
import { IncomingStockFormValues, Inventory, InventoryDto,EndingInventoryDto,SearchItemDto, EndingInventoryItemsDto ,DiscrepancyReportDto,DiscrepancyReportListFilter, DiscrepancyReportItemsDto,EndingInventoryListFilter, EndingInventoryFilter,AddEndingInventory, DiscrepancyReportFilter,DiscrepancyReportExcel, AddDiscrepancyReport, ConvertInventoryFormValues} from '../models/Inventories/inventory';
import { Repack, RepackFormValues, RepackList } from '../models/admin/repack';
import { Ingredient, IngredientFormValues, IngredientList } from '../models/admin/ingredient';
import { Recipe, RecipeFormValues, RecipeList } from '../models/admin/recipe';
import { CompleteRepackRequestFormValues, ConfirmRepack, RepackRequest, RepackRequestFormValues, RepackRequestList, RepacksToRequest, RepackToConfirm, RestockRepackRequestFormValues, TurnOverRepackRequestFormValues } from '../models/requests/repackRequest';
import { TransferRequest, TransferRequestFormValues, TransferRequestList } from '../models/requests/transferRequest';
import { Permission, Role, RoleFormValues, RoleList } from '../models/admin/role';
import { CompleteProcessRequestFormValues, ConfirmProcess, ProcessRequest, ProcessRequestList, ProcessToRequest, RestockProcessRequestFormValues, TurnOverProcessRequestFormValues } from '../models/requests/processRequest';
import { PaginatedResult } from '../models/pagination';
import { error } from 'console';

const { REACT_APP_API } = process.env;

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = REACT_APP_API?.replace('[HOST]', window.location.hostname);
//axios.defaults.baseURL = 'https://localhost:7018/api';

//for authentication
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config;
})


// with error handling
axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    const {data, status, config} = error.response!;
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data);
            }
            break;
        case 401:
            toast.error('unauthorised');
            break;
        case 404:
            if(config.url != "/profile/franchisee"){
                if(config.url != "/profile/employee"){
                    history.push('/not-found');
                }
            }
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    getWithBody: <T> (url: string, body: {}) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    postFile: <T> (url: string, body: {}, config: AxiosRequestConfig<any>) => axios.post<T>(url, body,config).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    putWithoutBody: <T> (url: string) => axios.put<T>(url).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

//admin requests
const Branches = {
    detail: (id: string) => requests.get<Branch>(`/branches/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<Branch[]>>(`/branches?${params}&SearchText=${SearchText}`),
    options: () => requests.get<Branch[]>('/branches/branchoptions'),
    create: (branch: BranchFormValues) => axios.post<string>('/branches', branch),
    update: (branch: BranchFormValues) => axios.put<string>(`/branches/${branch.id}`, branch),
    delete: (id: string) => axios.put<void>(`/branches/archive/${id}`)
}
const Categories = {
    detail: (id: string) => requests.get<Category>(`/categories/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<Category[]>>(`/categories?${params}&SearchText=${SearchText}`),
    options: () => requests.get<Category[]>('/categories/categoriesoptions'),
    create: (category: CategoryFormValues) => axios.post<string>('/categories', category),
    update: (category: CategoryFormValues) => axios.put<string>(`/categories/${category.id}`, category),
    delete: (id: string) => axios.put<void>(`/categories/archive/${id}`)
}
const Classifications = {
    detail: (id: string) => requests.get<Classification>(`/classifications/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<Classification[]>>(`/classifications?${params}&SearchText=${SearchText}`),
    options: () => requests.get<Classification[]>('/classifications/classificationoptions'),
    create: (classification: ClassificationFormValues) => axios.post<string>('/classifications', classification),
    update: (classification: ClassificationFormValues) => axios.put<string>(`/classifications/${classification.id}`, classification),
    delete: (id: string) => axios.put<void>(`/classifications/archive/${id}`)
}
const Components = {
    detail: (id: string) => requests.get<Component>(`/components/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<ComponentList[]>>(`/components?${params}&SearchText=${SearchText}`),
    options: () => requests.get<ComponentList[]>('/components/componentoptions'),
    create: (component: ComponentFormValues) => axios.post<string>('/components', component),
    update: (component: ComponentFormValues) => axios.put<string>(`/components/${component.id}`, component),
    delete: (id: string) => axios.put<void>(`/components/archive/${id}`)
}
const FinalPortions = {
    detail: (id: string) => requests.get<FinalPortion>(`/finalportions/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<FinalPortionList[]>>(`/finalportions?${params}&SearchText=${SearchText}`),
    create: (finalPortion: FinalPortionFormValues) => axios.post<string>('/finalportions', finalPortion),
    update: (finalPortion: FinalPortionFormValues) => axios.put<string>(`/finalportions/${finalPortion.id}`, finalPortion),
    delete: (id: string) => axios.put<void>(`/finalportions/archive/${id}`)
}
const Ingredients = {
    detail: (id: string) => requests.get<Ingredient>(`/ingredients/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<IngredientList[]>>(`/ingredients?${params}&SearchText=${SearchText}`),
    options: () => requests.get<IngredientList[]>('/ingredients/ingredientoptions'),
    create: (ingredient: IngredientFormValues) => axios.post<string>('/ingredients', ingredient),
    update: (ingredient: IngredientFormValues) => axios.put<string>(`/ingredients/${ingredient.id}`, ingredient),
    delete: (id: string) => axios.put<void>(`/ingredients/archive/${id}`)
}
const Items = {
    detail: (id: string) => requests.get<Item>(`/items/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<ItemList[]>>(`/items?${params}&SearchText=${SearchText}`),
    options: () => requests.get<ItemList[]>('/items/itemoptions'),
    create: (item: ItemFormValues) => axios.post<string>('/items', item),
    update: (item: ItemFormValues) => axios.put<string>(`/items/${item.id}`, item),
    delete: (id: string) => axios.put<void>(`/items/archive/${id}`)
}
const Locations = {
    detail: (id: string) => requests.get<Location>(`/locations/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<LocationList[]>>(`/locations?${params}&SearchText=${SearchText}`),
    options: (filterDepartment: FilterDepartment) => requests.post<Location[]>('/locations/locationoptions', filterDepartment),
    create: (location: LocationFormValues) => axios.post<string>('/locations', location),
    update: (location: LocationFormValues) => axios.put<string>(`/locations/${location.id}`, location),
    delete: (id: string) => axios.put<void>(`/locations/archive/${id}`)
}
const Menus = {
    detail: (id: string) => requests.get<Menu>(`/menus/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<Menu[]>>(`/menus?${params}&SearchText=${SearchText}`),
    options: () => requests.get<Menu[]>('/menus/menuoptions'),
    create: (menu: MenuFormValues) => axios.post<string>('/menus', menu),
    update: (menu: MenuFormValues) => axios.put<string>(`/menus/${menu.id}`, menu),
    delete: (id: string) => axios.put<void>(`/menus/archive/${id}`)
}
const Permissions = {
    list: () => requests.get<Permission[]>('/permissions'),
    myPermissions: () => requests.get<Permission[]>('/permissions/user')
}
const Recipes = {
    detail: (id: string) => requests.get<Recipe>(`/recipes/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<RecipeList[]>>(`/recipes?${params}&SearchText=${SearchText}`),
    options: () => requests.get<RecipeList[]>('/recipes/recipeoptions'),
    create: (recipe: RecipeFormValues) => axios.post<string>('/recipes', recipe),
    update: (recipe: RecipeFormValues) => axios.put<string>(`/recipes/${recipe.id}`, recipe),
    delete: (id: string) => axios.put<void>(`/recipes/archive/${id}`)
}
const Repacks = {
    detail: (id: string) => requests.get<Repack>(`/repacks/${id}`),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<RepackList[]>>(`/repacks?${params}&SearchText=${SearchText}`),
    create: (repack: RepackFormValues) => axios.post<string>('/repacks', repack),
    update: (repack: RepackFormValues) => axios.put<string>(`/repacks/${repack.id}`, repack),
    delete: (id: string) => axios.put<void>(`/repacks/archive/${id}`)
}

const Roles = {
    detail: (id: string) => requests.get<Role>(`/roles/${id}`),
    list: (params: URLSearchParams, SearchText: string, options: boolean = false) => 
        requests.get<PaginatedResult<RoleList[]>>(`/roles?${params}&SearchText=${SearchText}&options=${options}`),
    create: (role: RoleFormValues) => axios.post<string>('/roles', role),
    update: (role: RoleFormValues) => axios.put<string>(`/roles/${role.id}`, role),
    delete: (id: string) => axios.put<void>(`/roles/archive/${id}`)
}


const Suppliers = {
    detail: (id: string) => requests.get<Supplier>(`/suppliers/${id}`),
    options: () => requests.get<Supplier[]>('/suppliers/supplieroptions'),
    list: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<Supplier[]>>(`/suppliers?${params}&SearchText=${SearchText}`),
    create: (supplier: SupplierFormValues) => axios.post<string>('/suppliers', supplier),
    update: (supplier: SupplierFormValues) => axios.put<string>(`/suppliers/${supplier.id}`, supplier),
    delete: (id: string) => axios.put<void>(`/suppliers/archive/${id}`)
}
//end of admin

//requests
const FranchiseePurchaseOrderRequests = {
    list: (params: URLSearchParams, fetchAll: boolean = false) => axios.get<PaginatedResult<FranchiseePurchaseOrderRequestList[]>>(`/requests/franchisee/purchaseorders?fetchAll=${fetchAll}`, {params}).then(responseBody),
    cart: (franchiseeOrderQuantity: GetComponentsToOrder) => requests.post<ComponentsToOrder[]>('/requests/franchisee/purchaseorders/getorders', franchiseeOrderQuantity),
    create: (purchaseOrder: FranchiseePurchaseOrderRequestFormValues) => axios.post<OrderConfirmation>('/requests/franchisee/purchaseorders', purchaseOrder),
    detail: (id: string) => requests.get<FranchiseePurchaseOrderRequest>(`/requests/franchisee/purchaseorders/${id}`),
    approve: (approvedPOFormValues: ApprovedPOFormValues) => requests.put<void>(`/requests/franchisee/approved/purchaseorders`,approvedPOFormValues),
    complete: (completePOFormValues: CompletePOFormValues) => requests.put<void>(`/requests/franchisee/complete/purchaseorders`,completePOFormValues),
    restock: (restockPurchaseRequestFormValues: RestockPurchaseRequestFormValues) => requests.put<void>(`/requests/franchisee/checkrestock/purchaseorders`, restockPurchaseRequestFormValues)
}
const OrderRequests = {
    list: () => requests.get<OrderRequestList[]>('/requests/orders'),
    create: (order: OrderRequestFormValues) => axios.post<void>('/requests/order', order),
    detail: (id: string) => requests.get<OrderRequest>(`/requests/order/${id}`),
    complete: (id: string, orderToComplete: CompleteOrderRequestFormValues) => requests.put<void>(`/requests/complete/order/${id}`, orderToComplete)
}
const TurnOverRequests = {
    list: () => requests.get<TurnOverRequestList[]>('/requests/turnovers'),
    complete: (completeTurnOver: CompleteTurnOverRequestFormValues) => axios.post<void>('/requests/complete/turnover', completeTurnOver),
    detail: (id: string) => requests.get<TurnOverRequest>(`/requests/turnover/${id}`)
}
const RequestReleases = {
    list: () => requests.get<RequestReleaseList[]>('/requests/releases'),
    complete: (id: string,completeRelease: CompleteRequestReleaseFormValues) => axios.post<void>(`/requests/complete/release/${id}`, completeRelease),
    detail: (id: string) => requests.get<RequestRelease>(`/requests/release/${id}`)
}
const RepackRequests = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<RepackRequestList[]>>('/requests/repack', {params}).then(responseBody),
    create: (repackRequests: RepacksToRequest) => axios.post<void>('/requests/repack', repackRequests),
    detail: (id: string) => requests.get<RepackRequest>(`/requests/repack/${id}`),
    confirm: (repackId: string, quantity: number, unitId: string, department: number) => requests.get<ConfirmRepack>(`/requests/repack/${repackId}/${quantity}/${unitId}/${department}`),
    restock: (id: string, repackToRestock: RestockRepackRequestFormValues) => requests.put<void>(`/requests/restock/repack/${id}`, repackToRestock),
    complete: (id: string, repackToComplete: CompleteRepackRequestFormValues) => requests.put<string>(`/requests/complete/repack/${id}`, repackToComplete),
    turnover: (id: string, repackToTurnOver: TurnOverRepackRequestFormValues) => requests.put<string>(`/requests/turnover/repack/${id}`, repackToTurnOver),
    delete: (id: string) => axios.put<void>(`/requests/archive/repack/${id}`)
}

const ProcessRequests = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<ProcessRequestList[]>>('/requests/process', {params}).then(responseBody),
    create: (processRequests: ProcessToRequest) => axios.post<void>('/requests/process', processRequests),
    detail: (id: string) => requests.get<ProcessRequest>(`/requests/process/${id}`),
    confirm: (recipeId: string, department: number) => requests.get<ConfirmProcess>(`/requests/process/${recipeId}/${department}`),
    restock: (id: string, processToRestock: RestockProcessRequestFormValues) => requests.put<void>(`/requests/restock/process/${id}`, processToRestock),
    complete: (id: string, processToComplete: CompleteProcessRequestFormValues) => requests.put<string>(`/requests/complete/process/${id}`, processToComplete),
    turnover: (id: string, processToTurnOver: TurnOverProcessRequestFormValues) => requests.put<string>(`/requests/turnover/process/${id}`, processToTurnOver)
}

const TransferRequests = {
    list: (params: URLSearchParams, searchText: string) => axios.get<PaginatedResult<TransferRequestList[]>>(`/requests/transfer?${params}&SearchText=${searchText}`).then(responseBody),
    create: (transfer: TransferRequestFormValues) => axios.post<string>('/requests/transfer', transfer),
    detail: (id: string) => requests.get<TransferRequest>(`/requests/transfer/${id}`),
    turnover: (id: string, itemToTransfer: TurnOverRepackRequestFormValues) => requests.put<string>(`/requests/turnover/transfer/${id}`, itemToTransfer),
    delete: (id: string) => axios.put<void>(`/requests/archive/transfer/${id}`)
}
//requests end

//inventories
const Inventories = {
    list: (searchItemDto: SearchItemDto) => requests.post<InventoryDto[]>('/inventory', searchItemDto),
    convert: (convertInventory: ConvertInventoryFormValues) => axios.post<void>('/inventory/convert', convertInventory)
}

const EndingInventories = {
    list: (endingInventoryListFilter: EndingInventoryListFilter) => requests.post<EndingInventoryDto[]>('/endinginventories', endingInventoryListFilter),
    listitems: (endingInventoryFilter: EndingInventoryFilter) => requests.post<EndingInventoryItemsDto[]>('/endinginventories/inventoryitems', endingInventoryFilter),
    generateExcelReport: (endingInventoryFilter: EndingInventoryFilter) => requests.postFile<File>('/endinginventories/generateexcel', endingInventoryFilter,{responseType: 'arraybuffer'}),
    create: (endInv: AddEndingInventory) => axios.post<void>('/endinginventories/addinventoryitems', endInv)
}

const DiscrepancyReports = {
    list: (discrepancyReportListFilter: DiscrepancyReportListFilter) => requests.post<DiscrepancyReportDto[]>('/discrepancyreport', discrepancyReportListFilter),
    listitems: (discrepancyReportFilter: DiscrepancyReportFilter) => requests.post<DiscrepancyReportItemsDto[]>('/discrepancyreport/discrepancyreportsitems', discrepancyReportFilter),
    generateExcelReport: (discrepancyReportExcel: DiscrepancyReportExcel) => requests.postFile<File>('/discrepancyreport/generateexcel', discrepancyReportExcel,{responseType: 'arraybuffer'}),
    create: (disReprt: AddDiscrepancyReport) => axios.post<void>('/discrepancyreport/adddiscrepancyreportsitems', disReprt)
}

const IncomingStock = {
    create: (IncomingStock: IncomingStockFormValues) => axios.post<string>('/incomingstock', IncomingStock)
}

const UnitsOfMeasure = {
    list: (SearchText:string) => requests.get<Units[]>(`/unitsofmeasure?SearchText=${SearchText}`),
    baseList: () => requests.get<BaseUnits[]>('/unitsofmeasure/base'),
    detail: (id: string) => requests.get<Unit>(`/unitsofmeasure/${id}`),
    alt: (unitId: string, quantity: number) => requests.get<Unit>(`/unitsofmeasure/alt/${unitId}/${quantity}`),
    create: (item: Unit) => axios.post<string>('/unitsofmeasure', item),
    update: (item: Unit) => axios.put<string>(`/unitsofmeasure/${item.id}`, item),
    delete: (id: string) => axios.put<void>(`/unitsofmeasure/archive/${id}`)
}

const Profile = {
    getEmployee: () => requests.get<EmployeeProfile>('/profile/employee'),
    getEmployees: (params: URLSearchParams, SearchText:string) => requests.get<PaginatedResult<EmployeeProfiles[]>>(`/profile/employee/list?${params}&SearchText=${SearchText}`),
    createEmployee: (employeeProfileFormValues : EmployeeProfileFormValues) => axios.post<void>('/profile/create/employee', employeeProfileFormValues),
    updateEmployee: (employeeProfileFormValues : EmployeeProfileFormValues, id : string) => axios.put<void>(`/profile/edit/employee/${id}`, employeeProfileFormValues),
    approveEmployee: (employeeProfileFormValues : ApproveProfileFormValues, id : string) => axios.put<void>(`/profile/approve/employee/${id}`, employeeProfileFormValues),
    getFranchisee: () => requests.get<FranchiseeProfile>('/profile/franchisee'),
    getFranchisees: () => requests.get<FranchiseeProfiles[]>('/profile/franchisee/list'),
    createFranchisee: (franchiseeProfileFormValues : FranchiseeProfileFormValues) => axios.post<void>('/profile/create/franchisee', franchiseeProfileFormValues),
    updateFranchisee: (franchiseeProfileFormValues : FranchiseeProfileFormValues, id : string) => axios.put<void>(`/profile/edit/franchisee/${id}`, franchiseeProfileFormValues),
    approveFranchisee: (franchiseeProfileFormValues : ApproveProfileFormValues, id : string) => axios.put<void>(`/profile/approve/franchisee/${id}`, franchiseeProfileFormValues)
}

const Account = {
    getEmployeeNames: () => requests.get<Record<string, string>>("/accounts/employees/names"),
    current: () => requests.get<User>('/accounts'),
    login: (user: UserFormValues) => requests.post<User>('/accounts/login', user),
    register: (user: UserFormValues) => requests.post<User>('/accounts/register', user).catch(error => { throw error.response?.data; }),
    resetPassword: (request: PasswordResetRequestFormValues): Promise<void> =>
        requests.post('/accounts/reset-password', request),
    getPasswordResetRequests: (): Promise<PasswordResetRequest[]> =>
        requests.get('/accounts/reset-password-requests'),
    approvePasswordResetRequest: (id: string): Promise<void> =>
        requests.putWithoutBody(`/accounts/approve-reset-password/${id}`),
    // users: () => requests.get<UserList[]>('/user/userslist')
}

const agent = {
    Account,
    Profile,
    UnitsOfMeasure,
    Inventories,
    EndingInventories,
    DiscrepancyReports,
    IncomingStock,
    Branches,
    Categories,
    Classifications,
    Components,
    FinalPortions,
    Ingredients,
    Items,
    Locations,
    Menus,
    Permissions,
    Recipes,
    Repacks,
    Roles,
    Suppliers,
    FranchiseePurchaseOrderRequests,
    OrderRequests,
    RequestReleases,
    TurnOverRequests,
    RepackRequests,
    ProcessRequests,
    TransferRequests
}

export default agent;