import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link,
  TextField,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormik,
} from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../app/stores/store";
import { FormTextField } from "../../app/common/form/FormTextField";
import { history } from '../..';

interface Props {
  createAccount: () => void;
  forgotPassword: () => void;
}

export default observer(function Login({ createAccount, forgotPassword }: Props) {
  const { userStore } = useStore();

  var isLoading = false;

  return (
    <>
      <Card sx={{ paddingBottom: 2, width: 375, minWidth: 345 }}>
        <CardMedia
          component="img"
          height="140"
          image="https://file.rendit.io/n/dpUDfwax2auOgIQJAKvX.png"
          alt="banhmi kitchen"
        />
        <CardContent>
          <Typography variant="h5" component="div">
            Log In
          </Typography>
          <Typography
            sx={{ paddingBottom: 2 }}
            variant="body2"
            color="text.secondary"
          >
            Please enter your registered email and password.
          </Typography>

          <Formik
            initialValues={{ email: "", password: "", error: null }}
            onSubmit={(values, { setErrors }) =>
              userStore
                .login(values)
                .catch((error) =>
                  setErrors({ error: "Invalid email or password" })
                )
            }
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      label="Email"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      label="Password"
                      size="small"
                      type="password"
                      component={FormTextField}
                    />
                    <ErrorMessage
                      name="error"
                      render={() => (
                        <Typography sx={{ color: "red" }}>
                          {errors.error}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "black",
                          borderColor: "none",
                        },
                      }}
                    >
                      Log In
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
        <CardActions>
          <Link
            variant="body2"
            color="text.primary"
            component="button"
            onClick={createAccount}
            sx={{ paddingLeft: 1, marginLeft: 1 }}
          >
            Don't have an account? Create an account here.
          </Link>
          <Link
            variant="body2"
            color="text.primary"
            component="button"
            onClick={forgotPassword}
            sx={{ paddingLeft: 1, marginLeft: 1 }}
          >
            Forgot Password?
          </Link>
        </CardActions>
      </Card>
    </>
  );
});
