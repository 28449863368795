import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { sizeOptions } from "../../../app/common/options/sizeOptions";
import { useStore } from "../../../app/stores/store";

export default observer(function RepackDetail() {
  const { repackStore } = useStore();
  const { selectedRepack } = repackStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedRepack
          ? selectedRepack.name
          : "Select a repack from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedRepack
                ? "Repack Name: " + selectedRepack.name
                : "Repack Name: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRepack
                ? "Item: " + selectedRepack.item
                : "Item: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRepack
                ? "Component: " + selectedRepack.component
                : "Component: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRepack
                ? "Quantity: " + selectedRepack.componentQuantity
                : "Quantity: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRepack
                ? "Unit: " + selectedRepack.componentUnit
                : "Unit: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
