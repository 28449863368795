import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Branch, BranchFormValues, BranchOption } from "../../models/admin/branch";
import { Pagination, PagingParams } from "../../models/pagination";

export default class BranchStore {
    branchRegistry = new Map<string, Branch>();
    itemCount: number = 0;
    selectedBranch: Branch | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    branchOptions = new Map<string, BranchOption>();
    branches: Branch[] = [];
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this)
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getBranches = () => {
        return Array.from(this.branchRegistry.values()).sort((a: Branch, b: Branch) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    getBranchOptions = async () => {
        this.loadingInitial = true;
        let branchOptions = this.branchOptions;
        try {
            const result = await agent.Branches.options();
            result.forEach(branch => {
                let branchOption: BranchOption = {
                    value: branch.id,
                    label: branch.name
                }
                this.branchOptions.set(branch.id, branchOption);
            });
            return branchOptions;
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadBranch = async (id: string) => {
        this.loadingInitial = true;
        try {
            let branch = await agent.Branches.detail(id);
            this.setBranch(branch);
            runInAction(() => {
                this.selectedBranch = branch;
            })
            this.setLoadingInitial(false);
            return branch;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadBranches = async () => {
        this.loadingInitial = true;
        this.branchRegistry.clear();
        try {
            const result = await agent.Branches.list(this.axiosParams, "");
            result.data.forEach(branch => {
                this.setBranch(branch);
            })
            this.setPagination(result.pagination)
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchBranches = async (searchText: string) => {
        this.branchRegistry.clear();
        try {
            const result = await agent.Branches.list(this.axiosParams,searchText);
            result.data.forEach(branch => {
                this.setBranch(branch);
            })
            this.setPagination(result.pagination)
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    doesBranchExist = (id: string) => {
        let branch = this.branchRegistry.get(id);
        if(branch) return true;

        return false;
    }

    selectBranch = (id: string) => {
        this.selectedBranch = this.branchRegistry.get(id);
    }

    unSelectBranch = () => {
        this.selectedBranch = undefined;
    }

    private setBranch = (branch: Branch) => {
        this.branchRegistry.set(branch.id, branch);
    }

    private getBranch = (id: string) => {
        return this.branchRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createBranch = async (branch: BranchFormValues) => {
        this.loading = true;
        try {
            var branchId = (await agent.Branches.create(branch)).data;
            runInAction(async() => {
                this.loadBranch(branchId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateBranch = async (branch: BranchFormValues) => {
        this.loading = true;
        try {
            var branchId = (await agent.Branches.update(branch)).data;
            runInAction(() => {
                this.loadBranch(branchId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveBranch = async (branchid: string) => {
        this.loading = true;
        try {
            await agent.Branches.delete(branchid);
            runInAction(() => {
                this.loadBranch(branchid).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}