import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestErrors";
import Dashboard from "../../features/dashboard/Dashboard";
import HomePage from "../../features/home/HomePage";
import { useStore } from "../stores/store";
import LoadingComponent from "./LoadingComponent";
import NavBar from "./Navbar";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import ListAltIcon from "@mui/icons-material/ListAlt";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BentoIcon from "@mui/icons-material/Bento";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import PeopleIcon from "@mui/icons-material/People";
import SecurityIcon from "@mui/icons-material/Security";
import CategoryIcon from "@mui/icons-material/Category";
import MemoryIcon from "@mui/icons-material/Memory";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StoreIcon from "@mui/icons-material/Store";
import LockResetIcon from "@mui/icons-material/LockReset";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryIcon from "@mui/icons-material/Inventory";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CreateProfile from "../../features/profile/CreateProfile";
import ManageProfile from "../../features/profile/ManageProfile";
import ManageBranch from "../../features/admin/branch/ManageBranch";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import ManageCategory from "../../features/admin/category/ManageCategory";
import ManageClassification from "../../features/admin/classification/ManageClassification";
import ManageComponent from "../../features/admin/component/ManageComponent";
import ManageFinalPortion from "../../features/admin/finalPortion/ManageFinalPortion";
import ManageItem from "../../features/admin/item/ManageItem";
import ManageLocation from "../../features/admin/location/ManageLocation";
import ManageMenu from "../../features/admin/menu/ManageMenu";
import ManageSupplier from "../../features/admin/supplier/ManageSupplier";
import ClassIcon from "@mui/icons-material/Class";
import Inventory from "../../features/inventory/Inventory";
import EndingInventory from "../../features/inventory/endingInventory/EndingInventory";
import EndingInventoryList from "../../features/inventory/endingInventory/EndingInventoryList";
import DiscrepancyReport from "../../features/inventory/discrepancyReport/DiscrepancyReport";
import DiscrepancyReportList from "../../features/inventory/discrepancyReport/DiscrepancyReportList";
import IncomingStock from "../../features/inventory/incomingStock/IncomingStock";
import AddIncomingStock from "../../features/inventory/incomingStock/AddIncomingStock";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PurchaseOrderRequest from "../../features/request/franchiseePurchaseOrder/PurchaseOrderRequest";
import ViewPurchaseOrderRequest from "../../features/request/franchiseePurchaseOrder/ViewPurchaseOrderRequest";
import ApproveViewPurchaseOrder from "../../features/request/adminPurchaseOrder/ApproveViewPurchaseOrder";
import DispatchOrder from "../../features/request/dispatchOrder/DispatchViewPurchaseOrder";
import ManageRepack from "../../features/admin/repack/ManageRepack";
import ManageIngredient from "../../features/admin/ingredient/ManageIngredient";
import ManageRecipe from "../../features/admin/recipe/ManageRecipe";
import CreateRepackRequest from "../../features/request/repack/CreateRepackRequest";
import CreateTransferRequest from "../../features/request/transferRequest/CreateTransferRequest";
import ViewTransferRequest from "../../features/request/transferRequest/ViewTransferRequest";
import ManageRole from "../../features/admin/role/ManageRole";
import ManageUser from "../../features/admin/profile/ManageUser";
import ViewRepackRequest from "../../features/request/repack/ViewRepackRequest";
import ViewProcessRequest from "../../features/request/process/ViewProcessRequest";
import CreateProcessRequest from "../../features/request/process/CreateProcessRequest";
import CreatePurchaseOrderRequest from "../../features/request/franchiseePurchaseOrder/CreatePurchaseOrderRequest";
import ManageUnit from "../../features/admin/unit/ManageUnit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PasswordResetRequestList from "../../features/admin/user/PasswordResetRequestList";

const drawerWidth = 240;

function App() {
  const location = useLocation();
  const { commonStore, userStore, permissionStore } = useStore();
  const { getUser, isLoggedIn } = userStore;
  const { loadUserPermissions, myPermissionRegistry, loadingInitial } =
    permissionStore;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    document.title = "Banh Mi Kitchen";
    if (commonStore.token) {
      getUser()
        .then(() => loadUserPermissions())
        .finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, getUser, isLoggedIn, loadUserPermissions]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Divider />
      <List>
        {/* Requests*/}
        {/* purchase order*/}
        {myPermissionRegistry.has("50992d63-bdee-484f-8420-574a1f5da65b") ||
        myPermissionRegistry.has("af553ee8-dbb0-4df0-a66a-c8982c582280") ||
        myPermissionRegistry.has("569bd266-b3d3-41a1-99e4-dac28ff685c4") ||
        myPermissionRegistry.has("cab90d7c-a3b5-43e3-bc35-84a681bba519") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Purchase Order" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.has("50992d63-bdee-484f-8420-574a1f5da65b") ||
        myPermissionRegistry.has("af553ee8-dbb0-4df0-a66a-c8982c582280") ||
        myPermissionRegistry.has("569bd266-b3d3-41a1-99e4-dac28ff685c4") ||
        myPermissionRegistry.has("cab90d7c-a3b5-43e3-bc35-84a681bba519") ? (
          <ListItem key="PurchaseOrder" disablePadding>
            <ListItemButton component={NavLink} to="/purchase-order">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase Order" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("cab90d7c-a3b5-43e3-bc35-84a681bba519") ? (
          <ListItem key="PurchaseOrderHistory" disablePadding>
            <ListItemButton component={NavLink} to="/history/purchase-order">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="View Purchase Orders" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("af553ee8-dbb0-4df0-a66a-c8982c582280") ? (
          <ListItem key="ApproveViewPurchaseOrder" disablePadding>
            <ListItemButton component={NavLink} to="/approve/purchase-order">
              <ListItemIcon>
                <FactCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Approve Purchase Orders" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("50992d63-bdee-484f-8420-574a1f5da65b") ? (
          <ListItem key="DispatchOrder" disablePadding>
            <ListItemButton component={NavLink} to="/dispatch/purchase-order">
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Dispatch Orders" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* repack request*/}
        {myPermissionRegistry.has("b8919b74-3550-4e7e-ac38-502fd1a74ae4") ||
        myPermissionRegistry.has("213af6ae-3790-48ca-8a1e-e4a0ca600cb8") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Repacking" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("b8919b74-3550-4e7e-ac38-502fd1a74ae4") ? (
          <ListItem key="repack" disablePadding>
            <ListItemButton component={NavLink} to="/repack">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Repack" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("213af6ae-3790-48ca-8a1e-e4a0ca600cb8") ? (
          <ListItem key="repackHistory" disablePadding>
            <ListItemButton component={NavLink} to="/history/repack">
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="View Repack Requests" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* process request*/}
        {myPermissionRegistry.has("189b3f98-ff14-4db4-8676-6b5920f5fd30") ||
        myPermissionRegistry.has("6cd5e64f-cd05-45f5-b781-242c42e23ecb") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Processing" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("189b3f98-ff14-4db4-8676-6b5920f5fd30") ? (
          <ListItem key="process" disablePadding>
            <ListItemButton component={NavLink} to="/process">
              <ListItemIcon>
                <MicrowaveIcon />
              </ListItemIcon>
              <ListItemText primary="Process" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("6cd5e64f-cd05-45f5-b781-242c42e23ecb") ? (
          <ListItem key="processHistory" disablePadding>
            <ListItemButton component={NavLink} to="/history/process">
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="View Process Requests" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* transfer request*/}
        {myPermissionRegistry.has("0d6361f1-b80e-40c9-8aa8-31c76bf9ee7a") ||
        myPermissionRegistry.has("45f73627-562e-4e40-b728-548a5825a824") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Transfers" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("0d6361f1-b80e-40c9-8aa8-31c76bf9ee7a") ? (
          <ListItem key="transfer" disablePadding>
            <ListItemButton component={NavLink} to="/transfer">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Transfer" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("45f73627-562e-4e40-b728-548a5825a824") ? (
          <ListItem key="transferHistory" disablePadding>
            <ListItemButton component={NavLink} to="/history/transfer">
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="View Transfer Requests" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* Inventories*/}
        {myPermissionRegistry.has("3b499c05-f57c-46f8-854b-bf2d55e4c7b7") ||
        myPermissionRegistry.has("eec68669-efb6-4aaf-bb8a-1b2be9e0a0d0") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Inventories" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("3b499c05-f57c-46f8-854b-bf2d55e4c7b7") ? (
          <ListItem key="Inventory" disablePadding>
            <ListItemButton component={NavLink} to="/inventory">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Inventory" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("3b499c05-f57c-46f8-854b-bf2d55e4c7b7") ? (
          <ListItem key="EndingInventoryList" disablePadding>
            <ListItemButton component={NavLink} to="/endinginventorylist">
              <ListItemIcon>
                <FactCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Ending Inventory" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("3b499c05-f57c-46f8-854b-bf2d55e4c7b7") ? (
          <ListItem key="DiscrepancyReportList" disablePadding>
            <ListItemButton component={NavLink} to="/discrepancyreportlist">
              <ListItemIcon>
                <ReportProblemIcon />
              </ListItemIcon>
              <ListItemText primary="Discrepancy Report" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("eec68669-efb6-4aaf-bb8a-1b2be9e0a0d0") ? (
          <ListItem key="IncomingStock" disablePadding>
            <ListItemButton component={NavLink} to="/incomingstock">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Incoming Stock" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* manage users and roles */}
        {myPermissionRegistry.has("65f4bfe3-f91d-4c0c-a445-2b6d1c4282f6") ||
        myPermissionRegistry.has("3483a984-c2f5-452e-ac24-1c7f08bc815f") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Roles and Profiles" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("65f4bfe3-f91d-4c0c-a445-2b6d1c4282f6") ? (
          <ListItem key="ManageUsers" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-user">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Users" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("3483a984-c2f5-452e-ac24-1c7f08bc815f") ? (
          <ListItem key="ManageRoles" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-role">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Roles" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has(
          "65f4bfe3-f91d-4c0c-a445-2b6d1c4282f6"
        ) ? (
          <ListItem key="PasswordResetRequests" disablePadding>
            <ListItemButton
              component={NavLink}
              to="/admin/password-reset-requests"
            >
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText primary="Password Reset Requests" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* Formulas */}
        {myPermissionRegistry.has("94a195c4-8b29-4c60-ac94-67fdda19e200") ||
        myPermissionRegistry.has("ac3fda13-8e2c-4568-826a-2d8778deec7b") ||
        myPermissionRegistry.has("e7431e11-b8b8-4baf-8e8b-94a6ad73efa8") ||
        myPermissionRegistry.has("03193f21-c278-48d0-abbf-1a54e458ffb8") ||
        myPermissionRegistry.has("8e8980b6-65bf-4f1e-b963-84e9ce3fefea") ||
        myPermissionRegistry.has("f546ca71-dded-4a61-8f4a-4d45294dc5c3") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Formulas" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("94a195c4-8b29-4c60-ac94-67fdda19e200") ? (
          <ListItem key="Components" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-component">
              <ListItemIcon>
                <BakeryDiningIcon />
              </ListItemIcon>
              <ListItemText primary="Components" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("ac3fda13-8e2c-4568-826a-2d8778deec7b") ? (
          <ListItem key="Menus" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-menu">
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Menus" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("e7431e11-b8b8-4baf-8e8b-94a6ad73efa8") ? (
          <ListItem key="Ingredient" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-ingredient">
              <ListItemIcon>
                <KitchenIcon />
              </ListItemIcon>
              <ListItemText primary="Ingredient" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("03193f21-c278-48d0-abbf-1a54e458ffb8") ? (
          <ListItem key="Recipe" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-recipe">
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Recipe" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("8e8980b6-65bf-4f1e-b963-84e9ce3fefea") ? (
          <ListItem key="Repack" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-repack">
              <ListItemIcon>
                <BentoIcon />
              </ListItemIcon>
              <ListItemText primary="Repack" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("f546ca71-dded-4a61-8f4a-4d45294dc5c3") ? (
          <ListItem key="FinalPortion" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-finalportion">
              <ListItemIcon>
                <LunchDiningIcon />
              </ListItemIcon>
              <ListItemText primary="Final Portion" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* Vendors */}
        {myPermissionRegistry.has("ef9681c4-fbf4-4348-b988-e609d7d346c6") ||
        myPermissionRegistry.has("5dd5e727-967e-4a4f-a84f-e58dbe7559b6") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="Vendors" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("5dd5e727-967e-4a4f-a84f-e58dbe7559b6") ? (
          <ListItem key="Suppliers" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-supplier">
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Suppliers" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("ef9681c4-fbf4-4348-b988-e609d7d346c6") ? (
          <ListItem key="Branches" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-branch">
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="Branches" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {/* General */}
        {myPermissionRegistry.has("ecd9cf63-1a7f-4cbd-bd09-e558f5901e2c") ||
        myPermissionRegistry.has("09b0c792-3b07-4b7d-8ecf-41ad0f7863d7") ||
        myPermissionRegistry.has("ef2912de-de36-4f2d-9541-da338b7f1652") ||
        myPermissionRegistry.has("a0f5efd7-b0fa-4968-8b29-7fae56f6175b") ? (
          <ListItem sx={{ backgroundColor: "#EFEFEF" }}>
            <ListItemText primary="General" />
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("ecd9cf63-1a7f-4cbd-bd09-e558f5901e2c") ? (
          <ListItem key="Items" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-item">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Items" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("09b0c792-3b07-4b7d-8ecf-41ad0f7863d7") ? (
          <ListItem key="Locations" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-location">
              <ListItemIcon>
                <WarehouseIcon />
              </ListItemIcon>
              <ListItemText primary="Location" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("ef2912de-de36-4f2d-9541-da338b7f1652") ? (
          <ListItem key="Categories" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-category">
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("a0f5efd7-b0fa-4968-8b29-7fae56f6175b") ? (
          <ListItem key="Classifications" disablePadding>
            <ListItemButton
              component={NavLink}
              to="/admin/manage-classification"
            >
              <ListItemIcon>
                <ClassIcon />
              </ListItemIcon>
              <ListItemText primary="Classifications" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}

        {myPermissionRegistry.size > 0 &&
        myPermissionRegistry.has("12da11a6-2217-4273-8d4b-03608d61aee6") ? (
          <ListItem key="Units" disablePadding>
            <ListItemButton component={NavLink} to="/admin/manage-unit">
              <ListItemIcon>
                <DeviceThermostatIcon />
              </ListItemIcon>
              <ListItemText primary="Units" />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
      </List>
    </div>
  );

  if (!commonStore.appLoaded)
    return <LoadingComponent content="Loading app..." />;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastContainer position="bottom-right" hideProgressBar />
        <Route exact path="/" component={HomePage} />
        <Route
          path={"/(.+)"}
          render={() => (
            <>
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <NavBar
                  drawerWidth={drawerWidth}
                  handleDrawerToggle={handleDrawerToggle}
                />
                <Box
                  component="nav"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                  aria-label="inventory features"
                >
                  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                  <Drawer
                    PaperProps={{ style: { height: "90.5vh" } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: "block", sm: "none" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        marginTop: 7,
                      },
                    }}
                  >
                    {drawer}
                  </Drawer>
                  <Drawer
                    PaperProps={{ style: { height: "93vh" } }}
                    variant="permanent"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        marginTop: 8,
                      },
                    }}
                    open
                  >
                    {drawer}
                  </Drawer>
                </Box>
                {/* for the main content */}
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                  }}
                >
                  <Toolbar />
                  <Switch>
                    <Route
                      exact
                      path="/create-profile"
                      component={CreateProfile}
                    />
                    <Route
                      exact
                      path="/manage-profile"
                      component={ManageProfile}
                    />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/inventory" component={Inventory} />
                    <Route
                      exact
                      path="/endinginventory"
                      component={EndingInventory}
                    />
                    <Route
                      exact
                      path="/endinginventorylist"
                      component={EndingInventoryList}
                    />
                    <Route
                      exact
                      path="/discrepancyreport"
                      component={DiscrepancyReport}
                    />
                    <Route
                      exact
                      path="/discrepancyreportlist"
                      component={DiscrepancyReportList}
                    />
                    <Route
                      exact
                      path="/incomingstock"
                      component={AddIncomingStock}
                    />
                    <Route
                      exact
                      path="/admin/manage-branch"
                      component={ManageBranch}
                    />
                    <Route
                      exact
                      path="/admin/manage-category"
                      component={ManageCategory}
                    />
                    <Route
                      exact
                      path="/admin/manage-classification"
                      component={ManageClassification}
                    />
                    <Route
                      exact
                      path="/admin/manage-component"
                      component={ManageComponent}
                    />
                    <Route
                      exact
                      path="/admin/manage-finalportion"
                      component={ManageFinalPortion}
                    />
                    <Route
                      exact
                      path="/admin/manage-ingredient"
                      component={ManageIngredient}
                    />
                    <Route
                      exact
                      path="/admin/manage-item"
                      component={ManageItem}
                    />
                    <Route
                      exact
                      path="/admin/manage-location"
                      component={ManageLocation}
                    />
                    <Route
                      exact
                      path="/admin/manage-menu"
                      component={ManageMenu}
                    />
                    <Route
                      exact
                      path="/admin/manage-recipe"
                      component={ManageRecipe}
                    />
                    <Route
                      exact
                      path="/admin/manage-repack"
                      component={ManageRepack}
                    />
                    <Route
                      exact
                      path="/admin/manage-role"
                      component={ManageRole}
                    />
                    <Route
                      exact
                      path="/admin/manage-supplier"
                      component={ManageSupplier}
                    />
                    <Route
                      exact
                      path="/admin/manage-user"
                      component={ManageUser}
                    />
                    <Route
                      exact
                      path="/admin/password-reset-requests"
                      component={PasswordResetRequestList}
                    />
                    <Route
                      exact
                      path="/admin/manage-unit"
                      component={ManageUnit}
                    />
                    <Route
                      exact
                      path="/purchase-order"
                      component={CreatePurchaseOrderRequest}
                    />
                    <Route
                      exact
                      path="/history/purchase-order"
                      component={ViewPurchaseOrderRequest}
                    />
                    <Route
                      exact
                      path="/approve/purchase-order"
                      component={ApproveViewPurchaseOrder}
                    />
                    <Route
                      exact
                      path="/dispatch/purchase-order"
                      component={DispatchOrder}
                    />
                    <Route
                      exact
                      path="/repack"
                      component={CreateRepackRequest}
                    />
                    <Route
                      exact
                      path="/history/repack"
                      component={ViewRepackRequest}
                    />
                    <Route
                      exact
                      path="/process"
                      component={CreateProcessRequest}
                    />
                    <Route
                      exact
                      path="/history/process"
                      component={ViewProcessRequest}
                    />
                    <Route
                      exact
                      path="/transfer"
                      component={CreateTransferRequest}
                    />
                    <Route
                      exact
                      path="/history/transfer"
                      component={ViewTransferRequest}
                    />
                    <Route path="/errors" component={TestErrors} />
                    <Route path="/server-error" component={ServerError} />
                    <Route path="/not-found" component={NotFound} />
                  </Switch>
                </Box>
              </Box>
            </>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default observer(App);
