import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { useStore } from "../../../app/stores/store";
  import React from "react";
  import DispatchList from "./DispatchList";
  import DispatchDetail from "./DispatchDetail";
  export default observer(function DispatchViewPurchaseOrder() {
    const { requestReleasestore } = useStore();
    const { selectedRequestRelease } = requestReleasestore;
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ padding: 1 }} variant="h3">
            Dispatch Order
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container direction="row" spacing={0}>
            <Grid item xs={0} md={0} lg={2}></Grid>
            <Grid item xs={12} md={12} lg={8}>
              {selectedRequestRelease ? (
                  <DispatchDetail />
                ) : (
                  <DispatchList />
                )}
            </Grid>
            <Grid item xs={0} md={0} lg={2}></Grid>
          </Grid>
        </Box>
      </>
    );
  });
  