import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { TransferRequestFormValues } from "../../../app/models/requests/transferRequest";
import SelectItemsToTransfer from "./SelectItemsToTransfer";
import { departmentOptions } from "../../../app/common/options/departmentOptions";

export default observer(function CreateTransferRequest() {
  const { transferRequestStore, itemStore, unitStore } = useStore();
  const { createMultipleTransferRequest, multipleTransferFormValues } = transferRequestStore;
  const { loadItems, itemRegistry } = itemStore;
  const { loadUnits, unitRegistry } = unitStore;
  const [multipleMode, setMultipleMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    loadItems();
    loadUnits();
  }, [loadItems, loadUnits]);

  const getItemName = (itemId: string) => {
    return itemRegistry.get(itemId)?.name || "";
  };

  const getUnitName = (unitId: string) => {
    const unit = Array.from(unitRegistry.values()).find((u) => u.id === unitId);
    return unit?.name || "";
  };

  const getDepartmentName = (departmentId: number) => {
    return departmentOptions.find((option) => option.value === departmentId)?.label || "";
  };

  const handleToggleMode = () => {
    setMultipleMode(!multipleMode);
  };

  const handleSubmitMultiple = () => {
    setOpenDialog(true);
  };

  const handleConfirmSubmit = () => {
    createMultipleTransferRequest(multipleTransferFormValues);
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Create Transfer Request
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          This is where we can create a transfer request
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Button onClick={handleToggleMode}>
              {multipleMode
                ? "Single Transfer Request"
                : "Multiple Transfer Requests"}
            </Button>
            <SelectItemsToTransfer multipleMode={multipleMode} />
            {multipleMode && (
              <Button onClick={handleSubmitMultiple}>
                Submit Multiple Transfer Requests
              </Button>
            )}
          </Grid>
          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Transfer Requests</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The following items will be submitted for transfer:
          </Typography>
          <List>
            {multipleTransferFormValues.map((item) => (
              <ListItem key={item.itemId}>
                <ListItemText
                  primary={`Item: ${getItemName(item.itemId)} from ${getDepartmentName(item.providingDepartment)}`}
                  secondary={`Quantity: ${item.inventoryToTransferQuantity}, Unit: ${getUnitName(item.unitId)}, receiving Department: ${getDepartmentName(item.receivingDepartment)}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmSubmit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
