import { format } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { departmentOptions } from "../../common/options/departmentOptions";
import { EndingInventory, EndingInventoryDto,EndingInventoryListFilter, EndingInventoryItems, EndingInventoryItemsDto, EndingInventoryFilter, DiscrepancyReportItems, AddEndingInventory } from "../../models/Inventories/inventory";

export default class EndingInventoryStore {
    selectedItem: DiscrepancyReportItems[] | undefined = undefined;
    endingInventoryItemsFiter: EndingInventoryFilter | undefined = undefined;
    endingInventoryRegistry = new Map<string, EndingInventory>();
    endingInventoryItemsRegistry = new Map<string, EndingInventoryItems>();
    loading = false;
    loadingInitial = true;
    hasSetFilter = false;
    InitialDate="";
    InitialId="";
    IsView=false;
    constructor() {
        makeAutoObservable(this);
    }

    setInitialId = (InitialId: string) => {
        this.InitialId = InitialId;
    }

    getInitialId = () => {
        return this.InitialId;
    }

    setIsView = (IsView: boolean) => {
        this.IsView = IsView;
    }

    getIsView = () => {
        return this.IsView;
    }

    
    setInitialDate = (initDate: string) => {
        this.InitialDate = initDate;
    }

    getInitialDate = () => {
        return this.InitialDate;
    }

    setHasSetFilter = (filter: boolean) => {
        this.hasSetFilter = filter;
    }

    getHasSetFilter = () => {
        return this.hasSetFilter;
    }

    setEndingInventoryItemsFiter = async (filter: EndingInventoryFilter) => {
        const user = await agent.Account.current();
        filter.departmentId=user.department;
        this.endingInventoryItemsFiter = filter;
    }

    getEndingInventoryItemsFiter = () => {
        return this.endingInventoryItemsFiter;
    }

    selectItem = (items: DiscrepancyReportItems[]) => {
        this.selectedItem = items;
    }

    getSelectedItem = () => {
        return this.selectedItem;
    }

    getEndingInventoryItems = () => {
        return Array.from(this.endingInventoryItemsRegistry.values());
    }

    loadEndingInventoryItems = async (endingInventoryFilter: EndingInventoryFilter) => {
        this.endingInventoryItemsRegistry.clear();
        const user = await agent.Account.current();
        endingInventoryFilter.departmentId=user.department;
        this.loadingInitial = true;
        try {
            const inventory = await agent.EndingInventories.listitems(endingInventoryFilter);
            inventory.forEach(item => {
                this.setInventoryItem(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    generateExcelReport = async (endingInventoryFilter: EndingInventoryFilter) => {
        const user = await agent.Account.current();
        endingInventoryFilter.departmentId=user.department;
        try {
            const inventory = await agent.EndingInventories.generateExcelReport(endingInventoryFilter);
            const outputFilename = `EndingInvetortReport-${Date.now()}.xlsx`;
            const url = URL.createObjectURL(new Blob([inventory]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    private setInventoryItem = (inventoryDto: EndingInventoryItemsDto) => {
        let inventoryItem: EndingInventoryItems = {
            id: inventoryDto.id,
            inventoryItemId: inventoryDto.inventoryItemId,
            itemName: inventoryDto.itemName,
            category: inventoryDto.category,
            department: inventoryDto.department,
            departmentName: departmentOptions[inventoryDto.department].label,
            supplierName: inventoryDto.supplierName,
            brand: inventoryDto.brand,
            unitOfMeasure: inventoryDto.unitOfMeasure,
            locationName: inventoryDto.locationName,
            startingQuantity: inventoryDto.startingQuantity,
            addedQuantity: inventoryDto.addedQuantity,
            dispatchedQuantity: inventoryDto.dispatchedQuantity,
            wasteYielding: inventoryDto.wasteYielding,
            endingQuantity: inventoryDto.endingQuantity,
            expectedEndingQty:inventoryDto.expectedEndingQty
        };
        this.endingInventoryItemsRegistry.set(inventoryItem.id, inventoryItem);
    }

    getEndingInventory = () => {
        return Array.from(this.endingInventoryRegistry.values());
    }

    loadEndingInventory= async () => {
        this.endingInventoryRegistry.clear();
        this.loadingInitial = true;
        const user = await agent.Account.current();
        let endInvFilter: EndingInventoryListFilter = {
            departmentId:user.department
          };
        try {
            const inventory = await agent.EndingInventories.list(endInvFilter);
            inventory.forEach(item => {
                this.setInventory(item);
            })
            runInAction(() => {
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    private setInventory = (inventoryDto: EndingInventoryDto) => {
        let inventory: EndingInventory = {
            id:inventoryDto.id,
            name: inventoryDto.name,
            endingInventoryDate: inventoryDto.endingInventoryDate,
            createdBy: inventoryDto.createdBy,
        };
        this.endingInventoryRegistry.set(inventory.id, inventory);
    }

    createEndingInventory = async (addEndingInventory: AddEndingInventory) => {
        const user = await agent.Account.current();
        addEndingInventory.departmentName=user.departmentName;
        addEndingInventory.departmentId=user.department;
        this.loading = true;
        try {
            await agent.EndingInventories.create(addEndingInventory);
            runInAction(() => {
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }
}