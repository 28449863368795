import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useStore } from "../../../app/stores/store";
import ComponentList from "./ComponentList";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ComponentForm from "./ComponentForm";
import ComponentDetail from "./ComponentDetail";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default observer(function ManageComponent() {
  const { componentStore } = useStore();
  const {
    archiveComponent,
    loadComponents,
    searchComponents,
    componentRegistry,
    loadingInitial,
    unSelectComponent,
    selectedComponent,
    pagination,
    pagingParams,
  } = componentStore;
  const [add, setAdd] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [archive, setArchive] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [searchName, setSearchName] = React.useState<string>();

  useEffect(() => {
    handlePaginationInit();
    if (componentRegistry.size <= 0) loadComponents();
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems,componentRegistry]);

  const handleAdd = () => {
    unSelectComponent();
    setAdd(true);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };
  const handleUpdate = () => {
    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };

  const handleArchive = (id: string) => {
    if (selectedComponent != null) {
      selectedComponent.id = id;
    }
    setArchive(true);
  };

  const handleCloseArchive = () => {
    setArchive(false);
  };

  const handleSaveArchive = (id: string) => {
    setArchive(false);
    archiveComponent(id);
  };

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    if(searchName!= null){
      searchComponents(searchName);
    }else{
      loadComponents();
    }
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    if(searchName!= null){
      searchComponents(searchName);
    }else{
      loadComponents();
    }
  };
  
  const handleSearch = () => {
    if(searchName!= null){
      searchComponents(searchName);
    }else{
      loadComponents();
    }
  };

  if (loadingInitial)
    return <LoadingComponent content="Loading Components..." />;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Components
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          View all components, add and update a component
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} md={4}>
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Components
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAdd}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        Add Component
                      </Button>
                      <Dialog open={add}>
                        <DialogTitle>Add Component</DialogTitle>
                        <DialogContent>
                          {/* input fields */}
                          <ComponentForm closeForm={handleCloseAdd} />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Stack>
                  <Box sx={{ width: "100%", marginBottom: "20px", paddingX:"10px" }}>
                    <Paper
                      component="form"
                      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100% " }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Component Name..."
                        inputProps={{ "aria-label": "Search Component Name" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchName(event.target.value);
                        }}
                      />
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    {/* list component here */}
                    <ComponentList />
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        onClick={handlePrevClick}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "orange",
                          "&:hover": {
                            backgroundColor: "orange",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "orange",
                            borderColor: "none",
                          },
                          display: currentPage == 1 ? "none" : "block",
                        }}
                      >
                        Previous
                      </Button>
                    </Box>
                    <Typography
                      sx={{ padding: 1 }}
                      variant="h6"
                      color="text.secondary"
                    >
                      Page {currentPage} of{" "}
                      {totalItems != 0 ? Math.ceil(totalItems / pageSize) : 1}
                    </Typography>
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        onClick={handleNextClick}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "orange",
                          "&:hover": {
                            backgroundColor: "orange",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "orange",
                            borderColor: "none",
                          },
                          display:
                            currentPage == Math.ceil(totalItems / pageSize)
                              ? "none"
                              : totalItems != 0
                              ? "block"
                              : "none",
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Component Details
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Stack direction="row" spacing={1}>
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={() =>
                            selectedComponent != null
                              ? handleArchive(selectedComponent.id)
                              : null
                          }
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          disabled={selectedComponent == null}
                          sx={{
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "black",
                              borderColor: "none",
                            },
                          }}
                        >
                          Delete Component
                        </Button>
                        <Button
                          startIcon={<EditIcon />}
                          onClick={handleUpdate}
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          disabled={selectedComponent == null}
                          sx={{
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "black",
                              borderColor: "none",
                            },
                          }}
                        >
                          Update Component
                        </Button>
                      </Stack>
                      <Dialog open={update}>
                        <DialogTitle>Update Component</DialogTitle>
                        <DialogContent>
                          <ComponentForm closeForm={handleCloseUpdate} />
                        </DialogContent>
                      </Dialog>
                      <Dialog open={archive}>
                        <DialogTitle>Archive Component</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to archive this component?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseArchive}
                            variant="contained"
                            size="small"
                            color="primary"
                            disableElevation
                            sx={{
                              backgroundColor: "black",
                              "&:hover": {
                                backgroundColor: "black",
                                borderColor: "none",
                                boxShadow: "none",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "black",
                                borderColor: "none",
                              },
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() =>
                              selectedComponent != null
                                ? handleSaveArchive(selectedComponent.id)
                                : null
                            }
                            variant="contained"
                            size="small"
                            color="primary"
                            disableElevation
                            sx={{
                              backgroundColor: "black",
                              "&:hover": {
                                backgroundColor: "black",
                                borderColor: "none",
                                boxShadow: "none",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "black",
                                borderColor: "none",
                              },
                            }}
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Stack>

                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    {/* details component here */}
                    <ComponentDetail />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
