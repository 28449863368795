import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Supplier, SupplierFormValues, SupplierOption } from "../../models/admin/suppliers";
import { Pagination, PagingParams } from "../../models/pagination";

export default class SupplierStore {
    supplierRegistry = new Map<string, Supplier>();
    supplierOptions = new Map<string, SupplierOption>();
    itemCount: number = 0;
    selectedSupplier: Supplier | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getSuppliers = () => {
        return Array.from(this.supplierRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    //temporary implementation
    getSupplierOptions = async () => {
        this.loadingInitial = true;
        let supplierOptions = this.supplierOptions;
        try {
            const result = await agent.Suppliers.options();
            result.forEach(supplier => {
                let supplierOption: SupplierOption = {
                    value: supplier.id,
                    label: supplier.name
                }
                this.supplierOptions.set(supplier.id, supplierOption);
            });
            this.setLoadingInitial(false);
            return supplierOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadSupplier = async (id: string) => {
        this.loadingInitial = true;
        try {
            let supplier = await agent.Suppliers.detail(id);
            this.setSupplier(supplier);
            runInAction(() => {
                this.selectedSupplier = supplier;
            })
            this.setLoadingInitial(false);
            return supplier;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadSuppliers = async () => {
        this.loadingInitial = true;
        this.supplierRegistry.clear();
        try {
            const result = await agent.Suppliers.list(this.axiosParams, "");
            result.data.forEach(supplier => {
                this.setSupplier(supplier);
                let supplierOption: SupplierOption = {
                    value: supplier.id,
                    label: supplier.name
                }
                this.setSupplierOption(supplierOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchSuppliers = async (searchText: string) => {
        this.supplierRegistry.clear();
        try {
            const result = await agent.Suppliers.list(this.axiosParams,searchText);
            result.data.forEach(supplier => {
                this.setSupplier(supplier);
                let supplierOption: SupplierOption = {
                    value: supplier.id,
                    label: supplier.name
                }
                this.setSupplierOption(supplierOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    selectSupplier = (id: string) => {
        this.selectedSupplier = this.supplierRegistry.get(id);
    }

    unSelectSupplier = () => {
        this.selectedSupplier = undefined;
    }

    private setSupplier = (supplier: Supplier) => {
        this.supplierRegistry.set(supplier.id, supplier);
    }

    private setSupplierOption = (supplierOption: SupplierOption) => {
        this.supplierOptions.set(supplierOption.value, supplierOption);
    }

    private getSupplier = (id: string) => {
        return this.supplierRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createSupplier = async (supplier: SupplierFormValues) => {
        this.loading = true;
        try {
            var supplierId = (await agent.Suppliers.create(supplier)).data;
            runInAction(async() => {
                this.loadSupplier(supplierId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateSupplier = async (supplier: SupplierFormValues) => {
        this.loading = true;
        try {
            var supplierId = (await agent.Suppliers.update(supplier)).data;
            runInAction(async() => {
                this.loadSupplier(supplierId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveSupplier = async (supplierId: string) => {
        this.loading = true;
        try {
            await agent.Suppliers.delete(supplierId);
            runInAction(async() => {
                this.loadSuppliers().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}