import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import ImageIcon from "@mui/icons-material/Image";
import { format } from "date-fns";
import { departmentOptions } from "../../../app/common/options/departmentOptions";

export default observer(function LocationDetail() {
  const { locationStore } = useStore();
  const { selectedLocation } = locationStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1 }}>
        {selectedLocation
          ? selectedLocation.name
          : "Select a location from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Typography variant="subtitle1">
              {selectedLocation
                ? "Description: " + selectedLocation.description
                : "Description: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedLocation
                ? "Department: " + departmentOptions[selectedLocation.department].label
                : "Department: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedLocation
                ? "Last updated: " + format(selectedLocation.lastUpdated!, 'dd MMM yyyy')
                : "Last updated: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedLocation
                ? "Last updater: " + selectedLocation.lastUpdater
                : "Last updater: "}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">Location Photo:</Typography>
            <Avatar
              alt={selectedLocation?.name}
              src={selectedLocation?.photo}
              variant="square"
              sx={{ width: 250, height: 150 }}
            >
              <ImageIcon sx={{ width: 250, height: 150 }} />{" "}
            </Avatar>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
