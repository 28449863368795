import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FranchiseePurchaseOrderRequestList, FranchiseePurchaseOrderRequest,ApprovedPOFormValues,CompletePOFormValues,RestockPurchaseRequestFormValues} from "../../models/requests/franchiseePurchaseOrderRequest";
import { Pagination, PagingParams } from "../../models/pagination";

export default class AdminOrderRequestStore {
    adminOrderRequestRegistry = new Map<string, FranchiseePurchaseOrderRequestList>();
    selectedAdminOrderRequest: FranchiseePurchaseOrderRequest | undefined = undefined;
    selectedAdminPurchaseOrder: string | undefined = undefined;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getAdminOrderRequests = () => {
        return Array.from(this.adminOrderRequestRegistry.values());
    }

    loadAdminOrderRequests = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.FranchiseePurchaseOrderRequests.list(new URLSearchParams(), true);
            result.data.forEach((adminOrderRequest: FranchiseePurchaseOrderRequestList) => {
                this.setAdminOrderRequest(adminOrderRequest);
            });
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    

    private setAdminOrderRequest = (adminPurchaseOrderRequest: FranchiseePurchaseOrderRequestList) => {
        this.adminOrderRequestRegistry.set(adminPurchaseOrderRequest.id, adminPurchaseOrderRequest);
    }

    loadAdminOrderRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                this.selectedAdminOrderRequest=undefined;
                var franchiseeOrderRequest = await agent.FranchiseePurchaseOrderRequests.detail(id);
                runInAction(() => {
                    this.selectedAdminOrderRequest = franchiseeOrderRequest;
                })
                this.setLoadingInitial(false);
                return franchiseeOrderRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadProcessOrderDetails =  async (id: string) => {
            try {
                runInAction(() => {
                    this.selectedAdminPurchaseOrder = id;
                })
                return "";
            } catch (error) {
            }
    }

    unSelectProcessOrder= () => {
        this.selectedAdminPurchaseOrder = undefined;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    approvedPurchaseOrder = async (approvedPOFormValues: ApprovedPOFormValues) => {
        this.loading = true;
        try {
            var processRequestId = (await agent.FranchiseePurchaseOrderRequests.approve(approvedPOFormValues));
            runInAction(async() => {
                this.loading = false;
                this.selectedAdminPurchaseOrder = undefined;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    completePurchaseOrder = async (completePOFormValues: CompletePOFormValues) => {
        this.loading = true;
        try {
            var processRequestId = (await agent.FranchiseePurchaseOrderRequests.complete(completePOFormValues));
            runInAction(async() => {
                this.loading = false;
                this.selectedAdminPurchaseOrder = undefined;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    restockPurchaseRequest = async (restockPurchaseRequestFormValues: RestockPurchaseRequestFormValues) => {
        this.loading = true;
        try {
            await agent.FranchiseePurchaseOrderRequests.restock(restockPurchaseRequestFormValues);
            runInAction(async() => {
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    
}