import { Card, CardActionArea, CardHeader, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function IngredientList() {
  const { ingredientStore } = useStore();
  const { getIngredients, loadIngredient } = ingredientStore;

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getIngredients().map((Ingredient) => (
          <Card key={Ingredient.id} onClick={() => loadIngredient(Ingredient.id)}>
            <CardActionArea>
              <CardHeader
                title={Ingredient.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
});
