import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { ItemsOption } from "../../../app/models/admin/items";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { MenusOption } from "../../../app/models/admin/menus";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { FormMultiSelect } from "../../../app/common/form/FormMultiSelect";
import {
  PermissionOption,
  RoleFormValues,
} from "../../../app/models/admin/role";

interface Props {
  closeForm: () => void;
}

export default observer(function RoleForm({ closeForm }: Props) {
  const { roleStore, permissionStore, menuStore } = useStore();
  const { selectedRole, createRole, updateRole, loadingInitial } = roleStore;
  const { getPermissionOptions } = permissionStore;
  const [title, setTitle] = useState<string>("Create Role");
  const [permissionOptions, setPermissionOptions] = useState<
    PermissionOption[]
  >([]);
  const [hasNullDepartment, setHasNullDepartment] = useState(false);
  const [roleFormValues, setRoleForm] = useState<RoleFormValues>({
    id: "",
    name: "",
    department: 0,
    permissionIds: [],
  });
  let defaultPermissionOptions: PermissionOption[] = [];

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedRole) {
      setTitle("Update Role");
      let formData: RoleFormValues = {
        id: selectedRole.id,
        name: selectedRole.name,
        department: selectedRole.department,
        permissionIds: [],
      };

      if (formData.department === null) setHasNullDepartment(true);
      setRoleForm(formData);
    }

    getPermissionOptions().then((permOptions) => {
      let pOptions: PermissionOption[] = [];
      permOptions!.forEach((option) => {
        let pOption: PermissionOption = {
          value: option.value,
          label: option.label,
        };
        pOptions.push(pOption);
      });
      pOptions.sort((a, b) => a.label.localeCompare(b.label));
      setPermissionOptions(pOptions);

      selectedRole!.permissions!.forEach((permission) => {
        var permissionOption = pOptions.find((permOption) => {
          return permOption.value === permission.id
        });

        if(permissionOption) defaultPermissionOptions.push(permissionOption);
      });
    });
  }, [selectedRole, getPermissionOptions]);

  function handleFormSubmit(roleFormValues: RoleFormValues) {
    let role: RoleFormValues = {
      ...roleFormValues,
    };
    if (hasNullDepartment) role.department = null;
    defaultPermissionOptions.forEach((permission) => {
      role.permissionIds!.push(permission.value);
    });

    console.log(role);
    if (!selectedRole) {
      createRole(role).then(() => closeForm());
    } else {
      updateRole(role).then(() => closeForm());
    }
  }

  if (loadingInitial)
    return <LoadingComponent content="Loading Role..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={roleFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in Role information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Role Name"
                      department="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel component="legend">
                      Role has No department
                    </FormLabel>
                    <Switch
                      name="isActive"
                      checked={hasNullDepartment}
                      onChange={(event, checked) => {
                        setHasNullDepartment(checked ? true : false);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="department"
                      label="Department"
                      department="small"
                      options={departmentOptions}
                      disabled={hasNullDepartment}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      limitTags={5}
                      id="tags-outlined"
                      options={permissionOptions}
                      getOptionLabel={(option: PermissionOption) =>
                        option.label
                      }
                      defaultValue={defaultPermissionOptions!}
                      filterSelectedOptions
                      disableCloseOnSelect
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, value) => {
                        defaultPermissionOptions = value
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Permissions"
                          sx={{
                            backgroundColor: "white",
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "gray",
                            }, //styles the label
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": { borderColor: "gray" },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
