import { makeAutoObservable, runInAction } from "mobx";
import { number } from "yup";
import agent from "../api/agent";
import { AltUnitForm, BaseUnitsOption, Unit, UnitsOption,Units } from "../models/units";

export default class UnitStore {
    unitRegistry = new Map<string, Units>();
    loading = false;
    loadingInitial = true;
    unitOptions = new Map<string, UnitsOption>();
    baseUnitOptions = new Map<string, BaseUnitsOption>();
    selectedUnit: Unit | undefined = undefined;
    selectedCompUnit: Unit | undefined = undefined;
    selectedUnitManage: Unit | undefined = undefined;

    constructor() {
        makeAutoObservable(this)
    }

    getUnitOptions = async () => {
        this.loadingInitial = true;
        let unitOptions = this.unitOptions;
        try {
            const units = await agent.UnitsOfMeasure.list("");
            units.forEach(unit => {
                let unitOption: UnitsOption = {
                    value: unit.id,
                    label: unit.name
                }
                this.unitOptions.set(unit.id, unitOption);
            });
            this.setLoadingInitial(false);
            return unitOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getUnits = () => {
        return Array.from(this.unitRegistry.values()).sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.name.localeCompare(b.name));
    }

    unSelectItem = () => {
        this.selectedUnitManage = undefined;
    }

    loadUnit = async (id: string) => {
        this.loadingInitial = true;
            try {
                var item = await agent.UnitsOfMeasure.detail(id);
                this.setUnit(item);
                runInAction(() => {
                    this.selectedUnitManage = item;
                })
                this.setLoadingInitial(false);
                return item;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadUnits = async () => {
        this.unitRegistry.clear();
        try {
            const result = await agent.UnitsOfMeasure.list("");
            result.forEach(item => {
                this.setUnit(item);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    searchUnits = async (searchText: string) => {
        this.unitRegistry.clear();
        try {
            const result = await agent.UnitsOfMeasure.list(searchText);
            result.forEach(item => {
                this.setUnit(item);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setUnit = (unit: Units) => {
        this.unitRegistry.set(unit.id, unit);
    }

    getBaseUnitOptions = async () => {
        this.loadingInitial = true;
        let unitOptions = this.baseUnitOptions;
        try {
            const units = await agent.UnitsOfMeasure.baseList();
            units.forEach(unit => {
                let baseUnitOption: BaseUnitsOption = {
                    value: unit.id,
                    label: unit.name
                }
                this.baseUnitOptions.set(unit.id, baseUnitOption);
            });
            this.setLoadingInitial(false);
            return unitOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadUnitOfMeasure =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var unit = await agent.UnitsOfMeasure.detail(id);
                runInAction(() => {
                    this.selectedUnit = unit;
                    this.loadingInitial = false;
                })
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadComponentUnit =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var unit = await agent.UnitsOfMeasure.detail(id);
                runInAction(() => {
                    this.selectedCompUnit = unit;
                    this.loadingInitial = false;
                })
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadAltUnit =  async (id: string, quantity: number) => {
        this.loadingInitial = true;
            try {
                var unit = await agent.UnitsOfMeasure.alt(id, quantity);
                runInAction(() => {
                    this.loadingInitial = false;
                })
                return unit;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createUnit = async (unit: Unit) => {
        this.loading = true;
        try {
            var unitId = (await agent.UnitsOfMeasure.create(unit)).data;
            runInAction(async() => {
                this.loadUnit(unitId).then(() => {
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateUnit = async (unit: Unit) => {
        this.loading = true;
        try {
            var unitId = (await agent.UnitsOfMeasure.update(unit)).data;
            runInAction(async() => {
                this.loadUnit(unitId).then(() => {
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
    
    archiveUnit = async (unitId: string) => {
        this.loading = true;
        try {
            await agent.UnitsOfMeasure.delete(unitId);
            runInAction(async() => {
                this.loadUnits().then(() => {
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}