import {
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Stack,
    styled,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { departmentOptions } from "../../../app/common/options/departmentOptions";
  import { useStore } from "../../../app/stores/store";
  import CheckCircleIcon from "@mui/icons-material/CheckCircle";
  import PendingActionsIcon from "@mui/icons-material/PendingActions";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import ArchiveIcon from "@mui/icons-material/Archive";
  import SendIcon from "@mui/icons-material/Send";
  import { useEffect, useState } from "react";
  import RadarIcon from "@mui/icons-material/Radar";
  import CheckIcon from "@mui/icons-material/Check";
  import { format } from "date-fns";
  import {
    CompleteRequestReleaseFormValues,
  } from "../../../app/models/requests/requestRelease";
  export default observer(function DispatchDetail() {
    const { requestReleasestore} = useStore();
    const [HasRender, setHasRender] = useState(false);
    const {
        unSelectRequestRelease,
        selectedRequestRelease,
        loadRequestRelease,
        completeRequestRelease
    } = requestReleasestore;
  
    useEffect(() => {
      if (!HasRender) {
        if (selectedRequestRelease) {
          setHasRender(true);
          loadRequestRelease(selectedRequestRelease?.id);
        }
      }
      if (!selectedRequestRelease) {
        setHasRender(false);
      }
    }, [selectedRequestRelease]);
  
    const handleReturnToList = () => {
        unSelectRequestRelease();
    };
  
    const handleComplete = (id: string) => {
        let completeRequestReleaseFormValues: CompleteRequestReleaseFormValues = {
            id: id,
            isComplete:true
          };
          completeRequestRelease(id,completeRequestReleaseFormValues);
    };

    const GridHead = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#505050",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: "#ffffff",
    }));
  
    const GridBody = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#e5e5e5",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    return (
      <>
       <Stack spacing={2}>
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ padding: 1 }} variant="h5">
              {selectedRequestRelease?.requestNumber}
            </Typography>
            <Stack
              sx={{ paddingRight: 2, paddingTop: 1.5 }}
              direction="row"
              spacing={2}
            >
              {selectedRequestRelease?.isComplete ? (
                <Chip
                  size="small"
                  icon={<CheckCircleIcon />}
                  color="success"
                  label="Completed"
                />
              ) : (
                <Chip
                  size="small"
                  icon={<PendingActionsIcon />}
                  color="warning"
                  label="For Dispatch"
                />
              )}
            </Stack>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>
          <Divider sx={{ marginBottom: 1 }} />
          <Box sx={{ paddingBottom: 2 }}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={12} lg={12}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {"Branch: " + selectedRequestRelease?.branch}
                  </Typography>

                  <Typography variant="h6">
                       {selectedRequestRelease?.completionDate
                      ? "Delivery Date: " +
                        format(
                          new Date(selectedRequestRelease?.completionDate!),
                          "dd MMM yyyy"
                        )
                      : "Delivery Date: "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleReturnToList}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Return to list
            </Button>
            {selectedRequestRelease?.isComplete ? (
              ""
            ) : (
              <Button
                startIcon={<CheckIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                onClick={() => handleComplete(selectedRequestRelease!=null ? selectedRequestRelease?.id:"")}
                sx={{
                  backgroundColor: "orange",
                  "&:hover": {
                    backgroundColor: "orange",
                    borderColor: "none",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "orange",
                    borderColor: "none",
                  },
                }}
              >
                Complete
              </Button>
            )}
          </Stack>
        </Paper>

        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Inventory to Dispatch
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box
            style={{
              height: "52vh",
              maxHeight: "52vh",
              overflow: "auto",
            }}
          >
            <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
              {selectedRequestRelease?.fpoToConfirm?.map((item) => (
                <Card key={item.id}>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Stack spacing={5} direction="row">
                      <Typography variant="h6">Item: {item.item}</Typography>
                      <Typography variant="h6">
                        Storage: {item.location}
                      </Typography>
                      <Typography variant="h6">
                        Expiration Date:
                        {item?.expirationDate
                          ? format(
                              new Date(item?.expirationDate!),
                              "dd MMM yyyy"
                            )
                          : ""}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 3 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10}>
                          <GridHead>To Process</GridHead>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Quantity</Typography>
                              <Typography>
                                {item.expectedQuantity} {item.expectedUnit}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Alt Quantity</Typography>
                              <Typography>
                                {item.altQuantity} {item.altUnit}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Divider />
                </Card>
              ))}
            </Stack>
          </Box>
        </Paper>
      </Stack>
      </>
    );
  });
  