import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CompleteRequestReleaseFormValues, RequestRelease, RequestReleaseList } from "../../models/requests/requestRelease";

export default class RequestReleaseStore {
    requestReleaseRegistry = new Map<string, RequestReleaseList>();
    itemCount: number = 0;
    selectedRequestRelease: RequestRelease | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    getRequestReleases = () => {
        return Array.from(this.requestReleaseRegistry.values());
    }

    loadRequestRelease =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var requestRelease = await agent.RequestReleases.detail(id);
                runInAction(() => {
                    this.selectedRequestRelease = requestRelease;
                })
                this.setLoadingInitial(false);
                return requestRelease;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadRequestReleases = async () => {
        this.loadingInitial = true;
        try {
            const requestReleases = await agent.RequestReleases.list();
            requestReleases.forEach(requestRelease => {
                this.setRequestRelease(requestRelease);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setRequestRelease = (requestRelease: RequestReleaseList) => {
        this.requestReleaseRegistry.set(requestRelease.id, requestRelease);
    }

    private getRequestRelease = () => {
        return this.selectedRequestRelease;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    unSelectRequestRelease= () => {
        this.selectedRequestRelease = undefined;
    }

    completeRequestRelease = async (id:string,completeRequestRelease: CompleteRequestReleaseFormValues) => {
        this.loading = true;
        try {
            await agent.RequestReleases.complete(id, completeRequestRelease);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
                this.unSelectRequestRelease();
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}