import { Container, Paper, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../app/stores/store";

export default observer(function ServerError() {
  const { commonStore } = useStore();
  return (
    <Container>
      <Typography variant="h1" component="div">
        Server Error
      </Typography>
      
      {commonStore.error?.details && (
        <Paper>
          <Typography variant="h5" component="div">Stack Trace</Typography>
          <code style={{ marginTop: "10px" }}>{commonStore.error.details}</code>
        </Paper>
      )}
    </Container>
  );
});
