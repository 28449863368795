import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import BranchList from "./BranchList";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import BranchDetail from "./BranchDetail";
import BranchForm from "./BranchForm";
import FilterListIcon from "@mui/icons-material/FilterList";
import Search from "../../../app/layout/Search";
import { Branch } from "../../../app/models/admin/branch";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default observer(function ManageBranch() {
  const { branchStore } = useStore();
  const {
    loadBranches,
    branchRegistry,
    loadingInitial,
    searchBranches,
    unSelectBranch,
    selectedBranch,
    pagination,
    pagingParams,
  } = branchStore;
  const [add, setAdd] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [searchName, setSearchName] = React.useState<string>();

  useEffect(() => {
    handlePaginationInit();
    if (branchRegistry.size <= 0) loadBranches();
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems,branchRegistry]);

  const handleAdd = () => {
    unSelectBranch();
    setAdd(true);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    if(searchName!= null){
      searchBranches(searchName);
    }else{
      loadBranches();
    }
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    if(searchName!= null){
      searchBranches(searchName);
    }else{
      loadBranches();
    }
  };

  const handleSearch = () => {
    if(searchName!= null){
      searchBranches(searchName);
    }else{
      loadBranches();
    }
  };
  if (loadingInitial) return <LoadingComponent content="Loading Branches..." />;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Branches
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          View all branches, add and update a branch
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} md={12}>
                {/* search component */}
                <Box sx={{ width: "100%", marginBottom: "20px" }}>
                    <Paper
                      component="form"
                      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100% " }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Branch Name..."
                        inputProps={{ "aria-label": "Search Branch Name" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchName(event.target.value);
                        }}
                      />
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                {/* <Search searchList={searchBranches} /> */}
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Branches
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAdd}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "orange",
                          "&:hover": {
                            backgroundColor: "orange",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "orange",
                            borderColor: "none",
                          },
                        }}
                      >
                        Add Branch
                      </Button>
                      <Dialog open={add}>
                        <DialogTitle>Add Branch</DialogTitle>
                        <DialogContent>
                          {/* input fields */}
                          <BranchForm closeForm={handleCloseAdd} />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Stack>

                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    {/* list component here */}
                    <BranchList />
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        onClick={handlePrevClick}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "orange",
                          "&:hover": {
                            backgroundColor: "orange",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "orange",
                            borderColor: "none",
                          },
                          display: currentPage == 1 ? "none" : "block",
                        }}
                      >
                        Previous
                      </Button>
                    </Box>
                    <Typography
                      sx={{ padding: 1 }}
                      variant="h6"
                      color="text.secondary"
                    >
                      Page {currentPage} of{" "}
                      {totalItems != 0 ? Math.ceil(totalItems / pageSize) : 1}
                    </Typography>
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        onClick={handleNextClick}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "orange",
                          "&:hover": {
                            backgroundColor: "orange",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "orange",
                            borderColor: "none",
                          },
                          display:
                            currentPage == Math.ceil(totalItems / pageSize)
                              ? "none"
                              : totalItems != 0
                              ? "block"
                              : "none",
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
