import { Box, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { TabPanel } from "../../app/common/panel/TabPanel";
import EmployeeProfile from "./EmployeeProfile";
import FranchiseeProfile from "./FranchiseeProfile";

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default observer(function CreateProfile() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">Create Profile</Typography>
        <Typography sx={{ paddingLeft: 1, paddingBottom: 1}}
            variant="body2"
            color="text.secondary">Select a profile to create</Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Employee" {...a11yProps(0)} />
            <Tab label="Franchisee" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <EmployeeProfile/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FranchiseeProfile/>
        </TabPanel>
      </Box>
    </>
  );
});
