import {
  Avatar,
  Badge,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../app/common/form/FormTextField";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { EmployeeProfileFormValues } from "../../app/models/profile";
import { history } from "../..";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default observer(function EmployeeProfile() {
  const { profileStore, commonStore } = useStore();
  const {
    checkIfEmployeeRegistered,
    loadEmployeeProfile,
    createEmployeeProfile,
    updateEmployeeProfile,
    loading,
    loadingInitial,
  } = profileStore;
  const { uploadPhoto } = commonStore;
  const [title, setTitle] = useState<string>("Create Employee Profile");
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [employeeProfileFormValues, setEmployeeForm] =
    useState<EmployeeProfileFormValues>({
      id: "",
      firstName: "",
      lastName: "",
      address: "",
      profilePhoto: "",
    });

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    address: yup.string().required("Required"),
  });

  useEffect(() => {
    loadEmployeeProfile().then((values) => {
      if (checkIfEmployeeRegistered()) {
        setTitle("Update Employee Profile");
        setBaseImage(values!.profilePhoto);
        let formData: EmployeeProfileFormValues = {
          id: values!.id,
          firstName: values!.firstName,
          lastName: values!.lastName,
          address: values!.address,
          profilePhoto: values!.profilePhoto,
        };
        setEmployeeForm(formData);
      }
    });
  }, [checkIfEmployeeRegistered, loadEmployeeProfile]);

  const handleUploadPhoto = async (e: any) => {
    setBaseImage(await uploadPhoto(e));
    setIsPhotoUploaded(true);
  }

  function handleFormSubmit(
    employeeProfileFormValues: EmployeeProfileFormValues
  ) {
    let profile: EmployeeProfileFormValues = {
      ...employeeProfileFormValues,
      profilePhoto: baseImage
    };
    if (!checkIfEmployeeRegistered()) {
      createEmployeeProfile(profile).then(() =>
        history.push(`/dashboard`)
      );
    } else {
      updateEmployeeProfile(profile).then(() => {
        history.push(`/dashboard`);
      });
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading Profile..." />;

  return (
    <>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={2}></Grid>
        <Grid item xs>
          <Paper style={{ backgroundColor: "#EFEFEF", height: "100%" }}>
            <Grid container spacing={0}>
              <Formik
                initialValues={employeeProfileFormValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                  <Form noValidate autoComplete="off">
                    {/* header start */}
                    <Grid item sx={{ padding: 2 }}>
                      <Typography variant="h5" component="div">
                        {title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Please fill in additional information to complete your
                        profile
                      </Typography>
                    </Grid>
                    {/* header end */}

                    {/* Form */}
                    <Grid
                      item
                      container
                      direction="row"
                      spacing={2}
                      sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
                    >
                      {/* input fields start */}
                      <Grid item container spacing={2} xs={12} md={8}>
                        <Grid item xs={12}>
                          <Field
                            name="firstName"
                            label="First Name"
                            size="small"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="lastName"
                            label="Last Name"
                            size="small"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="address"
                            label="Address"
                            size="small"
                            multiline
                            rows={3}
                            component={FormTextField}
                          />
                        </Grid>
                      </Grid>
                      {/* input fields end */}

                      {/* profile pic start */}
                      <Grid item xs={12} md={4}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Stack spacing={2}>
                            <Avatar
                              src={baseImage}
                              sx={{ width: 150, height: 150 }}
                            />
                            <Button
                              component="label"
                              startIcon={<AddAPhotoIcon />}
                              variant="contained"
                              size="small"
                              color="primary"
                              disableElevation
                              sx={{
                                backgroundColor: "black",
                                "&:hover": {
                                  backgroundColor: "black",
                                  borderColor: "none",
                                  boxShadow: "none",
                                },
                                "&:active": {
                                  boxShadow: "none",
                                  backgroundColor: "black",
                                  borderColor: "none",
                                },
                              }}
                            >
                              Upload Photo
                              <input
                                name="profilePhoto"
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                hidden
                                onChange={(e) => {
                                  handleUploadPhoto(e);
                                }}
                              />
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                      {/* profile pic end */}
                    </Grid>
                    {/* form end */}

                    {/* button start */}
                    <Grid item sx={{ padding: 2 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        disableElevation
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        Submit Profile
                      </Button>
                    </Grid>
                    {/* button end */}
                  </Form>
                )}
              </Formik>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
});
