import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Category, CategoryFormValues, CategoryOption } from "../../models/admin/category";
import { Pagination, PagingParams } from "../../models/pagination";

export default class CategoryStore {
    categoryRegistry = new Map<string, Category>();
    categoryOptions = new Map<string, CategoryOption>();
    nonePaginatedCategoryOptions = new Map<string, CategoryOption>();
    itemCount: number = 0;
    selectedCategory: Category | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    categories: Category[] = [];
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getCategories = () => {
        return Array.from(this.categoryRegistry.values());
    }

    getNonePaginatedCategoryOptions = async () => {
        this.loadingInitial = true;
        let nonePaginatedCategoryOptions = this.nonePaginatedCategoryOptions;
        try {
            const cat = await agent.Categories.options();
            cat.forEach(category => {
                let categoryOption: CategoryOption = {
                    value: category.id,
                    label: category.name
                }
                this.nonePaginatedCategoryOptions.set(category.id, categoryOption);
            });
            this.setLoadingInitial(false);
            return nonePaginatedCategoryOptions;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCategory = async (id: string) => {
        try {
            let category = await agent.Categories.detail(id);
            this.setCategory(category);
            runInAction(() => {
                this.selectedCategory = category;
            })
            this.setLoadingInitial(false);
            return category;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadCategories = async () => {
        this.loadingInitial = true;
        this.categoryRegistry.clear();
        try {
            const result = await agent.Categories.list(this.axiosParams,"");
            result.data.forEach(category => {
                this.setCategory(category);
                let categoryOption: CategoryOption = {
                    value: category.id,
                    label: category.name
                }
                this.setCategoryOption(categoryOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchCategories = async (searchFilter: string) => {
        this.categoryRegistry.clear();
        try {
            const result = await agent.Categories.list(this.axiosParams,searchFilter);
            result.data.forEach(category => {
                this.setCategory(category);
                let categoryOption: CategoryOption = {
                    value: category.id,
                    label: category.name
                }
                this.setCategoryOption(categoryOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    selectCategory = (id: string) => {
        this.selectedCategory = this.categoryRegistry.get(id);
    }

    unSelectCategory = () => {
        this.selectedCategory = undefined;
    }

    private setCategory = (category: Category) => {
        category.lastUpdated = new Date(category.lastUpdated!);
        this.categoryRegistry.set(category.id, category);
    }

    private setCategoryOption = (categoryOption: CategoryOption) => {
        this.categoryOptions.set(categoryOption.value, categoryOption);
    }

    private getCategory = (id: string) => {
        return this.categoryRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createCategory = async (category: CategoryFormValues) => {
        this.loading = true;
        try {
            var categoryId = (await agent.Categories.create(category)).data;
            runInAction(async() => {
                this.loadCategory(categoryId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateCategory = async (category: CategoryFormValues) => {
        this.loading = true;
        try {
            var categoryId = (await agent.Categories.update(category)).data;
            runInAction(async() => {
                this.loadCategory(categoryId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveCategory = async (categoryId: string) => {
        this.loading = true;
        try {
            await agent.Categories.delete(categoryId);
            runInAction(async() => {
                this.loadCategories().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}