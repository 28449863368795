import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardHeader,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { useStore } from "../../../app/stores/store";
  import EditIcon from "@mui/icons-material/Edit";
  import { green, red } from "@mui/material/colors";
  import CheckCircleIcon from "@mui/icons-material/CheckCircle";
  import CancelIcon from "@mui/icons-material/Cancel";
  import React, { useEffect, useState } from "react";
  import Search from "../../../app/layout/Search";
  import {
    ApproveProfileFormValues,
    FranchiseeProfiles,
    FranchiseeProfilesForm,
  } from "../../../app/models/profile";
  import { RoleList, RolesOption } from "../../../app/models/admin/role";
  import { Form, Formik } from "formik";
  import LoadingComponent from "../../../app/layout/LoadingComponent";
  import { history } from "../../..";
  
  export default observer(function FranchiseeList() {
    const { profileStore, roleStore } = useStore();
    const {
      franchisees: franchiseeList,
      approveFranchiseeProfile,
      loading,
      loadingInitial,
    } = profileStore;
    const { getRoleOptions } = roleStore;
    const [franchisees, setFranchisees] = useState<FranchiseeProfilesForm[]>([]);
    const [roleOptions, setRoleOptions] = useState<RolesOption[]>([]);
    let franchiseeForms: FranchiseeProfilesForm[] = [];
  
    useEffect(() => {
      franchiseeList.forEach((franchisee) => {
        let franchiseeToAdd: FranchiseeProfilesForm = {
          id: franchisee.id,
          companyName: franchisee.companyName,
          isApproved: franchisee.isApproved,
          roleIds: [],
        };
  
        franchisee.roles!.forEach((role) => {
          let roleToAdd: RolesOption = {
            label: role.name,
            value: role.id,
          };
          franchiseeToAdd.roleIds!.push(roleToAdd);
        });
        franchiseeForms.push(franchiseeToAdd);
      });
      setFranchisees(franchiseeForms);
      setRoleOptions(getRoleOptions());
    }, [franchiseeList, getRoleOptions]);
  
    function handleApprove(franchiseeToApprove: FranchiseeProfilesForm) {
      let approveFormValues: ApproveProfileFormValues = {
        id: franchiseeToApprove.id,
        userProfile: 1,
        isApproved: true,
        roleIds: [],
      };
  
      franchiseeToApprove.roleIds!.forEach((role) => {
        approveFormValues.roleIds!.push(role.value);
      });
  
      approveFranchiseeProfile(approveFormValues).then(() => setFranchisees(franchiseeForms));
    }
  
    function handleReject(franchiseeToApprove: FranchiseeProfilesForm) {
      let approveFormValues: ApproveProfileFormValues = {
        id: franchiseeToApprove.id,
        userProfile: 2,
        isApproved: false,
        roleIds: [],
      };
  
      approveFranchiseeProfile(approveFormValues).then(() => setFranchisees(franchiseeForms));
    }
  
    if (loadingInitial)
      return <LoadingComponent content="Updating User Profiles..." />;
  
    return (
      <>
        <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
          {franchisees.map((franchisee) => (
            <Card key={franchisee.id}>
              <Formik
                initialValues={franchisee}
                enableReinitialize
                onSubmit={(values) => handleApprove(values)}
              >
                {({ values, setFieldValue }) => (
                  <Form noValidate autoComplete="off">
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ flexGrow: 0, padding: 2 }}>
                        <Typography variant="h5">
                          {franchisee.companyName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                      />
                      <Box sx={{ flexGrow: 0, padding: 2 }}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            color="success"
                            disableElevation
                            disabled={loading}
                          >
                            {franchisee.isApproved ? "Update Role" : "Approve"}
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            disableElevation
                            disabled={loading}
                            onClick={() => handleReject(values)}
                          >
                            {franchisee.isApproved ? "Revoke" : "Reject"}
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                    <Autocomplete
                      sx={{ marginLeft: 2, marginRight: 2 }}
                      multiple
                      id="tags-standard"
                      options={roleOptions}
                      getOptionLabel={(option: RolesOption) => option.label}
                      defaultValue={franchisee.roleIds!}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, value) => {
                        setFieldValue("roleIds", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Franchisee Roles"
                          sx={{
                            backgroundColor: "white",
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "gray",
                            }, //styles the label
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": { borderColor: "gray" },
                            },
                          }}
                        />
                      )}
                    />
                  </Form>
                )}
              </Formik>
            </Card>
          ))}
        </Stack>
      </>
    );
  });
  