import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import {
  TransferRequestFormValues,
  ItemToTransfer,
} from "../../../app/models/requests/transferRequest";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { history } from "../../..";

interface Props {
  closeForm: () => void;
  multipleMode: boolean;
}

export default observer(function TransferRequestForm({
  closeForm,
  multipleMode,
}: Props) {
  const { inventoryStore, transferRequestStore } = useStore();
  const { selectedItem } = transferRequestStore;
  const {
    findInventory,
    loadInventory,
    loadingInitial: loadingInventory,
  } = inventoryStore;
  const { createTransferRequest, loadingInitial: loadingTransfer } =
    transferRequestStore;
  const [unitOptions, setUnitOptions] = useState<UnitsOption[]>([]);
  const [transferRequestFormValues, setTransferRequestForm] =
    useState<TransferRequestFormValues>({
      itemId: "",
      inventoryToTransferQuantity: 0,
      unitId: "",
      remarks: "",
      providingDepartment: 0,
      receivingDepartment: 0,
    });

  const validationSchema = yup.object().shape({
    inventoryToTransferQuantity: yup.number().moreThan(0).required("Required"),
    unitId: yup.string().required("Required"),
  });

  useEffect(() => {
    loadInventory().then(() => {
      let itemInventory = findInventory(selectedItem!.id);
      let uOptions = new Map<string, UnitsOption>();
      itemInventory.forEach((inventory) => {
        let uOption: UnitsOption = {
          value: inventory.unitOfMeasureId,
          label: inventory.unitOfMeasure,
        };
        uOptions.set(uOption.value, uOption);
      });
      setUnitOptions(Array.from(uOptions.values()));
    });
  }, [findInventory, selectedItem, loadInventory]);

  function handleFormSubmit(transferRequestFormValues: TransferRequestFormValues) {
    let transferRequestForm: TransferRequestFormValues = {
        ...transferRequestFormValues,
    };
    transferRequestForm.itemId = selectedItem!.id;

    if (multipleMode) {
        transferRequestStore.addMultipleTransferFormValues(transferRequestForm);
        closeForm();
    } else {
        createTransferRequest(transferRequestForm).then(() =>
            history.push(`/history/transfer`)
        );
    }
}

  if (loadingInventory && loadingTransfer)
    return <LoadingComponent content="Loading Transfer Request..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={transferRequestFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="h5">
                  Transfering: {selectedItem!.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Please fill in Transfer information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="inventoryToTransferQuantity"
                      label="Quantity to Transfer"
                      size="small"
                      type="number"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="unitId"
                      label="Unit"
                      size="small"
                      options={unitOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="remarks"
                      label="Remarks"
                      size="small"
                      multiline
                      rows={3}
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="providingDepartment"
                      label="Providing Department"
                      size="small"
                      options={departmentOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="receivingDepartment"
                      label="Receiving Department"
                      size="small"
                      options={departmentOptions}
                      component={FormSelect}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {multipleMode ? "Add to Transfer List" : "Request Transfer"}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
