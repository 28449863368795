import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PurchaseOrderConfirmation from "./PurchaseOrderConfirmation";
import PurchaseOrderRequest from "./PurchaseOrderRequest";

export default observer(function CreatePurchaseOrderRequest() {
  const { franchiseeOrderRequestStore } = useStore();
  const { confirmedOrder } = franchiseeOrderRequestStore;
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Order Request
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={12}>
            {confirmedOrder ? (
              <PurchaseOrderConfirmation />
            ) : (
              <PurchaseOrderRequest />
            )}
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
