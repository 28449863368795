import { Chip, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { sizeOptions } from "../../../app/common/options/sizeOptions";
import { useStore } from "../../../app/stores/store";

export default observer(function FinalPortionDetail() {
  const { finalPortionStore } = useStore();
  const { selectedFinalPortion } = finalPortionStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedFinalPortion
          ? selectedFinalPortion.name
          : "Select a final portion from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedFinalPortion
                ? "Menu Name: " + selectedFinalPortion.menuName
                : "Menu Name: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedFinalPortion
                ? "Size: " + sizeOptions[selectedFinalPortion.size].label
                : "Size: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">
              Components
            </Typography>
            {selectedFinalPortion 
            ? selectedFinalPortion.components!.map((component) => (
                <Chip key={component.id} color="default"
                label={component.component}/>
            )) : ""}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
