import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import FilterListIcon from "@mui/icons-material/FilterList";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitForm from "./UnitForm";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default observer(function ManageUnit() {
  const { unitStore } = useStore();
  const {
    unitRegistry,
    loadUnits,
    searchUnits,
    unSelectItem,
    selectedUnitManage,
    archiveUnit
  } = unitStore;
  const [archive, setArchive] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [searchName, setSearchName] = React.useState<string>();

  useEffect(() => {
    if (unitRegistry.size <= 0) loadUnits();
  }, [unitRegistry]);

  const handleAdd = () => {
    unSelectItem();
    setAdd(true);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };
  const handleUpdate = () => {
    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };

  const handleArchive = (id: string) => {
    if (selectedUnitManage != null) {
      selectedUnitManage.id = id;
    }
    setArchive(true);
  };

  const handleCloseArchive = () => {
    setArchive(false);
  };

  const handleSaveArchive = (id: string) => {
    setArchive(false);
    archiveUnit(id);
  };
  
  const handleSearch = () => {
    if(searchName!= null){
      searchUnits(searchName);
    }else{
      loadUnits();
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Units
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          View all units, add and update an unit
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} md={4}>
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Units
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAdd}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        Add Unit
                      </Button>
                      <Dialog open={add}>
                        <DialogTitle>Add Unit</DialogTitle>
                        <DialogContent>
                          {/* input fields */}
                          <UnitForm closeForm={handleCloseAdd} />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Stack>
                  <Box sx={{ width: "100%", marginBottom: "20px", paddingX:"10px" }}>
                    <Paper
                      component="form"
                      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100% " }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Unit Name..."
                        inputProps={{ "aria-label": "Search Unit Name" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchName(event.target.value);
                        }}
                      />
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Box>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    {/* list component here */}
                    <UnitList />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Unit Details
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    />
                    <Box sx={{ flexGrow: 0, padding: 1 }}>
                      <Stack direction="row" spacing={1}>
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={() =>
                            selectedUnitManage != null
                              ? handleArchive(selectedUnitManage.id)
                              : null
                          }
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          disabled={selectedUnitManage == null}
                          sx={{
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "black",
                              borderColor: "none",
                            },
                          }}
                        >
                          Delete Unit
                        </Button>
                        <Button
                          startIcon={<EditIcon />}
                          onClick={handleUpdate}
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          disabled={selectedUnitManage == null}
                          sx={{
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "black",
                              borderColor: "none",
                            },
                          }}
                        >
                          Update Unit
                        </Button>
                      </Stack>
                      <Dialog open={update}>
                        <DialogTitle>Update Unit</DialogTitle>
                        <DialogContent>
                          {/* input fields */}
                          <UnitForm closeForm={handleCloseUpdate} />
                        </DialogContent>
                      </Dialog>
                      <Dialog open={archive}>
                        <DialogTitle>Archive Item</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to archive this item?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseArchive}
                            variant="contained"
                            size="small"
                            color="primary"
                            disableElevation
                            sx={{
                              backgroundColor: "black",
                              "&:hover": {
                                backgroundColor: "black",
                                borderColor: "none",
                                boxShadow: "none",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "black",
                                borderColor: "none",
                              },
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() =>
                              selectedUnitManage != null
                                ? handleSaveArchive(selectedUnitManage.id)
                                : null
                            }
                            variant="contained"
                            size="small"
                            color="primary"
                            disableElevation
                            sx={{
                              backgroundColor: "black",
                              "&:hover": {
                                backgroundColor: "black",
                                borderColor: "none",
                                boxShadow: "none",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "black",
                                borderColor: "none",
                              },
                            }}
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Stack>

                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    {/* details component here */}
                    <UnitDetail />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
