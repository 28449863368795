import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { RepackRequestList } from "../../../app/models/requests/repackRequest";

export default observer(function RepackRequestsList() {
  const { repackRequestStore } = useStore();
  const {
    loadRepackRequest,
    loadRepackRequests,
    getRepackRequests,
    loadingInitial,
    pagination,
    pagingParams,
  } = repackRequestStore;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);

  const handleSelectRequest = (request: RepackRequestList) => {
    loadRepackRequest(request.id);
  };

  useEffect(() => {
    handlePaginationInit();
    loadRepackRequests();
    if (pagination) {
      setTotalItems(pagination?.totalItems);
    }
  }, [pagination?.totalItems, loadRepackRequests]);

  const handlePaginationInit = () => {
    handleSetPagingParams(currentPage);
  };

  const handleSetPagingParams = (page: number) => {
    pagingParams.pageNumber = page;
    pagingParams.pageSize = pageSize;
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    handleSetPagingParams(currentPage + 1);
    loadRepackRequests();
  };

  const handlePrevClick = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    handleSetPagingParams(currentPage - 1);
    loadRepackRequests();
  };

  if (loadingInitial)
    return <LoadingComponent content="Loading Repack Requests..." />;

  return (
    <>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ padding: 1 }} variant="h5">
            Repack Requests
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
        </Stack>

        <Divider sx={{ marginBottom: 1 }} />
        <Box
          style={{
            height: "73vh",
            maxHeight: "73vh",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {getRepackRequests().map((request) => (
              <Card
                key={request.id}
                onClick={() => handleSelectRequest(request)}
              >
                <CardActionArea>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Typography sx={{ paddingBottom: 1 }} variant="h5">
                      Repack Request Number: {request.requestNumber}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {request.isTurnOver ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Transfered"
                        />
                      ) : (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="In Progress"
                        />
                      )}
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </Box>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handlePrevClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display: currentPage == 1 ? "none" : "block",
              }}
            >
              Previous
            </Button>
          </Box>
          <Typography sx={{ padding: 1 }} variant="h6" color="text.secondary">
            Page {currentPage} of {totalItems!=0? Math.ceil(totalItems / pageSize) : 1}
          </Typography>
          <Box sx={{ flexGrow: 0, padding: 1 }}>
            <Button
              onClick={handleNextClick}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
                display:
                  currentPage == Math.ceil(totalItems / pageSize)
                    ? "none"
                    : totalItems!=0? "block":"none",
              }}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Paper>
    </>
  );
});
