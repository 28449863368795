import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function ItemDetail() {
  const { itemStore } = useStore();
  const { selectedItem } = itemStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedItem
          ? selectedItem.name
          : "Select an item from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedItem
                ? "Category Name: " + selectedItem.categoryName
                : "Category Name: "}
            </Typography>

            <Typography variant="subtitle1">
            {selectedItem
                ? selectedItem.isExpiring
                  ? "Expiry status: This item will expire"
                  : "Expiry status: this item does not expire"
                : "Expiry status: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
