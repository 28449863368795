import { Card, CardActionArea, CardHeader, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function ComponentList() {
  const { itemStore } = useStore();
  const { getItems, loadItem } = itemStore;

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getItems().map((item) => (
          <Card key={item.id} onClick={() => loadItem(item.id)}>
            <CardActionArea>
              <CardHeader
                title={item.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
});
