import {
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Stack,
    styled,
    Typography,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { observer } from "mobx-react-lite";
  import { departmentOptions } from "../../../app/common/options/departmentOptions";
  import { useStore } from "../../../app/stores/store";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import SendIcon from "@mui/icons-material/Send";
  import { useEffect, useState } from "react";
  import { format } from "date-fns";
  import LoadingComponent from "../../../app/layout/LoadingComponent";
  import {
    ConfirmProcess,
    ProcessToRequest,
    IngredientsToConfirm,
    TransfersToProcess,
  } from "../../../app/models/requests/processRequest";
  import { history } from "../../..";
  import { TransferRequestFormValues } from "../../../app/models/requests/transferRequest";
  
  export default observer(function ConfirmProcessRequest() {
    const { processRequestStore, transferRequestStore } = useStore();
    const {
      unSetProcessForm,
      confirmProcess,
      loading,
      removeToProcess,
      removeToTransfer,
      createProcessRequest,
      filledProcessForm,
      selectedRecipe
    } = processRequestStore;
    const { createMultipleTransferRequest, loading: loadingTransfer } =
      transferRequestStore;
    const [processToConfirm, setProcessToConfirm] = useState<ConfirmProcess>({
      itemToProcess: "",
      itemToProcessId: "",
      serving: 0,
      expectedQuantity: 0,
      expectedUnit: "",
      processingDepartment: filledProcessForm!.processingDepartment,
      ingredientsToConfirm: [],
      transfersToProcess: [],
    });
  
    const handleReturnToList = () => {
      unSetProcessForm();
    };
  
    const GridHead = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#505050",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: "#ffffff",
    }));
  
    const GridBody = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#e5e5e5",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
  
    const handleArchive = () => {};
  
    const handleConfirmAll = () => {
      let processToRequest: ProcessToRequest = {
        recipeId: selectedRecipe!.id,
        processingDepartment: processToConfirm.processingDepartment,
        ingredientsToAdd: processToConfirm.ingredientsToConfirm,
      };
  
      if (processToConfirm.transfersToProcess.length > 0) {
        let transferRequests: TransferRequestFormValues[] = [];
  
        processToConfirm.transfersToProcess.forEach((toTransfer) => {
          let transferRequestForm: TransferRequestFormValues = {
            itemId: toTransfer.itemId,
            inventoryToTransferQuantity: toTransfer.quantity,
            unitId: toTransfer.unitId,
            remarks: "Transfer request for process completion",
            providingDepartment: toTransfer.providingDepartment,
            receivingDepartment: toTransfer.receivingDepartment,
          };
  
          transferRequests.push(transferRequestForm);
        });
  
        createProcessRequest(processToRequest).then(() => {
          createMultipleTransferRequest(transferRequests).then(() => {
            unSetProcessForm();
            history.push(`/history/process`);
          });
        });
      } else {
        createProcessRequest(processToRequest).then(() => {
          unSetProcessForm();
          history.push(`/history/process`);
        });
      }
    };
  
    const handleRemoveIngredient = (ingredientToRemove: IngredientsToConfirm) => {
      removeToProcess(ingredientToRemove);
    };
  
    const handleRemoveTransfer = (transferToRemove: TransfersToProcess) => {
      removeToTransfer(transferToRemove);
    };
  
    useEffect(() => {
      setProcessToConfirm(confirmProcess!);
      console.log(confirmProcess);
    }, [setProcessToConfirm, confirmProcess]);
  
    if (loading || loadingTransfer)
      return <LoadingComponent content="Loading Process Request..." />;
  
    return (
      <>
        <Stack spacing={2}>
          <Paper style={{ backgroundColor: "#EFEFEF" }}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ padding: 1 }} variant="h5">
                Confirm inventories for processing
              </Typography>
            </Stack>
  
            <Divider sx={{ marginBottom: 1 }} />
  
            <Box sx={{ paddingBottom: 2 }}>
              <Grid container direction="row" spacing={0}>
                <Grid item xs={12} md={12} lg={6}>
                  <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    <Typography variant="h6">
                      {processToConfirm
                        ? "Item to Process: " + processToConfirm!.itemToProcess
                        : "Item to Process: "}
                    </Typography>
                    <Typography variant="h6">
                      {processToConfirm
                        ? "Quantity: " +
                          processToConfirm!.expectedQuantity +
                          " " +
                          processToConfirm!.expectedUnit
                        : "Quantity: "}
                    </Typography>
                    <Typography variant="h6">
                      {processToConfirm
                        ? "Serving: " +
                          processToConfirm!.serving
                        : "Quantity: "}
                    </Typography>
                  </Stack>
                </Grid>
  
                <Grid item xs={12} md={12} lg={6}>
                  <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    <Typography variant="h6">
                      {processToConfirm
                        ? "Processing Department: " +
                          departmentOptions[processToConfirm!.processingDepartment]
                            .label
                        : "Processing Department: "}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
  
            <Divider />
  
            <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
              <Button
                startIcon={<ArrowBackIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                onClick={handleReturnToList}
                sx={{
                  backgroundColor: "orange",
                  "&:hover": {
                    backgroundColor: "orange",
                    borderColor: "none",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "orange",
                    borderColor: "none",
                  },
                }}
              >
                Return to list
              </Button>
  
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled={processToConfirm!.ingredientsToConfirm.length == 0}
                onClick={handleConfirmAll}
                sx={{
                  backgroundColor: "orange",
                  "&:hover": {
                    backgroundColor: "orange",
                    borderColor: "none",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "orange",
                    borderColor: "none",
                  },
                }}
              >
                Confirm All
              </Button>
            </Stack>
          </Paper>
  
          {/* inventories to process */}
          <Paper style={{ backgroundColor: "#EFEFEF" }}>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ padding: 1 }} variant="h5">
                Inventories to Process
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            </Stack>
  
            <Divider sx={{ marginBottom: 1 }} />
  
            <Box
              style={{
                height: "52vh",
                maxHeight: "52vh",
                overflow: "auto",
              }}
            >
              <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                {processToConfirm!.ingredientsToConfirm.map((ingredient) => (
                  <Card key={ingredient.id}>
                    <Box sx={{ flexGrow: 0, padding: 2 }}>
                      <Stack spacing={5} direction="row">
                        <Typography variant="h6">
                          Item: {ingredient.item}
                        </Typography>
                        <Typography variant="h6">
                          Storage: {ingredient.location}
                        </Typography>
                        {ingredient.expirationDate ? (
                          <Typography variant="h6">
                            Expiration Date:{" "}
                            {format(
                              new Date(ingredient.expirationDate),
                              "dd MMM yyyy"
                            )}
                          </Typography>
                        ) : (
                          <Typography variant="h6">Expiration Date:</Typography>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 3 }}
                      >
                        {/* inventory to process */}
                        <Grid container spacing={1}>
                          <Grid item xs={1}></Grid>
  
                          <Grid item xs={10}>
                            <GridHead>To Process</GridHead>
                          </Grid>
  
                          <Grid item xs={1}></Grid>
  
                          <Grid item xs={1}></Grid>
  
                          <Grid item xs={5}>
                            <GridBody>
                              <Stack>
                                <Typography>Quantity</Typography>
                                <Typography>
                                  {ingredient.quantityToProcess}{" "}
                                  {ingredient.unitToProcess}
                                </Typography>
                              </Stack>
                            </GridBody>
                          </Grid>
                          <Grid item xs={5}>
                            <GridBody>
                              <Stack>
                                <Typography>Alt Quantity</Typography>
                                <Typography>
                                  {ingredient.altQuantity > 1000
                                    ? ingredient.altQuantity / 1000
                                    : ingredient.altQuantity}{" "}
                                  {ingredient.altUnit}
                                </Typography>
                              </Stack>
                            </GridBody>
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                        
                      </Stack>
                    </Box>
                    <Divider />
                    <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
                      <Box
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        onClick={() => handleRemoveIngredient(ingredient)}
                        sx={{
                          backgroundColor: "red",
                          "&:hover": {
                            backgroundColor: "red",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "red",
                            borderColor: "none",
                          },
                        }}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Paper>
  
          {/* transfer to process */}
          {confirmProcess!.transfersToProcess != null && confirmProcess!.transfersToProcess.length > 0 ? (
            <Paper style={{ backgroundColor: "#EFEFEF" }}>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ padding: 1 }} variant="h5">
                  Inventories to Transfer for Processing
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
              </Stack>
  
              <Divider sx={{ marginBottom: 1 }} />
  
              <Box
                style={{
                  height: "25vh",
                  maxHeight: "25vh",
                  overflow: "auto",
                }}
              >
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  {processToConfirm!.transfersToProcess.map((toTransfer) => (
                    <Card key={toTransfer.id}>
                      <Box sx={{ flexGrow: 0, padding: 2 }}>
                        <Typography variant="h6">
                          {toTransfer.quantity} {toTransfer.unit} of{" "}
                          {toTransfer.inventoryItem} from:{" "}
                          {
                            departmentOptions[toTransfer.providingDepartment]
                              .label
                          }{" "}
                          Department
                        </Typography>
                      </Box>
                      <Divider />
                      <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
                        <Box
                          sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "flex" },
                          }}
                        />
                        <Button
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          onClick={() => handleRemoveTransfer(toTransfer)}
                          sx={{
                            backgroundColor: "red",
                            "&:hover": {
                              backgroundColor: "red",
                              borderColor: "none",
                              boxShadow: "none",
                            },
                            "&:active": {
                              boxShadow: "none",
                              backgroundColor: "red",
                              borderColor: "none",
                            },
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
              </Box>
            </Paper>
          ) : (
            <></>
          )}
        </Stack>
      </>
    );
  });
  