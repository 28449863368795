import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { IncomingStockFormValues } from "../../models/Inventories/inventory";

export default class IncomingStockStore {
    // selectedIncomingStock: Item | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this);
    }

    // getItems = () => {
    //     return Array.from(this.itemRegistry.values());
    // }

    // loadIncomingStock = async (id: string) => {
    //     this.loadingInitial = true;
    //         try {
    //             var incomingstock = await agent.Items.detail(id);
    //             this.setItem(item);
    //             runInAction(() => {
    //                 this.selectedItem = item;
    //             })
    //             this.setLoadingInitial(false);
    //             return item;
    //         } catch (error) {
    //             console.log(error);
    //             this.setLoadingInitial(false);
    //         }
    // }

    // private setItem = (item: ItemList) => {
    //     this.itemRegistry.set(item.id, item);
    // }

    // private getSelectedItem = () => {
    //     return this.selectedItem;
    // }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createIncomingStock = async (incomingstock: IncomingStockFormValues) => {
        this.loading = true;
        try {
            var incomingStockId = (await agent.IncomingStock.create(incomingstock)).data;
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}