import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function NotFound() {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item>
          <Paper style={{backgroundColor: "#EFEFEF"}}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <SearchIcon fontSize="large" sx={{ alignContent: "center", marginTop: 3, marginBottom: 2}} />
              <Typography variant="h4" component="div" align="center"
              sx={{marginLeft: 3, marginRight: 3,}}>
                Oops - we've looked everywhere and could not find this.
              </Typography>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  margin: 3,
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    borderColor: "none",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "black",
                    borderColor: "none",
                  },
                }}
              >
                return
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
