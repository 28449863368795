import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Recipe, RecipeFormValues, RecipeList, RecipeOption } from "../../models/admin/recipe";
import { Pagination, PagingParams } from "../../models/pagination";

export default class RecipeStore {
    recipeRegistry = new Map<string, RecipeList>();
    nonePaginatedRecipeRegistry = new Map<string, RecipeList>();
    recipeOptions = new Map<string, RecipeOption>();
    nonePaginatedRecipeOptions = new Map<string, RecipeOption>();
    itemCount: number = 0;
    selectedRecipe: Recipe | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getRecipes = () => {
        return Array.from(this.recipeRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getNonePaginatedRecipes = () => {
        return Array.from(this.nonePaginatedRecipeRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    loadRecipe = async (id: string) => {
        this.loadingInitial = true;
            try {
                var recipe = await agent.Recipes.detail(id);
                this.setRecipe(recipe);
                runInAction(() => {
                    this.selectedRecipe = recipe;
                })
                this.setLoadingInitial(false);
                return recipe;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    getNonPaginatedRecipeOptions = async () => {
        this.loadingInitial = true;
        let menuOptionsPO = this.nonePaginatedRecipeRegistry;
        try {
            const option = await agent.Recipes.options();
            option.forEach(recipe => {
                let recipeOption: RecipeOption = {
                    value: recipe.id,
                    label: recipe.name
                }
                this.nonePaginatedRecipeOptions.set(recipe.id, recipeOption);
            });
            this.setLoadingInitial(false);
            return menuOptionsPO;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    loadNonePaginatedRecipe= async () => {
        this.loadingInitial = true;
        this.nonePaginatedRecipeRegistry.clear();
        try {
            const recipes = await agent.Recipes.options();
            recipes.forEach(recipe => {
                this.setNonePaginatedRecipe(recipe);
                let recipeOption: RecipeOption = {
                    value: recipe.id,
                    label: recipe.name
                }
                this.setNonePaginatedRecipeOption(recipeOption);
            })
            this.setLoadingInitial(false);
            return recipes;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadRecipes = async () => {
        this.loadingInitial = true;
        this.recipeRegistry.clear();
        try {
            const result = await agent.Recipes.list(this.axiosParams,"");
            result.data.forEach(recipe => {
                this.setRecipe(recipe);
                let recipeOption: RecipeOption = {
                    value: recipe.id,
                    label: recipe.name
                }
                this.setRecipeOption(recipeOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchRecipes = async (searchText: string) => {
        this.recipeRegistry.clear();
        try {
            const result = await agent.Recipes.list(this.axiosParams,searchText);
            result.data.forEach(recipe => {
                this.setRecipe(recipe);
                let recipeOption: RecipeOption = {
                    value: recipe.id,
                    label: recipe.name
                }
                this.setRecipeOption(recipeOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectRecipe = () => {
        this.selectedRecipe = undefined;
    }

    private setRecipe = (recipe: RecipeList) => {
        this.recipeRegistry.set(recipe.id, recipe);
    }

    private setNonePaginatedRecipe = (recipe: RecipeList) => {
        this.nonePaginatedRecipeRegistry.set(recipe.id, recipe);
    }

    private setRecipeOption = (recipeOption: RecipeOption) => {
        this.recipeOptions.set(recipeOption.value, recipeOption);
    }

    private setNonePaginatedRecipeOption = (recipeOption: RecipeOption) => {
        this.nonePaginatedRecipeOptions.set(recipeOption.value, recipeOption);
    }
    
    private getSelectedRecipe = () => {
        return this.selectedRecipe;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRecipe = async (recipe: RecipeFormValues) => {
        this.loading = true;
        try {
            var recipeId = (await agent.Recipes.create(recipe)).data;
            runInAction(async() => {
                this.loadRecipe(recipeId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateRecipe = async (recipe: RecipeFormValues) => {
        this.loading = true;
        try {
            var recipeId = (await agent.Recipes.update(recipe)).data;
            runInAction(async() => {
                this.loadRecipe(recipeId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveRecipe = async (recipeId: string) => {
        this.loading = true;
        try {
            await agent.Recipes.delete(recipeId);
            runInAction(async() => {
                this.loadRecipes().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}