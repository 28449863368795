import { Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function SupplierDetail() {
  const { supplierStore } = useStore();
  const { selectedSupplier } = supplierStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedSupplier
          ? selectedSupplier.name
          : "Select a supplier from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedSupplier
                ? "Address: " + selectedSupplier.address
                : "Address: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">
              {selectedSupplier
                ? "T.I.N: " + selectedSupplier.tin
                : "T.I.N: "}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
