import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { useStore } from "../../../app/stores/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArchiveIcon from "@mui/icons-material/Archive";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import TurnOverTransferForm from "./TurnOverTransferForm";
import { Inventory } from "../../../app/models/Inventories/inventory";

export default observer(function TransferRequestDetail() {
  const { transferRequestStore, inventoryStore, profileStore } = useStore();
  const { loadFullInventory, computedInventoryForTransfer } = inventoryStore;
  const {
    archiveTransferRequest,
    loadTransferRequests,
    selectedTransferRequest,
    unSelectTransferRequest,
  } = transferRequestStore;
  const [add, setAdd] = useState(false);
  const [inventoryToTransfer, setInventoryToTransfer] = useState<Inventory[]>(
    []
  );

  const handleReturnToList = () => {
    unSelectTransferRequest();
  };

  const handleArchive = () => {
    if (selectedTransferRequest != null) {
      archiveTransferRequest(selectedTransferRequest.id);
    }
    loadTransferRequests();
    handleReturnToList();
  };

  const handleTransfer = () => {
    setAdd(true);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const isReceivingDepartment = profileStore.isInDepartment(
    selectedTransferRequest?.receivingDepartment ?? 0
  );

  useEffect(() => {
    loadFullInventory().then(() => {
      setInventoryToTransfer(
        computedInventoryForTransfer(selectedTransferRequest!)
      );
    });
  }, [loadFullInventory, computedInventoryForTransfer, selectedTransferRequest]);
  return (
    <>
      <Stack spacing={2}>
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ padding: 1 }} variant="h5">
              {selectedTransferRequest
                ? selectedTransferRequest.requestNumber
                : "No Transfer Request Selected"}
            </Typography>
            <Stack
              sx={{ paddingRight: 2, paddingTop: 1.5 }}
              direction="row"
              spacing={2}
            >
              {selectedTransferRequest! ? (
                selectedTransferRequest.isTurnOver ? (
                  <Chip
                    size="small"
                    icon={<CheckCircleIcon />}
                    color="success"
                    label="Transfered"
                  />
                ) : (
                  <Chip
                    size="small"
                    icon={<PendingActionsIcon />}
                    color="warning"
                    label="In Progress"
                  />
                )
              ) : (
                <Chip
                  size="small"
                  icon={<PendingActionsIcon />}
                  color="default"
                  label="In Progress"
                />
              )}
            </Stack>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />
          <Box sx={{ paddingBottom: 2 }}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Item to transfer: " + selectedTransferRequest.item
                      : "Item to transfer: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Quantity: " +
                        selectedTransferRequest.inventoryToTransferQuantity +
                        " " +
                        selectedTransferRequest.unit
                      : "Quantity: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Remarks: " + selectedTransferRequest.remarks
                      : "Remarks: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Request Date: " +
                        format(
                          new Date(selectedTransferRequest.createdDate!),
                          "dd MMM yyyy"
                        )
                      : "Request Date: "}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Providing Department: " +
                        departmentOptions[
                          selectedTransferRequest.providingDepartment
                        ].label
                      : "Providing Department: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? "Receiving Department: " +
                        departmentOptions[
                          selectedTransferRequest.receivingDepartment
                        ].label
                      : "Receiving Department: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest
                      ? selectedTransferRequest.location
                        ? "Receiving Storage: " +
                          selectedTransferRequest.location
                        : "Receiving Storage: Not yet specified"
                      : "Receiving Storage: "}
                  </Typography>

                  <Typography variant="h6">
                    {selectedTransferRequest!.completionDate
                      ? "Transfer Date: " +
                        format(
                          new Date(selectedTransferRequest!.completionDate),
                          "dd MMM yyyy"
                        )
                      : "Transfer Date: Still in progress"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleReturnToList}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Return to list
            </Button>

            <Button
              startIcon={<ArchiveIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleArchive}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Archive
            </Button>

            {selectedTransferRequest ? (
              selectedTransferRequest.isTurnOver ? (
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled
                >
                  Transfer
                </Button>
              ) : (
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={handleTransfer}
                  disabled={!isReceivingDepartment}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Transfer
                </Button>
              )
            ) : (
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled
              >
                Transfer
              </Button>
            )}
          </Stack>
          <Dialog open={add}>
            <DialogTitle>Confirm Transfer of Inventory</DialogTitle>
            <DialogContent>
              {/* input fields */}
              <TurnOverTransferForm closeForm={handleCloseAdd} />
            </DialogContent>
          </Dialog>
        </Paper>

        {/* inventories to transfer */}
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Inventories to Transfer
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box
            style={{
              height: "52vh",
              maxHeight: "52vh",
              overflow: "auto",
            }}
          >
            {inventoryToTransfer.length > 0 ? (
              selectedTransferRequest!.isTurnOver ? (
                <Typography
                  sx={{ padding: 1 }}
                  variant="h5"
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Inventories already transfered
                </Typography>
              ) : (
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  {inventoryToTransfer.map((inventory) => (
                    <Card key={inventory.id}>
                      <Box sx={{ flexGrow: 0, padding: 2 }}>
                        <Typography variant="h6">
                          {inventory.quantity} {inventory.unitOfMeasure} of{" "}
                          {inventory.itemName} from storage:{" "}
                          {inventory.locationName}
                          {inventory.isExpiring
                            ? " with expiration date of " + inventory.expiration
                            : ""}
                        </Typography>
                      </Box>
                    </Card>
                  ))}
                </Stack>
              )
            ) : (
              <Typography
                sx={{ padding: 1 }}
                variant="h5"
                style={{
                  color: "gray",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No available stock from{" "}
                {
                  departmentOptions[
                    selectedTransferRequest!.providingDepartment
                  ].label
                }
              </Typography>
            )}
          </Box>
        </Paper>
      </Stack>
    </>
  );
});
