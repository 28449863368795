import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { useStore } from "../../../app/stores/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArchiveIcon from "@mui/icons-material/Archive";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Inventory } from "../../../app/models/Inventories/inventory";
import RadarIcon from "@mui/icons-material/Radar";
import {
  IngredientsToConfirm,
  ProcessRequest,
  ProcessRequestList,
  RestockProcessRequestFormValues,
} from "../../../app/models/requests/processRequest";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CheckIcon from "@mui/icons-material/Check";
import CompleteProcessForm from "./CompleteProcessForm";
import TurnOverProcessForm from "./TurnOverProcessForm";
//   import CompleteProcessForm from "./CompleteProcessForm";
//   import TurnOverProcessForm from "./TurnOverProcessForm";

export default observer(function ProcessRequestDetail() {
  const { processRequestStore, inventoryStore } = useStore();
  const {
    loadInventory,
    findInventoryForRequest,
    loadingInitial,
    setLoadingInitial,
  } = inventoryStore;
  const {
    selectedProcessRequest,
    unSelectProcessRequest,
    restockProcessRequest,
    loading,
    isProcessReady,
    loadProcessRequest,
  } = processRequestStore;
  const [isWaitingForRestock, setIsWaitingForRestock] = useState(false);
  const [complete, setComplete] = useState(false);
  const [turnOver, setTurnOver] = useState(false);
  const [isProcessRequestReady, setIsReady] = useState(false);
  const [processRequest, setProcessRequest] = useState<ProcessRequest>({
    id: "",
    requestNumber: "",
    inProgress: true,
    isComplete: false,
    completionDate: null,
    createdDate: null,
    requestorName: "",
    processingDepartment: 1,
    serving: 0,
    recipeId: "",
    recipe: "",
    componentId: "",
    component: "",
    ingredients: null,
    expectedQuantity: 0,
    expectedUnit: "",
    discrepancyQuantity: 0,
    reason: "",
    actualQuantity: 0,
    locationId: "",
    location: "",
    isTurnOver: false,
    turnOverPersonnelId: "",
    turnOverPersonnel: "",
    turnOverDate: null,
  });
  const [hasCompleted, setHasCompleted] = useState(false);
  const handleReturnToList = () => {
    unSelectProcessRequest();
  };

  const handleArchive = () => {};

  const handleProcess = () => {
    setComplete(true);
  };

  const handleTurnOver = () => {
    setTurnOver(true);
  };

  const handleRestockCheck = (ingredientToRestock: IngredientsToConfirm) => {
    loadInventory().then(() => {
      let inventory = findInventoryForRequest(
        ingredientToRestock.itemId,
        ingredientToRestock.quantityToProcess,
        ingredientToRestock.unitIdToProcess,
        ingredientToRestock.processingDepartment
      );

      if (inventory.length > 0) {
        let restockProcess: RestockProcessRequestFormValues = {
          inventoryId: inventory[0].id,
          ingredientToProcessId: ingredientToRestock.id,
        };
        restockProcessRequest(restockProcess).then(() => {
          loadProcessRequest(selectedProcessRequest!.id);
        });
      }
    });
  };

  const handleCloseComplete = (completed: boolean) => {
    setComplete(false);
    if (completed) {
      setHasCompleted(true);
    }
  };

  const handleCloseTurnOver = () => {
    setTurnOver(false);
  };

  const handleCloseCompleted = () => {
    setHasCompleted(false);
  };

  const GridHead = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#505050",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#ffffff",
  }));

  const GridBody = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#e5e5e5",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    setIsReady(isProcessReady());
    setProcessRequest(selectedProcessRequest!);
    setLoadingInitial(false);

    const isWaiting = selectedProcessRequest?.ingredients?.some(
      (ingredient) => ingredient.location === "Waiting for restock"
    );
    setIsWaitingForRestock(isWaiting || false);
  }, [selectedProcessRequest, setLoadingInitial]);

  if (loadingInitial || loading)
    return <LoadingComponent content="Loading Process Requests..." />;

  return (
    <>
      <Stack spacing={2}>
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ padding: 1 }} variant="h5">
              {processRequest
                ? processRequest.requestNumber
                : "No Process Request Selected"}
            </Typography>
            <Stack
              sx={{ paddingRight: 2, paddingTop: 1.5 }}
              direction="row"
              spacing={2}
            >
              {processRequest! ? (
                processRequest.isTurnOver ? (
                  <Chip
                    size="small"
                    icon={<CheckCircleIcon />}
                    color="success"
                    label="Processed"
                  />
                ) : (
                  <Chip
                    size="small"
                    icon={<PendingActionsIcon />}
                    color="warning"
                    label="In Progress"
                  />
                )
              ) : (
                <Chip
                  size="small"
                  icon={<PendingActionsIcon />}
                  color="default"
                  label="In Progress"
                />
              )}
              {/* {processRequest! ? (
                  processRequest.inventoryId ? (
                    <></>
                  ) : (
                    <Chip
                      size="small"
                      icon={<PendingActionsIcon />}
                      color="warning"
                      label="Awaiting Restock"
                    />
                  )
                ) : (
                  <Chip
                    size="small"
                    icon={<PendingActionsIcon />}
                    color="default"
                    label="In Progress"
                  />
                )} */}
            </Stack>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />
          <Box sx={{ paddingBottom: 2 }}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {processRequest
                      ? "Recipe to Process: " + processRequest.recipe
                      : "Recipe to Process: "}
                  </Typography>

                  <Typography variant="h6">
                    {processRequest
                      ? "Serving: " + processRequest.serving
                      : "Serving: "}
                  </Typography>

                  <Typography variant="h6">
                    {processRequest
                      ? "Expected Quantity: " +
                        processRequest.expectedQuantity +
                        " " +
                        processRequest.expectedUnit
                      : "Expected Quantity: "}
                  </Typography>

                  <Typography variant="h6">
                    {processRequest
                      ? "Request Date: " +
                        format(
                          new Date(processRequest.createdDate!),
                          "dd MMM yyyy"
                        )
                      : "Request Date: "}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="h6">
                    {processRequest
                      ? "Processing Department: " +
                        departmentOptions[processRequest.processingDepartment]
                          .label
                      : "Processing Department: "}
                  </Typography>

                  <Typography variant="h6">
                    {processRequest
                      ? processRequest.location
                        ? "Receiving Storage: " + processRequest.location
                        : "Receiving Storage: Not yet specified"
                      : "Receiving Storage: "}
                  </Typography>

                  <Typography variant="h6">
                    {processRequest!.completionDate
                      ? "Process Date: " +
                        format(
                          new Date(processRequest!.completionDate),
                          "dd MMM yyyy"
                        )
                      : "Process Date: Still in progress"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleReturnToList}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Return to list
            </Button>

            {/* {processRequest.inventoryId ? (
                <></>
              ) : (
                <Button
                  startIcon={<RadarIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled={isProcessRequestReady}
                  onClick={handleRestockCheck}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Check restock
                </Button>
              )} */}

            <Button
              startIcon={<ArchiveIcon />}
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleArchive}
              sx={{
                backgroundColor: "orange",
                "&:hover": {
                  backgroundColor: "orange",
                  borderColor: "none",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "orange",
                  borderColor: "none",
                },
              }}
            >
              Archive
            </Button>

            {processRequest ? (
              processRequest.isTurnOver ? (
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled
                >
                  Process
                </Button>
              ) : processRequest.isComplete ? (
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={handleTurnOver}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Turn Over
                </Button>
              ) : (
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  disabled={isProcessRequestReady || isWaitingForRestock}
                  onClick={handleProcess}
                  sx={{
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "orange",
                      borderColor: "none",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "orange",
                      borderColor: "none",
                    },
                  }}
                >
                  Process
                </Button>
              )
            ) : (
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled
              >
                Process
              </Button>
            )}
          </Stack>
          <Dialog open={complete}>
            <DialogTitle>Confirm Process of Inventory</DialogTitle>
            <DialogContent>
              {/* input fields */}
              <CompleteProcessForm
                closeForm={() => handleCloseComplete(false)}
                onComplete={() => handleCloseComplete(true)}
              />
            </DialogContent>
          </Dialog>
          <Dialog open={turnOver}>
            <DialogTitle>Select Process Storage</DialogTitle>
            <DialogContent>
              {/* input fields */}
              <TurnOverProcessForm closeForm={handleCloseTurnOver} />
            </DialogContent>
          </Dialog>
          <Dialog open={hasCompleted} onClose={handleCloseCompleted}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  "& .css-i4bv87-MuiSvgIcon-root": {
                    "margin-bottom": "-5px",
                    "margin-left": "10px",
                    color: "#45D015",
                  },
                }}
              >
                Process Confirmed. ready for Turnover
                <CheckCircleIcon />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Paper>

        {/* process details */}
        <Paper style={{ backgroundColor: "#EFEFEF" }}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ padding: 1 }} variant="h5">
              Inventory to Process
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
          </Stack>

          <Divider sx={{ marginBottom: 1 }} />

          <Box
            style={{
              height: "52vh",
              maxHeight: "52vh",
              overflow: "auto",
            }}
          >
            <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
              {processRequest.ingredients?.map((ingredient) => (
                <Card key={ingredient.id}>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Stack spacing={5} direction="row">
                      <Typography variant="h6">
                        Item: {ingredient.item}
                      </Typography>
                      <Typography variant="h6">
                        Storage: {ingredient.location}
                      </Typography>
                      {ingredient.expirationDate ? (
                        <Typography variant="h6">
                          Expiration Date:{" "}
                          {format(
                            new Date(ingredient.expirationDate),
                            "dd MMM yyyy"
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="h6">Expiration Date:</Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 3 }}
                    >
                      {/* inventory to process */}
                      <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10}>
                          <GridHead>To Process</GridHead>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Quantity</Typography>
                              <Typography>
                                {ingredient.quantityToProcess}{" "}
                                {ingredient.unitToProcess}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={5}>
                          <GridBody>
                            <Stack>
                              <Typography>Alt Quantity</Typography>
                              <Typography>
                                {ingredient.altQuantity > 1000
                                  ? ingredient.altQuantity / 1000
                                  : ingredient.altQuantity}{" "}
                                {ingredient.altUnit}
                              </Typography>
                            </Stack>
                          </GridBody>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Divider />
                  <Stack direction="row" spacing={1} sx={{ padding: 1 }}>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                    />
                    <Button
                      startIcon={<RadarIcon />}
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      disabled={isProcessRequestReady}
                      onClick={() => {
                        handleRestockCheck(ingredient);
                      }}
                      sx={{
                        backgroundColor: "orange",
                        "&:hover": {
                          backgroundColor: "orange",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "orange",
                          borderColor: "none",
                        },
                      }}
                    >
                      Check restock
                    </Button>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Box>
        </Paper>
      </Stack>
    </>
  );
});
