import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import React from "react";
import PurchaseOrderList from "./PurchaseOrderList";
import PurchaseOrderDetail from "./PurchaseOrderDetail";
export default observer(function ViewPurchaseOrderRequest() {
  const { franchiseeOrderRequestStore } = useStore();
  const { selectedFranchiseePurchaseOrder } = franchiseeOrderRequestStore;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          View Purchase Order
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>
          <Grid item xs={12} md={12} lg={8}>
            {selectedFranchiseePurchaseOrder ? (
                <PurchaseOrderDetail />
              ) : (
                <PurchaseOrderList />
              )}
          </Grid>
          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
