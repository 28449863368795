import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { SupplierFormValues } from "../../../app/models/admin/suppliers";

interface Props {
  closeForm: () => void;
}

export default observer(function SupplierForm({ closeForm }: Props) {
  const { supplierStore } = useStore();
  const {
    selectedSupplier,
    createSupplier,
    updateSupplier,
    loadingInitial,
  } = supplierStore;
  const [title, setTitle] = useState<string>("Create Supplier");
  const [supplierFormValues, setSupplierForm] = useState<SupplierFormValues>({
    id: "",
    name: "",
    address: "",
    tin: "",
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    address: yup.string().required("Required"),
    tin: yup.string().required("Required")
  });

  useEffect(() => {
    if (selectedSupplier) {
      setTitle("Update Supplier");
      let formData: SupplierFormValues = {
        id: selectedSupplier.id,
        name: selectedSupplier.name,
        address: selectedSupplier.address,
        tin: selectedSupplier.tin
      };
      setSupplierForm(formData);
    }
  }, [selectedSupplier]);

  function handleFormSubmit(supplierFormValues: SupplierFormValues) {
    let supplier: SupplierFormValues = {
      ...supplierFormValues,
    };
    if (!selectedSupplier) {
        createSupplier(supplier).then(() => closeForm());
    } else {
        updateSupplier(supplier).then(() => closeForm());
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading Category..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={supplierFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty
          }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in category information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>

                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Supplier Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="address"
                      label="Address"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="tin"
                      label="T.I.N"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
