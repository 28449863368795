import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../app/stores/store";

export default observer(function PurchaseOrderConfirmation() {
  const { franchiseeOrderRequestStore } = useStore();
  const { confirmedOrder } = franchiseeOrderRequestStore;

  useEffect(() => {}, []);

  // if (loadingInitial) return <LoadingComponent content="Loading Menus..." />;

  return (
    <>
      <Box
        style={{
          height: "67vh",
          maxHeight: "67vh",
          overflow: "auto",
        }}
        sx={{ backgroundColor: "#EFEFEF", padding: 5 }}
      >
        <Typography sx={{ padding: 1 }} variant="h4">
          Confirmation
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="h6"
          color="text.secondary"
        >
          Purchase order: {confirmedOrder!.franchiseeOrderRequestNumber} has
          been submitted
        </Typography>

        {confirmedOrder &&
        confirmedOrder.insufficientStockComponents.length > 0 ? (
          <>
            <Typography
              sx={{ paddingLeft: 1, paddingBottom: 1 }}
              variant="h6"
              color="text.secondary"
            >
              The following items have insufficient stock:
            </Typography>
            {confirmedOrder.insufficientStockComponents.map(
              (component, index) => (
                <Grid container>
                  <Grid item key={index} xs={12}>
                    <Typography sx={{ paddingTop: .5, paddingLeft: 5, color: "red" }} variant="h6">
                      {index + 1}. {component.component}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
});
