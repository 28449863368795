import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { history } from "../../..";
import { LocationsOption } from "../../../app/models/admin/location";
import { CompleteRepackRequestFormValues } from "../../../app/models/requests/repackRequest";
import MyDateInput from "../../../app/common/form/MyDateInput";

interface Props {
  closeForm: () => void;
}

export default observer(function CompleteRepackForm({ closeForm }: Props) {
  const { locationStore, repackRequestStore } = useStore();
  const {
    loadLocations,
    findLocationByDepartment,
    loadingInitial: loadingLocation,
  } = locationStore;
  const {
    completeRepackRequest,
    selectedRepackRequest,
    loadingInitial: loadingRepack,
  } = repackRequestStore;
  const [locationOptions, setLocationOptions] = useState<LocationsOption[]>([]);
  const [completeRequestFormValues, setCompleteRepackRequestFormValues] =
    useState<CompleteRepackRequestFormValues>({
      id: "",
      actualQuantity: selectedRepackRequest!.expectedQuantity,
      expirationDate:null,
      reason: "",
    });

  const validationSchema = yup.object().shape({
    actualQuantity: yup.string().required("Required"),
  });

  useEffect(() => {}, []);

  function handleFormSubmit(
    completeRequestFormValues: CompleteRepackRequestFormValues
  ) {
    let completeRequestForm: CompleteRepackRequestFormValues = {
      ...completeRequestFormValues,
    };
    completeRequestForm.id = selectedRepackRequest!.id;
    completeRepackRequest(completeRequestForm).then(() => {
      history.push(`/history/Repack`);
      closeForm();
    });
  }

  if (loadingLocation && loadingRepack)
    return <LoadingComponent content="Loading Repack Request..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={completeRequestFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Input actual repacked quantity
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="actualQuantity"
                      label="Actual Quantity"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <>
                    <Typography variant="body2" color="text.secondary">
                      Expiration Date
                    </Typography>
                      <MyDateInput
                        name="expirationDate"
                        placeholderText="Expiration Date"
                        dateFormat="MMMM d, yyyy"
                      />
                    </>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field
                      name="reason"
                      label="Reason for discrepancy"
                      size="small"
                      multiline
                      rows={3}
                      component={FormTextField}
                    />
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Complete Repack
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
