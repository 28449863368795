import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ComponentsToOrder, FranchiseePurchaseOrderRequest, FranchiseePurchaseOrderRequestFormValues,ApprovedPOFormValues, FranchiseePurchaseOrderRequestList, GetComponentsToOrder, OrderConfirmation } from "../../models/requests/franchiseePurchaseOrderRequest";
import { Pagination, PagingParams } from "../../models/pagination";

export default class FranchiseeOrderRequestStore {
    franchiseeOrderRequestRegistry = new Map<string, FranchiseePurchaseOrderRequestList>();
    componentsToOrder: ComponentsToOrder[] = [];
    selectedFranchiseeOrderRequest: FranchiseePurchaseOrderRequest | undefined = undefined;
    confirmedOrder: OrderConfirmation | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    selectedFranchiseePurchaseOrder: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getFranchiseeOrderRequests = () => {
        return Array.from(this.franchiseeOrderRequestRegistry.values());
    }

    loadCartItems = async (franchiseeOrderQuantity: Map<string, number>) => {
        this.loading = true;
        try {
            const serializedMap: { [key: string]: number } = Object.fromEntries(franchiseeOrderQuantity);
            let getComponentsToOrder: GetComponentsToOrder =
            {
                franchiseeOrderQuantity: serializedMap
            };
            console.log("getComponentsToOrder:", getComponentsToOrder);
            this.componentsToOrder = await agent.FranchiseePurchaseOrderRequests.cart(getComponentsToOrder);
            runInAction(() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    getComponentsToOrder = () => {
        return this.componentsToOrder.sort((a: ComponentsToOrder, b: ComponentsToOrder) => a.quantity - b.quantity);
    }

    loadFranchiseeOrderRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                this.selectedFranchiseeOrderRequest=undefined;
                var franchiseeOrderRequest = await agent.FranchiseePurchaseOrderRequests.detail(id);
                runInAction(() => {
                    this.selectedFranchiseeOrderRequest = franchiseeOrderRequest;
                })
                this.setLoadingInitial(false);
                return franchiseeOrderRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadFranchiseeOrderRequests = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.FranchiseePurchaseOrderRequests.list(this.axiosParams);
            result.data.forEach(franchiseeOrderRequest => {
                this.setFranchiseeOrderRequest(franchiseeOrderRequest);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setFranchiseeOrderRequest = (franchiseePurchaseOrderRequest: FranchiseePurchaseOrderRequestList) => {
        this.franchiseeOrderRequestRegistry.set(franchiseePurchaseOrderRequest.id, franchiseePurchaseOrderRequest);
    }

    private getSelectedFranchiseeOrderRequest = () => {
        return this.selectedFranchiseeOrderRequest;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    unTrackConfirmedOrder = () => {
        this.confirmedOrder = undefined;
    }

    createFranchiseeOrderRequest = async (franchiseeOrderRequest: FranchiseePurchaseOrderRequestFormValues) => {
        this.loading = true;
        try {
            var orderConfirmation = (await agent.FranchiseePurchaseOrderRequests.create(franchiseeOrderRequest)).data;
            runInAction(() => {
                this.confirmedOrder = orderConfirmation;
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    loadProcessOrderDetails =  async (id: string) => {
            try {
                runInAction(() => {
                    this.selectedFranchiseePurchaseOrder = id;
                })
                return "";
            } catch (error) {
            }
    }

    unSelectProcessOrder= () => {
        this.selectedFranchiseePurchaseOrder = undefined;
    }
}