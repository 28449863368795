import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField"
import { UnitsOption } from "../../../app/models/units";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { departmentOptions } from "../../../app/common/options/departmentOptions";
import { history } from "../../..";
import { LocationsOption } from "../../../app/models/admin/location";
import { TurnOverRepackRequestFormValues } from "../../../app/models/requests/repackRequest";

interface Props {
  closeForm: () => void;
}

export default observer(function TurnOverRepackForm({ closeForm }: Props) {
  const { locationStore, repackRequestStore } = useStore();
  const { loadLocations, findLocationByDepartment, loadingInitial: loadingLocation } = locationStore;
  const {
    turnOverRepackRequest,
    selectedRepackRequest,
    loadingInitial: loadingRepack,
  } = repackRequestStore;
  const [locationOptions, setLocationOptions] = useState<LocationsOption[]>([]);
  const [turnOverRepackRequestFormValues, setTurnOverRepackRequestFormValues] =
    useState<TurnOverRepackRequestFormValues>({
      id: "",
      locationId: ""
    });

  const validationSchema = yup.object().shape({
    locationId: yup.string().required("Required")
  });

  useEffect(() => {
    loadLocations().then(() => {
      let locations = findLocationByDepartment(selectedRepackRequest!.repackingDepartment);
      let lOptions = new Map<string, UnitsOption>();
      locations.forEach((location) => {
        let lOption: LocationsOption = {
          value: location.id,
          label: location.name,
        };
        lOptions.set(lOption.value, lOption);
      });
      setLocationOptions(Array.from(lOptions.values()));
    });
  }, [findLocationByDepartment, selectedRepackRequest, loadLocations]);

  function handleFormSubmit(
    turnOverRequestFormValues: TurnOverRepackRequestFormValues
  ) {
    let turnOverRequestForm: TurnOverRepackRequestFormValues = {
      ...turnOverRequestFormValues,
    };
    turnOverRequestForm.id = selectedRepackRequest!.id;
    turnOverRepackRequest(turnOverRequestForm).then(() =>{
      history.push(`/history/Repack`);
      closeForm();
    });
  }

  if (loadingLocation && loadingRepack)
    return <LoadingComponent content="Loading Repack Request..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={turnOverRepackRequestFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Select location to store inventory
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  

                  <Grid item xs={12}>
                    <Field
                      name="locationId"
                      label="Location"
                      size="small"
                      options={locationOptions}
                      component={FormSelect}
                    />
                  </Grid>

                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Request Repack
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
