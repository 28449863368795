import { Chip, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

export default observer(function RecipeDetail() {
  const { recipeStore } = useStore();
  const { selectedRecipe } = recipeStore;

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingLeft: 1}}>
        {selectedRecipe
          ? selectedRecipe.name
          : "Select a Recipe from the list to view details"}
      </Typography>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>

            <Typography variant="subtitle1">
              {selectedRecipe
                ? "Component: " + selectedRecipe.component
                : "Component: "}
            </Typography>

            <Typography variant="subtitle1">
              {selectedRecipe
                ? "Serving: " + selectedRecipe.serving
                : "Serving: "}
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {/* content here */}
            <Typography variant="subtitle1">
              Ingredients
            </Typography>
            {selectedRecipe 
            ? selectedRecipe.ingredients!.map((ingredient) => (
                <Chip key={ingredient.id} color="default"
                label={ingredient.name}/>
            )) : ""}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});
