import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CategoryForm from "./CategoryForm";
import { useEffect, useState } from "react";
import { Category } from "../../../app/models/admin/category";

export default observer(function CategoryList() {
  const { categoryStore } = useStore();
  const {
    archiveCategory,
    selectCategory,
    selectedCategory,
    getCategories
  } = categoryStore;
  const [archive, setArchive] = useState(false);
  const [update, setUpdate] = useState(false);

  const handleUpdate = () => {
    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };

  const handleEdit = (id: string) => {
    selectCategory(id);
    handleUpdate();
  };

  const handleArchive = (id: string) => {
    selectCategory(id);
    setArchive(true);
  };

  const handleCloseArchive = () => {
    setArchive(false);
  };

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getCategories().map((category) => (
          <Card key={category.id}>
            <Stack direction="row" spacing={2}>
              <Box sx={{ flexGrow: 0, padding: 2 }}>
                <Typography variant="h5">{category.name}</Typography>
                <Typography variant="subtitle1">
                  {category
                    ? "Last updated: " +
                      format(category.lastUpdated!, "dd MMM yyyy")
                    : "Last updated: "}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
              <Box sx={{ flexGrow: 0, padding: 2 }}>
                <Stack direction="row" spacing={1}>
                  <IconButton onClick={() => handleEdit(category.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleArchive(category.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Dialog open={update}>
                  <DialogTitle>Update Category</DialogTitle>
                  <DialogContent>
                    {/* input fields */}
                    <CategoryForm closeForm={handleCloseUpdate} />
                  </DialogContent>
                </Dialog>
                <Dialog open={archive}>
                  <DialogTitle>Archive Category</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to archive this category?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseArchive}
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "black",
                          borderColor: "none",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() =>
                        selectedCategory != null
                          ? archiveCategory(selectedCategory.id)
                          : null
                      }
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "black",
                          borderColor: "none",
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Stack>
          </Card>
        ))}
      </Stack>
    </>
  );
});
