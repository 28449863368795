import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
  } from "@mui/material";
  import { observer } from "mobx-react-lite";
  import { useStore } from "../../../app/stores/store";
import ConfirmProcessRequest from "./ConfirmProcessRequest";
import SelectItemsToProcess from "./SelectItemsToProcess";
  
  export default observer(function CreateProcessRequest() {
    const { processRequestStore } = useStore();
    const { filledProcessForm } = processRequestStore;
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ padding: 1 }} variant="h3">
            Create Process Request
          </Typography>
          <Typography
            sx={{ paddingLeft: 1, paddingBottom: 1 }}
            variant="body2"
            color="text.secondary"
          >
            This is where we can create a process request
          </Typography>
  
          <Divider sx={{ marginBottom: 2 }} />
  
          <Grid container direction="row" spacing={0}>
            <Grid item xs={0} md={0} lg={2}></Grid>
  
            <Grid item xs={12} md={12} lg={8}>
              { filledProcessForm ? (
                  <ConfirmProcessRequest />
                ) : (
                  <SelectItemsToProcess />
                )}
              
            </Grid>
  
            <Grid item xs={0} md={0} lg={2}></Grid>
          </Grid>
        </Box>
      </>
    );
  });
  