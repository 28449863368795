import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Role, RoleFormValues, RoleList, RolesOption } from "../../models/admin/role";
import { Pagination, PagingParams } from "../../models/pagination";

export default class RoleStore {
    roleRegistry = new Map<string, RoleList>();
    roleOptions = new Map<string, RolesOption>();
    itemCount: number = 0;
    selectedRole: Role | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getRoles = () => {
        return Array.from(this.roleRegistry.values()).sort((a, b) => a.name.localeCompare(b.name));
    }

    getRoleOptions = () => {
        return Array.from(this.roleOptions.values());
    }

    loadRole = async (id: string) => {
        this.loadingInitial = true;
            try {
                var Role = await agent.Roles.detail(id);
                this.setRole(Role);
                runInAction(() => {
                    this.selectedRole = Role;
                })
                this.setLoadingInitial(false);
                return Role;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadRoles = async (options: boolean = false) => {
        this.loadingInitial = true;
        this.roleRegistry.clear();
        try {
            const result = await agent.Roles.list(this.axiosParams, "", options);
            result.data.forEach(Role => {
                this.setRole(Role);
                let RoleOption: RolesOption = {
                    value: Role.id,
                    label: Role.name
                }
                this.setRoleOption(RoleOption);
            })
            if (!options) {
                this.setPagination(result.pagination);
            }
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }    

    searchRoles = async (searchText: string) => {
        this.roleRegistry.clear();
        try {
            const result = await agent.Roles.list(this.axiosParams,searchText);
            result.data.forEach(Role => {
                this.setRole(Role);
                let RoleOption: RolesOption = {
                    value: Role.id,
                    label: Role.name
                }
                this.setRoleOption(RoleOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    unSelectRole = () => {
        this.selectedRole = undefined;
    }

    private setRole = (Role: RoleList) => {
        this.roleRegistry.set(Role.id, Role);
    }

    private setRoleOption = (RoleOption: RolesOption) => {
        this.roleOptions.set(RoleOption.value, RoleOption);
    }

    private getSelectedRole = () => {
        return this.selectedRole;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRole = async (role: RoleFormValues) => {
        this.loading = true;
        try {
            var roleId = (await agent.Roles.create(role)).data;
            runInAction(async() => {
                this.loadRole(roleId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateRole = async (role: RoleFormValues) => {
        this.loading = true;
        try {
            var roleId = (await agent.Roles.update(role)).data;
            runInAction(async() => {
                this.loadRole(roleId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveRole = async (roleId: string) => {
        this.loading = true;
        try {
            await agent.Roles.delete(roleId);
            runInAction(async() => {
                this.loadRoles().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}