import { Box, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { TabPanel } from "../../app/common/panel/TabPanel";
import { useStore } from "../../app/stores/store";
import EmployeeProfile from "./EmployeeProfile";
import FranchiseeProfile from "./FranchiseeProfile";
import ViewEmployee from "./ViewEmployee";
import ViewFranchisee from "./ViewFranchisee";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default observer(function ManageProfile() {
  const { userStore } = useStore();
  const { user, checkIfProfileCreated } = userStore;
  const [value, setValue] = React.useState(0);
  const [isEmployee, setIsEmployee] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (checkIfProfileCreated() && user!.userProfile == 1) {
      setIsEmployee(true);
    } else if (checkIfProfileCreated() && user!.userProfile == 2) {
      setIsEmployee(false);
    }
  }, [checkIfProfileCreated]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Profile
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          Select a tab to view or update your profile
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="View" {...a11yProps(0)} />
            <Tab label="Update" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {isEmployee ? <ViewEmployee /> : <ViewFranchisee />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isEmployee ? <EmployeeProfile /> : <FranchiseeProfile />}
        </TabPanel>
      </Box>
    </>
  );
});
