import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Menu, MenuFormValues, MenusOption } from "../../models/admin/menus";
import { Pagination, PagingParams } from "../../models/pagination";

export default class MenuStore {
    menuRegistry = new Map<string, Menu>();
    nonePaginatedMenuRegistry = new Map<string, Menu>();
    menuOptions = new Map<string, MenusOption>();
    nonePaginatedMenuOptions = new Map<string, MenusOption>();
    franchiseeOrderQuantity = new Map<string, number>();
    itemCount: number = 0;
    selectedMenu: Menu | undefined = undefined;
    selectedNonePaginatedMenu: Menu | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    menus: Menu[] = [];
    nonePaginatedMenu: Menu[] = [];
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }
    
    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    searchNonePaginatedMenus = (searchFilter: string) => {
        this.nonePaginatedMenu = Array.from(this.nonePaginatedMenuRegistry.values()).filter(x => x.name.toLowerCase().includes(searchFilter.toLowerCase()));
    }

    populateMenus = () => {
        this.menus =  Array.from(this.menuRegistry.values());
    }

    populateNonePaginatedMenus = () => {
        this.nonePaginatedMenu =  Array.from(this.nonePaginatedMenuRegistry.values());
    }

    getMenus = (filter: string = "") => {
        if(!filter) return Array.from(this.menuRegistry.values());

        return Array.from(this.menuRegistry.values()).filter((menu) => {
            return (menu.classificationName == filter)
        });
    }
    
    getNonPaginatedMenus = (filter: string = "") => {
        if(!filter) return Array.from(this.nonePaginatedMenuRegistry.values());

        return Array.from(this.nonePaginatedMenuRegistry.values()).filter((menu) => {
            return (menu.classificationName == filter)
        });
    }

    getNonPaginatedMenuOptions = async () => {
        this.loadingInitial = true;
        let menuOptionsPO = this.nonePaginatedMenuOptions;
        try {
            const menus = await agent.Menus.options();
            menus.forEach(menu => {
                let menuOption: MenusOption = {
                    value: menu.id,
                    label: menu.name
                }
                this.nonePaginatedMenuOptions.set(menu.id, menuOption);
            });
            this.setLoadingInitial(false);
            return menuOptionsPO;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadMenu = async (id: string) => {
        this.loadingInitial = true;
        try {
            let menu = await agent.Menus.detail(id);
            this.setMenu(menu);
            runInAction(() => {
                this.selectedMenu = menu;
            })
            this.populateMenus();
            this.setLoadingInitial(false);
            return menu;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadMenus = async () => {
        this.loadingInitial = true;
        this.menuRegistry.clear();
        try {
            const result = await agent.Menus.list(this.axiosParams, "");
            result.data.forEach(menu => {
                this.setMenu(menu);
                let menuOption: MenusOption = {
                    value: menu.id,
                    label: menu.name
                }
                this.setMenuOption(menuOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchMenus = async (searchText: string) => {
        this.menuRegistry.clear();
        try {
            const result = await agent.Menus.list(this.axiosParams, searchText);
            result.data.forEach(menu => {
                this.setMenu(menu);
                let menuOption: MenusOption = {
                    value: menu.id,
                    label: menu.name
                }
                this.setMenuOption(menuOption);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
            return result;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }


    loadNonePaginatedMenus = async () => {
        this.loadingInitial = true;
        this.nonePaginatedMenuRegistry.clear();
        try {
            const menus = await agent.Menus.options();
            menus.forEach(menu => {
                this.setNonePaginatedMenu(menu);
                let menuOption: MenusOption = {
                    value: menu.id,
                    label: menu.name
                }
                this.setNonePaginatedMenuOption(menuOption);
            })
            this.setLoadingInitial(false);
            return menus;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    selectMenu = (id: string) => {
        this.selectedMenu = this.menuRegistry.get(id);
    }

    selectNonePaginatedMenu = (id: string) => {
        this.selectedNonePaginatedMenu = this.nonePaginatedMenuRegistry.get(id);
    }

    unSelectMenu = () => {
        this.selectedMenu = undefined;
    }

    unSelectNonePaginatedMenu = () => {
        this.selectedNonePaginatedMenu = undefined;
    }

    private setMenu = (menu: Menu) => {
        this.menuRegistry.set(menu.id, menu);
    }

    private setNonePaginatedMenu = (menu: Menu) => {
        this.nonePaginatedMenuRegistry.set(menu.id, menu);
    }

    private setMenuOption = (menuOption: MenusOption) => {
        this.menuOptions.set(menuOption.value, menuOption);
    }

    private setNonePaginatedMenuOption = (menuOption: MenusOption) => {
        this.nonePaginatedMenuOptions.set(menuOption.value, menuOption);
    }

    private getMenu = (id: string) => {
        return this.menuRegistry.get(id);
    }

    private getNonePaginatedMenu = (id: string) => {
        return this.nonePaginatedMenuRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createMenu = async (menu: MenuFormValues) => {
        this.loading = true;
        try {
            var menuId = (await agent.Menus.create(menu)).data;
            runInAction(async() => {
                console.log("created")
                this.loadMenu(menuId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateMenu = async (menu: MenuFormValues) => {
        this.loading = true;
        try {
            var menuId = (await agent.Menus.update(menu)).data;
            runInAction(async() => {
                this.loadMenu(menuId).then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveMenu = async (menuId: string) => {
        this.loading = true;
        try {
            await agent.Menus.delete(menuId);
            runInAction(async() => {
                this.loadMenus().then(() => {
                    this.editMode = false;
                    this.loading = false;
                });
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}