import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { EmployeeProfileFormValues, Profile } from "../../app/models/profile";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default observer(function ViewFranchisee() {
  const { profileStore, commonStore } = useStore();
  const { loadFranchiseeProfile, loading, loadingInitial } = profileStore;
  const [profile, setProfile] = useState<Profile>({
    name: "",
    profilePhoto: "",
    address: "",
    email: "",
    phoneNumber: "",
    dateOfRegistration: null,
  });

  useEffect(() => {
    loadFranchiseeProfile().then((franchiseeProfile) => {
      let profile: Profile = {
        name: franchiseeProfile!.companyName,
        profilePhoto: franchiseeProfile!.profilePhoto,
        address: franchiseeProfile!.address,
        email: franchiseeProfile!.email,
        phoneNumber: franchiseeProfile!.phoneNumber,
        dateOfRegistration: franchiseeProfile?.dateOfRegistration!,
      };
      setProfile(profile);
    });
  }, [loadFranchiseeProfile]);

  if (loadingInitial) return <LoadingComponent content="Loading Profile..." />;

  return (
    <>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
        <Card style={{ backgroundColor: "#EFEFEF", height: "100%" }}>
            <CardHeader
              avatar={
                <Avatar
                  alt={profile.name}
                  src={profile.profilePhoto}
                  sx={{ width: 150, height: 150 }}
                />
              }
              title={<Typography variant="h3">{profile.name}</Typography>}
              subheader={
                <Typography variant="h5" color="text.secondary">
                  {profile.email}
                </Typography>
              }
            />
            <CardContent>
              <Stack direction="row" spacing={5}>
                <Stack>
                  <Typography variant="h5">Address</Typography>
                  <Typography variant="h6" color="text.secondary">
                    {profile.address}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5">Mobile Number</Typography>
                  <Typography variant="h6" color="text.secondary">
                    {profile.phoneNumber}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
});
