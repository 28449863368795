import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import * as yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  Avatar,
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../app/common/form/FormTextField";
import { string } from "yup/lib/locale";
import { FormSelect } from "../../../app/common/form/FormSelect";
import { LocationFormValues } from "../../../app/models/admin/location";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ImageIcon from "@mui/icons-material/Image";
import commonStore from "../../../app/stores/commonStore";
import { departmentOptions } from "../../../app/common/options/departmentOptions";

interface Props {
  closeForm: () => void;
}

export default observer(function LocationForm({ closeForm }: Props) {
  const { locationStore, commonStore } = useStore();
  const { selectedLocation, createLocation, updateLocation, loadingInitial } =
    locationStore;
  const [title, setTitle] = useState<string>("Create Location");
  const [locationFormValues, setLocationForm] = useState<LocationFormValues>({
    id: "",
    name: "",
    description: "",
    department: 0,
    photo: "",
  });
  const { uploadPhoto } = commonStore;
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const [baseImage, setBaseImage] = useState("");

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    department: yup.number().required("Required")
  });

  useEffect(() => {
    if (selectedLocation) {
      setTitle("Update Location");
      setBaseImage(selectedLocation.photo);
      let formData: LocationFormValues = {
        id: selectedLocation.id,
        name: selectedLocation.name,
        description: selectedLocation.description,
        department: selectedLocation.department,
        photo: selectedLocation.photo,
      };
      setLocationForm(formData);
    }
  }, [selectedLocation]);

  const handleUploadPhoto = async (e: any) => {
    setBaseImage(await uploadPhoto(e));
    setIsPhotoUploaded(true);
  };

  function handleFormSubmit(locationFormValues: LocationFormValues) {
    let location: LocationFormValues = {
      ...locationFormValues,
      photo: baseImage,
    };
    if (!selectedLocation) {
      createLocation(location).then(() => closeForm());
    } else {
      updateLocation(location).then(() => closeForm());
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading Location..." />;

  return (
    <>
      <Grid container spacing={0}>
        <Formik
          initialValues={locationFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* header start */}
              <Grid item sx={{ padding: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Please fill in location information
                </Typography>
              </Grid>
              {/* header end */}

              {/* Form */}
              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}
              >
                {/* input fields start */}
                <Grid item container spacing={2} xs={12} md={12}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Location Name"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="description"
                      label="Description"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="department"
                      label="Department"
                      size="small"
                      options={departmentOptions}
                      component={FormSelect}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack spacing={2}>
                      <Avatar
                        variant="square"
                        src={baseImage}
                        sx={{ width: 150, height: 150 }}
                      >
                        <ImageIcon sx={{ width: 250, height: 150 }} />{" "}
                      </Avatar>
                      <Button
                        component="label"
                        startIcon={<AddAPhotoIcon />}
                        variant="contained"
                        size="small"
                        color="primary"
                        disableElevation
                        sx={{
                          backgroundColor: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            borderColor: "none",
                            boxShadow: "none",
                          },
                          "&:active": {
                            boxShadow: "none",
                            backgroundColor: "black",
                            borderColor: "none",
                          },
                        }}
                      >
                        Upload Photo
                        <input
                          name="photo"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          hidden
                          onChange={(e) => {
                            handleUploadPhoto(e);
                          }}
                        />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                {/* input fields end */}
              </Grid>
              {/* form end */}

              {/* button start */}
              <Grid item sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    {title}
                  </Button>
                  <Button
                    onClick={closeForm}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isSubmitting}
                    disableElevation
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        borderColor: "none",
                        boxShadow: "none",
                      },
                      "&:active": {
                        boxShadow: "none",
                        backgroundColor: "black",
                        borderColor: "none",
                      },
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
              {/* button end */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
