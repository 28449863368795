import React from "react";
import { FieldProps, getIn } from "formik";
import { TextFieldProps, TextField, MenuItem } from "@mui/material";

export const FormSelect: React.FC<
  FieldProps & TextFieldProps & { options: { value: string; label: string }[] }
> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { options, error, helperText, field, form, ...rest } = props;

  return (
    <TextField
      FormHelperTextProps={{ sx: { backgroundColor: "#EFEFEF", margin: 0 } }}
      select
      sx={{
        "& .MuiInputLabel-root.Mui-focused": { color: "gray" }, //styles the label
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": { borderColor: "gray" },
        },
        backgroundColor: "white",
      }}
      fullWidth
      variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      {...rest}
      {...field}
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
