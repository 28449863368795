import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { history } from "../..";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";

export default observer(function Dashboard() {
  const { userStore, profileStore } = useStore();
  const { checkIfProfileCreated, getUser, user} = userStore;
  const { loadFranchiseeProfile, loadEmployeeProfile, loadingInitial} = profileStore;
  useEffect(() => {
    getUser().then(() => {
      if(!checkIfProfileCreated()){
        history.push("/create-profile");
      } else {
        if(user!.userProfile === 1){
          loadEmployeeProfile();
        }else if(user!.userProfile === 2){
          loadFranchiseeProfile()
        }
      }
    });
  }, [checkIfProfileCreated]);

  if (loadingInitial) return <LoadingComponent content="Loading Dashboard..." />;

  return (
    <></>
  );
});
