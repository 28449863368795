import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default observer(function ApprovePurchaseOrderList() {
  const { adminOrderRequestStore } = useStore();
  const {
    loadProcessOrderDetails,
    loadAdminOrderRequests,
    getAdminOrderRequests,
    adminOrderRequestRegistry,
  } = adminOrderRequestStore;

  useEffect(() => {
    adminOrderRequestRegistry.clear();
    if (adminOrderRequestRegistry.size <= 0) loadAdminOrderRequests();
  }, [adminOrderRequestRegistry]);

  const handleSelectPR = (id: string) => {
    loadProcessOrderDetails(id);
  };

  return (
    <>
      <Paper style={{ backgroundColor: "#EFEFEF" }}>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ padding: 1 }} variant="h5">
            Purchase Orders
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
        </Stack>
        <Divider sx={{ marginBottom: 1 }} />
        <Box
          style={{
            height: "73vh",
            maxHeight: "73vh",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {getAdminOrderRequests().map((item) => (
              <Card key={item.id} onClick={() => handleSelectPR(item.id)}>
                <CardActionArea>
                  <Box sx={{ flexGrow: 0, padding: 2 }}>
                    <Typography sx={{ paddingBottom: 1 }} variant="h5">
                      Purchase Order Number: {item.requestNumber}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {item.isApproved ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Approved"
                        />
                      ) : (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="For Approval"
                        />
                      )}
                        {item.inProgress == true && item.isApproved == true ? (
                        <Chip
                          size="small"
                          icon={<PendingActionsIcon />}
                          color="warning"
                          label="In Progress"
                        />
                      ) : (
                        ""
                      )}
                      {item.inProgress == false && item.isComplete == true && item.isApproved == true ? (
                        <Chip
                          size="small"
                          icon={<CheckCircleIcon />}
                          color="success"
                          label="Completed"
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </Box>
      </Paper>
    </>
  );
});
