import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Repack } from "../../models/admin/repack";
import { CompleteRepackRequestFormValues, ConfirmRepack, RepackRequest, RepackRequestFormValues, RepackRequestList, RepacksToRequest, RepackToConfirm, RepackToRequest, RestockRepackRequestFormValues, TransfersToRepack, TurnOverRepackRequestFormValues } from "../../models/requests/repackRequest";
import { Units } from "../../models/units";
import { Pagination, PagingParams } from "../../models/pagination";

export default class RepackRequestStore {
    repackRequestRegistry = new Map<string, RepackRequestList>();
    selectedRepackRequest: RepackRequest | undefined = undefined;
    selectedRepack: Repack | undefined = undefined;
    confirmRepack: ConfirmRepack | undefined = undefined;
    filledRepackForm: RepackRequestFormValues | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    getRepackRequests = () => {
        return Array.from(this.repackRequestRegistry.values());
    }

    loadRepackRequest =  async (id: string) => {
        this.loadingInitial = true;
            try {
                var repackRequest = await agent.RepackRequests.detail(id);
                runInAction(() => {
                    if(!repackRequest.isTurnOver) repackRequest.completionDate = null;
                    this.selectedRepackRequest = repackRequest;
                    this.loadingInitial = false;
                })
                return repackRequest;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
    }

    loadRepackRequests = async () => {
        this.loadingInitial = true;
        this.repackRequestRegistry.clear();
        try {
            const result = await agent.RepackRequests.list(this.axiosParams);
            result.data.forEach(repackRequest => {
                this.setRepackRequest(repackRequest);
            })
            this.loadingInitial = false;
            this.setPagination(result.pagination);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    isRepackReady = () => {
        var selectedRequestNumberString = this.selectedRepackRequest!.requestNumber.substring(10, 16);
        var selectedRequestNumber: number = +selectedRequestNumberString;

        var requestsInProgress = this.getRepackRequests().filter((x) => x.inProgress === true);

        var priorityRepackRequest = requestsInProgress.find((request) => {
            var requestNumberString = request.requestNumber.substring(10, 16);
            var requestNumber: number = +requestNumberString;

            if(request.id === this.selectedRepackRequest!.id) return false;

            if(requestNumber > selectedRequestNumber) return false;

            if(request.itemId != this.selectedRepackRequest!.itemId) return false;

            if(request.inventoryToRepackUnitId != this.selectedRepackRequest!.inventoryToRepackUnitId) return false;

            if(request.repackingDepartment != this.selectedRepackRequest!.repackingDepartment) return false;
            
            return true;
        });

        if(priorityRepackRequest){
            return true;
        }else{
            return false;
        }
    }

    loadRepackRequestsToConfirm = async (repackId: string, quantity: number, unitId: string, department: number) => {
        this.loadingInitial = true;
        console.log(this.loadingInitial);
        try {
            const repackRequestsToConfirm = await agent.RepackRequests.confirm(repackId, quantity, unitId, department);
            runInAction(() => {
                this.confirmRepack = repackRequestsToConfirm;
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    removeToRepack = (toRemove: RepackToConfirm) => {
        const indexOfRepacksToConfirm = this.confirmRepack!.repacksToConfirm.findIndex((toRepack) => {
            return toRepack.id === toRemove.id;
        });
          
        if (indexOfRepacksToConfirm !== -1) {
            this.confirmRepack!.repacksToConfirm.splice(indexOfRepacksToConfirm, 1);
        }
    }

    removeToTransfer = (toRemove: TransfersToRepack) => {
        const indexOfTransfersToConfirm = this.confirmRepack!.transfersToRepack.findIndex((toTransfer) => {
            return toTransfer.id === toRemove.id;
        });
          
        if (indexOfTransfersToConfirm !== -1) {
            this.confirmRepack!.transfersToRepack.splice(indexOfTransfersToConfirm, 1);
        }
    }

    private setRepackRequest = (repackRequest: RepackRequestList) => {
        this.repackRequestRegistry.set(repackRequest.id, repackRequest);
    }

    private getSelectedRepackRequest = () => {
        return this.selectedRepackRequest;
    }

    selectRepack = (repack: Repack) => {
        this.selectedRepack = repack;
    }

    unSelectRepackAndEmptyToRepackList = () => {
        this.filledRepackForm = undefined;
    }

    unSelectRepackRequest = () => {
        this.selectedRepackRequest = undefined;
    }

    setRepackForm = (repackForm: RepackRequestFormValues) => {
        this.filledRepackForm = repackForm;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createRepackRequest = async (confirmedRepacks: RepacksToRequest) => {
        this.loading = true;
        try {
            await agent.RepackRequests.create(confirmedRepacks);
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    restockRepackRequest = async (restockRepack: RestockRepackRequestFormValues) => {
        this.loading = true;
        try {
            await agent.RepackRequests.restock(restockRepack.repackRequestId, restockRepack);
            runInAction(async() => {
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    completeRepackRequest = async (completeRepack: CompleteRepackRequestFormValues) => {
        this.loading = true;
        try {
            var repackRequestId = (await agent.RepackRequests.complete(completeRepack.id, completeRepack));
            runInAction(async() => {
                this.loadRepackRequest(repackRequestId);
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    turnOverRepackRequest = async (turnOverRepack: TurnOverRepackRequestFormValues) => {
        this.loading = true;
        try {
            await agent.RepackRequests.turnover(turnOverRepack.id, turnOverRepack);
            runInAction(async() => {
                this.loadRepackRequest(this.selectedRepackRequest!.id);
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    archiveBranch = async (repackid: string) => {
        this.loading = true;
        try {
            await agent.RepackRequests.delete(repackid);
            runInAction(() => {
                this.loadRepackRequests();
                this.editMode = false;
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}