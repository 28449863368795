import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Login from "../User/Login";
import { history } from '../..';
import Register from "../User/Register";
import ForgotPasswordForm from "../User/ForgotPasswordForm";

export default observer(function HomePage() {
  const { userStore, modalStore } = useStore();
  const [createAccountMode, setCreateAccountMode] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  function handleCreateAccount(){
    setCreateAccountMode(true);
    setForgotPasswordMode(false);
  }

  function handleLogin(){
    setCreateAccountMode(false);
    setForgotPasswordMode(false);
  }

  function handleForgotPassword(){
    setCreateAccountMode(false);
    setForgotPasswordMode(true);
  }

  return (
    <>
      {userStore.isLoggedIn ? (
        history.push('/dashboard')
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
          sx={{backgroundColor: "#EFEFEF"}}
        >
          <Grid item xs={3}>
            {forgotPasswordMode ? (
              <ForgotPasswordForm loginUser={handleLogin} />
            ) : createAccountMode ? (
              <Register loginUser={handleLogin} />
            ) : (
              <Login
                createAccount={handleCreateAccount}
                forgotPassword={handleForgotPassword}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
});
