import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from "react-router-dom";
import { EndingInventory , EndingInventoryFilter} from "../../../app/models/Inventories/inventory";
import { format } from "date-fns";
export default observer(function EndingInventoryList() {
  const history = useHistory();
  const { endingInventoryStore } = useStore();
  const { endingInventoryItemsRegistry, endingInventoryRegistry,loadEndingInventoryItems,setInitialDate,setInitialId,setIsView, getEndingInventory , loadEndingInventory, setEndingInventoryItemsFiter,setHasSetFilter} = endingInventoryStore;

  useEffect(() => {
    endingInventoryRegistry.clear();
    if (endingInventoryRegistry.size <= 0) loadEndingInventory();
  }, [endingInventoryRegistry]);

  const handleEdit = (Id:string ,filteredDateItem: Date) => {
    let endInvFilter: EndingInventoryFilter = {
      id:Id,
      filteredDateStart:filteredDateItem,
      filteredDateEnd: filteredDateItem
    };
    setEndingInventoryItemsFiter(endInvFilter).then(
      () => {
        loadEndingInventoryItems(endInvFilter).then(
          () => {
            setHasSetFilter(true);
            setInitialDate(format(new Date(filteredDateItem!), 'yyyy-MM-dd'));
            setInitialId(Id);
            setIsView(true);
            history.push("/endinginventory");
          }
        );
      }
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ padding: 1 }} variant="h3">
          Manage Ending Inventory
        </Typography>
        <Typography
          sx={{ paddingLeft: 1, paddingBottom: 1 }}
          variant="body2"
          color="text.secondary"
        >
          View all ending inventory, report discrepancy
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container direction="row" spacing={0}>
          <Grid item xs={0} md={0} lg={2}></Grid>

          <Grid item xs={12} md={12} lg={8}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} md={12}>
                <Paper style={{ backgroundColor: "#EFEFEF" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ padding: 1 }} variant="h5">
                      Ending Inventories
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                    />
                  </Stack>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Box
                    style={{
                      height: "73vh",
                      maxHeight: "73vh",
                      overflow: "auto",
                    }}
                  >
                    <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                      {getEndingInventory().map((item) => (
                        <Card key={item.id}>
                          <Stack direction="row" spacing={2}>
                            <Box sx={{ flexGrow: 0, padding: 2 }}>
                              <Typography variant="h5">
                                {item.name}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "flex" },
                              }}
                            />
                            <Box sx={{ flexGrow: 0, padding: 2 }}>
                              <Stack direction="row" spacing={1}>
                                <IconButton
                                  onClick={() => handleEdit(item.id, item.endingInventoryDate)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Stack>
                            </Box>
                          </Stack>
                        </Card>
                      ))}
                    </Stack>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={0} lg={2}></Grid>
        </Grid>
      </Box>
    </>
  );
});
