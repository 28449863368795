import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';
import { FormTextField } from '../../app/common/form/FormTextField';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props{
    loginUser: () => void;
  }

export default observer(function ForgotPasswordForm({loginUser}: Props) {
    const { userStore } = useStore();
    const history = useHistory();

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                height="140"
                image="https://file.rendit.io/n/dpUDfwax2auOgIQJAKvX.png"
                alt="forgot password"
            />
            <CardContent>
                <Typography variant="h5" component="div">
                    Forgot Password
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Please enter your email and new password.
                </Typography>
                <Formik
                    initialValues={{ email: '', newPassword: '', confirmPassword: '', error: null }}
                    onSubmit={(values, { setErrors }) =>
                        userStore.resetPassword(values)
                            .then(() => {
                                // Redirect to login form after successful submission
                                toast.success('Password reset successful');
                            })
                            .catch(error =>
                                setErrors({ error: 'An error occurred while resetting password' })
                            )
                    }
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                        newPassword: Yup.string().required(),
                        confirmPassword: Yup.string().required()
                            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors }) => (
                        <Form onSubmit={handleSubmit} autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="email"
                                        label="Email"
                                        component={FormTextField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="newPassword"
                                        label="New Password"
                                        type="password"
                                        component={FormTextField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        component={FormTextField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Reset Password
                                    </Button>
                                </Grid>
                                <ErrorMessage
                                    name="error"
                                    render={error => (
                                        <Grid item xs={12}>
                                            <Typography color="error">{error}</Typography>
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CardContent>
            <Link
            variant="body2"
            color="text.primary"
            component="button"
            onClick={() => loginUser()}
            sx={{ paddingLeft: 1, marginLeft: 1}}
          >
            Login here.
          </Link>
        </Card>
    );
});
