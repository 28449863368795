import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Menu } from "../../../app/models/admin/menus";
import MenuForm from "./MenuForm";

interface Props {
  menuList: Array<Menu>;
}

export default observer(function MenuList({ menuList }: Props) {
  const { menuStore } = useStore();
  const { getMenus, archiveMenu, selectMenu, selectedMenu } = menuStore;
  const [update, setUpdate] = useState(false);
  const [archive, setArchive] = useState(false);
  
  const handleUpdate = () => {
    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };

  const handleEdit = (id: string) => {
    selectMenu(id);
    handleUpdate();
  };

  const handleArchive = (id: string) => {
    selectMenu(id);
    setArchive(true);
  };

  const handleCloseArchive = () => {
    setArchive(false);
  };

  return (
    <>
      <Stack spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        {getMenus().map((menu) => (
          <Card key={menu.id}>
            <Stack direction="row" spacing={2}>
              <Box sx={{ flexGrow: 0, padding: 2 }}>
                <Typography variant="h5">{menu.name}</Typography>
                <Typography variant="subtitle1">
                  {menu
                    ? "Classification: " + menu.classificationName
                    : "Classification: "}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />
              <Box sx={{ flexGrow: 0, padding: 2 }}>
                <Stack direction="row" spacing={1}>
                  <IconButton onClick={() => handleEdit(menu.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleArchive(menu.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Dialog open={update}>
                  <DialogTitle>Update Menu</DialogTitle>
                  <DialogContent>
                    {/* input fields */}
                    <MenuForm closeForm={handleCloseUpdate} />
                  </DialogContent>
                </Dialog>
                <Dialog open={archive}>
                  <DialogTitle>Archive Menu</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to archive this menu?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseArchive}
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "black",
                          borderColor: "none",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() =>
                        selectedMenu != null
                          ? archiveMenu(selectedMenu.id)
                          : null
                      }
                      variant="contained"
                      size="small"
                      color="primary"
                      disableElevation
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          borderColor: "none",
                          boxShadow: "none",
                        },
                        "&:active": {
                          boxShadow: "none",
                          backgroundColor: "black",
                          borderColor: "none",
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Stack>
          </Card>
        ))}
      </Stack>
    </>
  );
});
